import React, {useState} from "react";
import { Form, Input, Button } from "antd";
import axios from "axios";
import Swal from "sweetalert2";
import { API } from "../../../config/config";
const { TextArea } = Input;


const ApiTokenForm = (props) => {
    const { t, data, form, layout, tailLayout, onValuesChange, onSubmit, apiTokenBtnDisabled, setApiTokenBtnDisabled } = props;
    const [loading, setLoading] = useState(false);

    const generateNewToken = () => {
        setLoading(true);
        const apiUrl = '/genapitoken';
        axios
            .post(API + apiUrl, {uid: data.uid}, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
            .then((result) => {
                setApiTokenBtnDisabled(false);
                result = {...result.data};
                data.api_token = result.api_token;
                form.setFieldsValue({
                    api_token: result.api_token
                });
            })
            .catch((error) => {
                console.log(error);
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: t("login.dialogs.catch"),
                    showConfirmButton: false,
                    timer: 2000,
                });
            }).finally(() => {
                setLoading(false);
            });
    }

    return (
        <Form {...layout} form={form} onValuesChange={onValuesChange} onFinish={onSubmit} style={{ padding: "15px 0" }}>

            <Form.Item name="api_token" label={t('contents.profile.form.api_token')} rules={[{ required: true, message: t('contents.profile.form.required_messages.changeapi_token_required') }]}>
                <TextArea disabled
                    autoSize={{ minRows: 3, maxRows: 5 }}
                />
            </Form.Item>

            <Form.Item {...tailLayout}>

                <Button htmlType="button" style={{ marginRight: "10px" }} onClick={generateNewToken} loading={loading}>
                    {t("contents.profile.form.generate_new_api_token")}
                </Button>

                <Button type="primary" htmlType="submit" disabled={apiTokenBtnDisabled}>
                    {t("contents.profile.form.submit")}
                </Button>
            </Form.Item>
        </Form>
    );
};

export default ApiTokenForm;

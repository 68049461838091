import React, { useState, useEffect, useCallback } from "react";
import DataFetchHookOI from "../../helper/DataFetchHookOI";
import PermissionsRequired from "../../helper/PermissionsRequired";
import { Table, PageHeader, Button, Space, Checkbox, Card, Form, Select, Row, Col, Input } from "antd";
import { SearchOutlined, FileSearchOutlined } from "@ant-design/icons";
import moment from "moment";
import AssociationsChecker from "./../../helper/AssociationsChecker";
import axios from "axios";
import { OI_URL } from "../../config/config";
import ExportModalV3 from "../../helper/ExportModalV3";
import CustomPaginationV2 from "../../helper/Table/CustomPaginationV2";
import CustomDateRangeV3 from "../../helper/Table/CustomDateRangeV3";
import SortOrder from "../../helper/Table/SortOrder";
import TxFindAccessToken from "../../helper/TxFindAccessToken";

const { Option } = Select;
const DATA_URL = "/api/v1/txshield/cartList/";
const DATA_URL_EXPORT = "/api/v1/txshield/cartList/reports";
const MERCHANT_DATA_URL = "/api/v1/txshield/merchants/";

const MAX_DATERANGE = 3;
const START_DATE = moment().subtract(MAX_DATERANGE, "months");
const END_DATE = moment();

const CartList = (props) => {
    const defaultSortInfo = {
        columnKey: 'dateCreated',
        field: 'dateCreated',
        order: 'descend'
    };

    // Initial request data with numeric page and size values
    const initialRequest = {
        page: 1,
        size: 20,
        order: "DESC",
        sortField: "dateCreated"
    };

    const [{ data, totalResult, isLoading, requestData }, doFetch, setRequestData] = DataFetchHookOI(
        DATA_URL, 
        "POST", 
        null, 
        initialRequest
    );
    
    const [formData, setFormData] = useState({ hideColumn: [] });
    const [form] = Form.useForm();
    const [sortedInfo, setSortedInfo] = useState(defaultSortInfo);
    const [dates, setDates] = useState([START_DATE, END_DATE]);
    const [currentPage, setCurrentPage] = useState(1);
    const [merchantList, setMerchantList] = useState([]);
    const oiAssocication = localStorage.getItem("oiAssocication") ? localStorage.getItem("oiAssocication").split(",") : [];
    const { t } = props;
    const initFormData = {
        keyword: null,
        field: null,
        sortField: 'dateCreated',  
        order: 'DESC',
        associatedMerchantIds: [],
        dateRange: [START_DATE, END_DATE],
        lookup: false,
        alert: false,
        chargeback: false,
        hideColumn: [],
    };

    // Ensure pagination state is synchronized with requestData
    useEffect(() => {
        if (requestData.page && parseInt(requestData.page) !== parseInt(currentPage)) {
            setCurrentPage(parseInt(requestData.page));
        }
    }, [requestData.page, currentPage]);

    useEffect(() => {
        formValInit();
        getMerchantList();
        
        // Initialize with date range
        const initialStartDate = START_DATE.format("YYYY-MM-DD 00:00:00");
        const initialEndDate = END_DATE.format("YYYY-MM-DD 23:59:59");
        
        // Send initial search with dates to ensure data loads
        setRequestData({
            ...initialRequest,
            dateFrom: initialStartDate,
            dateTo: initialEndDate
        });
        
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getMerchantList = useCallback(async () => {
        try {
            const token = await TxFindAccessToken();
            const { data } = await axios.post(
                OI_URL + MERCHANT_DATA_URL, 
                { associatedMerchantIds: oiAssocication }, 
                { 
                    headers: { 
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    } 
                }
            );
            if (data && data.list && Array.isArray(data.list)) {
                setMerchantList(data.list);
            } else {
                console.error("Invalid merchant data structure:", data);
            }
        } catch (error) {
            console.error("Error fetching merchant list:", error.response || error);
        }
    }, [oiAssocication]);

    const handleChange = (pagination, filters, sorter) => {
        // Don't do anything if this is a pagination change - that's handled by CustomPaginationV2
        if (pagination.current === currentPage && pagination.pageSize === requestData.size) {
            const activeSorter = sorter.column ? sorter : defaultSortInfo;
            setSortedInfo(activeSorter);
            
            if (activeSorter && activeSorter.field) {
                const sortField = activeSorter.field;
                const sortOrder = activeSorter.order === 'descend' ? 'DESC' : 'ASC';
                
                // Reset to page 1 when sorting changes
                setCurrentPage(1);
                
                // Preserve date filters when sorting
                const currentRequestData = { ...requestData };
                
                setRequestData({
                    ...currentRequestData,
                    page: 1, // Always reset to page 1 when sorting changes
                    sortField: sortField,
                    order: sortOrder
                });
            }
        }
    };

    const formValInit = () => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);
    };

    const clearAll = () => {
        setFormData(initFormData);
        setSortedInfo(defaultSortInfo);
        setCurrentPage(1);
        
        // When clearing, set dateRange to the initial date range
        const initialStartDate = START_DATE.format("YYYY-MM-DD 00:00:00");
        const initialEndDate = END_DATE.format("YYYY-MM-DD 23:59:59");
        
        setRequestData({ 
            ...requestData, 
            ...initFormData, 
            dateFrom: initialStartDate, 
            dateTo: initialEndDate, 
            page: 1,
            size: parseInt(requestData.size) || 20,
            sortField: 'dateCreated',
            order: 'DESC'
        });
        
        form.setFieldsValue({
            ...initFormData,
            dateRange: [START_DATE, END_DATE]
        });
    };

    const formValChange = (chgV, allV) => {
        setFormData(allV);
    };

    // FIXED onFinish function for proper date handling
    const onFinish = () => {
        // Get form values
        const currentValues = form.getFieldsValue();
        
        // Create variables for date range filtering
        let dateFrom = null;
        let dateTo = null;
        
        // Handle date range properly
        if (currentValues.dateRange && Array.isArray(currentValues.dateRange) && currentValues.dateRange.length === 2) {
            if (currentValues.dateRange[0]) {
                // Format as YYYY-MM-DD 00:00:00 for start date
                dateFrom = moment(currentValues.dateRange[0]).format("YYYY-MM-DD 00:00:00");
            }
            
            if (currentValues.dateRange[1]) {
                // Format as YYYY-MM-DD 23:59:59 for end date
                dateTo = moment(currentValues.dateRange[1]).format("YYYY-MM-DD 23:59:59");
            }
        }
        
        // Build search request with explicit date parameters
        const searchData = {
            page: 1,
            size: parseInt(requestData.size) || 20,
            order: sortedInfo.order === 'ascend' ? 'ASC' : 'DESC',
            sortField: sortedInfo.field || 'dateCreated',
            value: [currentValues.keyword ? currentValues.keyword.trim() : null],
            field: [currentValues.field],
            operator: [currentValues.field === "txShieldOICartReference" ? "=" : "LIKE"],
            associatedMerchantIds: currentValues.associatedMerchantIds || [],
            lookup: currentValues.lookup || false,
            alert: currentValues.alert || false,
            chargeback: currentValues.chargeback || false,
            // Explicitly set date parameters directly from the date picker values
            dateFrom: dateFrom,
            dateTo: dateTo
        };
                
        // Update state and trigger search
        setCurrentPage(1);
        setRequestData(searchData);
    };

    const columns = [
        {
            dataIndex: "dateCreated",
            key: "dateCreated",
            title: t("tables.fields.datecreated"),
            width: 180,
            sorter: true,
            defaultSortOrder: 'descend', 
            sortOrder: SortOrder(sortedInfo, "dateCreated"),
            render: (dateCreated) => moment(dateCreated).format("YYYY-MM-DD HH:mm:ss"),
        },
        {
            dataIndex: "txShieldOICartReference",
            key: "txShieldOICartReference",
            title: t("tables.fields.txshieldoicartreference"),
            width: 330,
            sorter: true,
            sortOrder: SortOrder(sortedInfo, "txShieldOICartReference"),
            render: (value) => {
                return (
                    <a className="oi-cart-ref-hyperlink" href={`/txfind_cart/${value}`} target="_blank" rel="noopener noreferrer">
                        {value}
                    </a>
                );
            },
        },
        {
            dataIndex: "transactionDate",
            key: "transactionDate",
            title: t("tables.fields.transactiondate"),
            width: 220,
            sorter: true,
            sortOrder: SortOrder(sortedInfo, "transactionDate"),
            render: (transactionDate) => moment(transactionDate).format("YYYY-MM-DD HH:mm:ss"),
        },
        {
            dataIndex: "cardno",
            key: "cardno",
            title: t("tables.fields.cardno"),
            width: 180,
            sorter: true,
            sortOrder: SortOrder(sortedInfo, "cardno"),
        },
        {
            dataIndex: "email",
            key: "email",
            title: t("tables.fields.email"),
            width: 220,
            sorter: true,
            sortOrder: SortOrder(sortedInfo, "email"),
        },
        {
            dataIndex: "merchantName",
            key: "merchantName",
            title: t("tables.fields.merchantname"),
            width: 180,
            sorter: true,
            sortOrder: SortOrder(sortedInfo, "merchantName"),
        },
        {
            dataIndex: "amount",
            key: "amount",
            title: t("tables.fields.amount"),
            width: 240,
            sorter: true,
            sortOrder: SortOrder(sortedInfo, "amount"),
        },
        {
            dataIndex: "currency",
            key: "currency",
            title: t("tables.fields.currency"),
            width: 160,
            sorter: true,
            sortOrder: SortOrder(sortedInfo, "currency"),
        },
        {
            dataIndex: "paymentDescriptor",
            key: "paymentDescriptor",
            title: t("tables.fields.paymentdescriptor"),
            width: 180,
            sorter: true,
            sortOrder: SortOrder(sortedInfo, "paymentDescriptor"),
        },
        {
            dataIndex: "action",
            title: t("tables.fields.action"),
            align: "left",
            fixed: formData.hideColumn ? (formData.hideColumn.length < 8 ? "right" : null) : null,
            width: 80,
            render: (text, record) => (
                <Space size="middle">
                    <Button type="link" href={`/txfind_cart/${record.txShieldOICartReference}`} target="_blank">
                        <FileSearchOutlined style={{ color: "rgba(0, 0, 0, 0.85)", fontSize: "18px" }} />
                    </Button>
                </Space>
            ),
        },
    ];

    const getColumnSource = () => {
        let initColumns = columns;
        if (formData.hideColumn) {
            return formData.hideColumn.length > 0 ? initColumns.filter((x) => !formData.hideColumn.includes(x.dataIndex)) : initColumns;
        } else {
            return initColumns;
        }
    };

    const getTableWidth = () => {
        return getColumnSource().reduce(function (a, k) {
            return a + k.width;
        }, 0);
    };

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 0]}>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="dateRange">
                                <CustomDateRangeV3 t={t} state={{ dates, setDates }} unit="months" limit={MAX_DATERANGE} />
                            </Form.Item>
                        </Col>
                        {merchantList && merchantList.length > 0 && (
                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item name="associatedMerchantIds">
                                    <Select
                                        showSearch
                                        showArrow={true}
                                        mode="multiple"
                                        maxTagCount="responsive"
                                        style={{ width: "100%" }}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                        placeholder={t("contents.oi.advanced_search.merchant")}
                                    >
                                        {merchantList.map((item) => (
                                            <Option key={item.merchantId} value={item.merchantId}>
                                                {item.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        )}
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 5 }}>
                            <Form.Item name="field">
                                <Select 
                                    showSearch
                                    style={{ width: "100%" }} 
                                    placeholder={t("tables.fields.field")}>
                                    <Option value="txShieldOICartReference">{t("contents.oi.advanced_search.txshieldoicartreference_field")}</Option>
                                    <Option value="cardnumber">{t("contents.oi.advanced_search.cardnumber_field")}</Option>
                                    <Option value="email">{"Email"}</Option>
                                    <Option value="paymentDescriptor">{t("contents.oi.advanced_search.descriptor_field")}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 7 }}>
                            <Form.Item name="keyword">
                                <Input placeholder={t("tables.fields.keyword")} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 0]} justify="end">
                        <Col className="gutter-row" flex={"none"}>
                            <Form.Item name="lookup" valuePropName="checked" style={{ paddingTop: "8px" }}>
                                <Checkbox>{t("contents.oi.advanced_search.had_lookup")}</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" flex={"none"}>
                            <Form.Item name="alert" valuePropName="checked" style={{ paddingTop: "8px" }}>
                                <Checkbox>{t("contents.oi.advanced_search.alert")}</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" flex={1}>
                            <Form.Item name="chargeback" valuePropName="checked" style={{ paddingTop: "8px" }}>
                                <Checkbox>{t("contents.oi.advanced_search.chargeback")}</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col
                            className="gutter-row"
                            style={{ marginBottom: "16px" }}
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 24 }}
                            lg={{ span: 12 }}
                            xl={{ span: 5 }}
                        >
                            <Form.Item name="hideColumn">
                                <Select
                                    showSearch
                                    mode="multiple"
                                    maxTagCount="responsive"
                                    style={{ width: "100%" }}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                    placeholder={t("tables.actions.hide_column")}
                                >
                                    {columns.map((item, index) => {
                                        return item.dataIndex !== "action" ? (
                                            <Option key={index} value={item.dataIndex}>
                                                {item.title}
                                            </Option>
                                        ) : null;
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col
                            className="gutter-row"
                            style={{ marginBottom: "8px" }}
                            xs={{ span: 24 }}
                            sm={{ span: 12 }}
                            md={{ span: 12 }}
                            lg={{ span: 6 }}
                            xl={{ span: 3 }}
                        >
                            <Form.Item>
                                <Button type="secondary" onClick={() => clearAll()} block>
                                    {t("tables.actions.clear_all")}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 4 }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> {t("tables.actions.search")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    const getDataSource = (datalist) =>
        datalist && Array.isArray(datalist)
          ? datalist.map((data) => {
              return {
                ...data,
                cardno: `${data.creditCardBin || data.cardBin}******${data.creditCardLastFour || data.cardLast4}`,
                settlementAmount: data.settlementAmount > 0 ? data.settlementAmount : "-",
                email: data.email || 
                       (data.customerInformation && data.customerInformation.emailAddress) || 
                       ""
              };
            })
          : [];

    const exportHeadersOptional = [];
    const exportHeaders = [
        { label: "Date", key: "dateCreated" },
        { label: "TxFind Cart Reference", key: "txShieldOICartReference" },
        { label: "Transaction Date", key: "transactionDate" },
        { label: "Card Number", key: "cardno" },
        { label: "Email Address", key: "email" },
        { label: "Merchant Name", key: "merchantName" },
        { label: "Descriptor", key: "paymentDescriptor" },
        { label: "Auth Code", key: "authorizationCode" },
        { label: "Auth Date", key: "authorizationDate" },
        { label: "Auth Amount", key: "authorizationAmount" },
        { label: "Settlement Date", key: "settlementDate" },
        { label: "Settlement Amount", key: "settlementAmount" }
    ];

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.oi.cart_list")}</h3>
            <ExportModalV3
                t={t}
                keyname="export_all"
                title={t("tables.actions.export")}
                data={requestData}
                data_url={DATA_URL_EXPORT}
                headers={exportHeaders}
                fieldHeadersOptional={exportHeadersOptional}
                filename={`txfind_carts_${moment().format("YYMMDD_HHmmss_SSSS")}.csv`}
                style={{ marginLeft: 8 }}
            />
        </div>
    );

    // Use the proper numeric values for pagination
    const paginationConfig = CustomPaginationV2({
        totalResult: parseInt(totalResult) || 0,
        currentPage: parseInt(currentPage) || 1,
        requestData: {
            ...requestData,
            page: parseInt(requestData.page) || 1,
            size: parseInt(requestData.size) || 20
        },
        setRequestData,
        setCurrentPage
    });

    return PermissionsRequired("SYSADMIN") || AssociationsChecker("OI") ? (
        <div>
            <PageHeader title={t("contents.oi.cart_list")}></PageHeader>
            {AdvancedSearchForm()}
            <Table
                loading={isLoading}
                title={() => getHeaderTable()}
                rowKey="txShieldOICartReference"
                columns={getColumnSource()}
                size="small"
                scroll={{ x: getTableWidth() }}
                className="table-v3 custom-pagination"
                dataSource={getDataSource(data.datalist)}
                onChange={handleChange}
                pagination={paginationConfig}
            />
        </div>
    ) : null;
};

export default CartList;
import React, { useState, useEffect } from "react";
import DataFetchHookV3 from "../../helper/DataFetchHookV3";
import { Table, PageHeader, Card, Form, Row, Button, Col, Input, Select } from "antd";
import { API_TXALERT } from "../../config/config";
import { SearchOutlined } from "@ant-design/icons";
import ExportModalV3 from "../../helper/ExportModalV3";
import moment from "moment";
import MerchantList from "./../../components/MerchantList";
import CustomPaginationV2 from "../../helper/Table/CustomPaginationV2";
import ColumnResizable from "../../helper/Table/ColumnResizable";
import htmlParser from "html-react-parser";
import CustomDateRangeV3 from "../../helper/Table/CustomDateRangeV3";

const DATA_URL = API_TXALERT + "/descriptorvariation";
const DATA_URL_EXPORT = API_TXALERT + "/descriptorvariation/reports";

const { Option } = Select;
const MAX_DATERANGE = 3;
const DEFAULT_DATERANGE = 14;
const DEFAULT_RANGETYPE = "days";

const START_DATE = moment().subtract(DEFAULT_DATERANGE, DEFAULT_RANGETYPE);
const END_DATE = moment();

const DescriptorVariationList = (props) => {
    // eslint-disable-next-line
    const [{ data, totalResult, isLoading, requestData, isUpdated }, doFetch, setRequestData] = DataFetchHookV3(DATA_URL, "POST", {
        size: 20,
        page: 1,
        order: "DESC",
        startDate: START_DATE,
        endDate: END_DATE,
    });

    const [dates, setDates] = useState([START_DATE, END_DATE]);


    const { t } = props;
    const [formData, setFormData] = useState();
    const [currentPage, setCurrentPage] = useState(1);

    const initFormData = {
        dateRange: [START_DATE, END_DATE],
        keyword: "",
        field: null,
        merchant: [],
    };

    const [form] = Form.useForm();

    useEffect(() => {
        formValInit();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const handleChange = (pagination, filters, sorter, { currentDataSource }) => {
        var orderBy = 'ASC';
        if (sorter.order === 'descend') {
            orderBy = 'DESC';
        }
        var sortField = 'descriptor_variation_id';
        if (sorter.field !== undefined) {
            sortField = sorter.field;
        }
        var p = pagination.current;
        if (Object.keys(sorter).length > 0 && requestData.sort_field !== sortField) {
            p = 1;
            setCurrentPage(1);
        }
        setRequestData({ ...requestData, order: orderBy, sort_field: sortField, page: p, size: pagination.pageSize });
    };

    const clearAll = () => {
        setDates([]);
        setFormData(initFormData);
        setCurrentPage(1);
        setRequestData({ ...requestData, ...initFormData, startDate: null, endDate: null, page: 1 });
        form.setFieldsValue(initFormData);
    };

    const formValInit = () => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);
    };

    const formValChange = (chgV, allV) => {
        setFormData(allV);
    };

    const onFinish = () => {
        let searchData = {};
        let startDate = null;
        let endDate = null;
        if (formData) {
            if (dates) {
                startDate = dates[0] ? dates[0] : null;
                endDate = dates[1] ? dates[1] : null;
            }
            searchData = {
                field: formData.field,
                keyword: formData.keyword,
                merchant: formData.merchant,
            };
        }
        setCurrentPage(1);
        setRequestData({ ...requestData, ...searchData, startDate: startDate, endDate: endDate, page: 1 });
    };


    const exportHeadersOptional = [];
    const exportHeaders = [
        { label: "Variation Text", key: "dv.variation_text" },
        { label: "Enrolled Descriptor", key: "descriptor" },
        { label: "Provider Name", key: "providername" },
        { label: "Created Date", key: "dv.created_date" },
    ];

    const [columns, setColumns] = useState([
        {
            dataIndex: "variation_text",
            key: "variation_text",
            title: t("tables.fields.variation_text"),
            ellipsis: true,
            width: 120,
            render: (variation_text, record) => (
                htmlParser(variation_text)
            ),
            sorter: {
                compare: (a, b) => a.variation_text.localeCompare(b.variation_text),
            },
        },
        {
            dataIndex: "descriptor",
            key: "descriptor",
            title: t("tables.fields.enrolled_descriptor"),
            width: 150,
            sorter: {
                compare: (a, b) => String(a?.descriptor ?? '').localeCompare(String(b?.descriptor ?? '')),
            },
            render: (id, record) => (
                <a href={`/alertdescriptors/${record.descriptor}`} target="_blank" rel="noopener noreferrer">
                    {id}{" "}
                </a>
            ),
        },
        {
            dataIndex: "providername",
            key: "providername",
            title: t("tables.fields.providername"),
            width: 100,
            ellipsis: true,
            render: (providername) => htmlParser(providername ?? 'no provider set'),
            sorter: {
                compare: (a, b) => a.providername.localeCompare(b.providername),
            },
        },
        {
            dataIndex: "created_date",
            key: "created_date",
            title: t("tables.fields.created_date"),
            width: 150,
            sorter: {
                compare: (a, b) => a.created_date.localeCompare(b.created_date),
            },
        },
    ].filter(item => !item.hidden));

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 16]}>

                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="dateRange">
                                <CustomDateRangeV3 t={t} state={{ dates, setDates }} unit="months" limit={MAX_DATERANGE} />
                            </Form.Item>
                        </Col>

                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="merchant">
                                <MerchantList placeholder={t("contents.merchants.title")} 
                                    style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>

                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="field">
                                <Select
                                    showSearch
                                    showArrow="true"
                                    style={{ width: "100%" }}
                                    placeholder={t("tables.fields.field")}>
                                    <Option value="dv.variation_text">{t("tables.fields.variation_text")}</Option>
                                    <Option value="descriptor">{t("tables.fields.enrolled_descriptor")}</Option>
                                    <Option value="providername">{t("tables.fields.provider")}</Option>

                                </Select>
                            </Form.Item>
                        </Col>

                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="keyword">
                                <Input placeholder={t("tables.fields.keyword")} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col flex="auto"></Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6, offset: 12 }} lg={{ span: 4, offset: 0 }} xl={{ span: 3, offset: 4 }}>
                            <Form.Item>
                                <Button type="secondary" onClick={() => clearAll()} block>
                                    {t("tables.actions.clear_all")}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> {t("tables.actions.search")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.descriptorvariations.title")}</h3>
            <ExportModalV3
                t={t}
                keyname="export_all"
                title={t("tables.actions.export")}
                data={requestData}
                data_url={DATA_URL_EXPORT}
                headers={exportHeaders}
                fieldHeadersOptional={exportHeadersOptional}
                filename={`alertdescriptors_${moment().format("YYMMDD_HHmmss_SSSS")}.csv`}
                style={{ marginLeft: 8 }}
            />
        </div>
    );

    const mergeColumns = columns.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => ({
            width: column.width,
            onResize: (_, { size }) => {
                const newColumns = [...columns];
                newColumns[index] = { ...newColumns[index], width: size.width };
                setColumns(newColumns);
            },
        }),
    }));

    return (
        <>
            <PageHeader title={t("contents.descriptorvariations.title")}></PageHeader>
            {AdvancedSearchForm()}
            <Table
                rowKey="descriptor_variation_id"
                size="small"
                title={() => getHeaderTable()}
                loading={isLoading}
                className="table-v3 custom-pagination"
                components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }}
                columns={mergeColumns}
                dataSource={data.datalist && Array.isArray(data.datalist) ? data.datalist : []}
                onChange={handleChange}
                pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}
                scroll={{ x: 1500 }}
            />
        </>
    );
};

export default DescriptorVariationList;

import React, { useState, useEffect } from "react";
import { PageHeader, Table, Tabs, Card, Form, Row, Col, Empty, Spin, message } from "antd";
import FormatNumber from "../../helper/FormatNumber";
import { OI_URL } from "../../config/config";
import axios from "axios";
import moment from "moment";
import TxFindAccessToken from "../../helper/TxFindAccessToken";

const { TabPane } = Tabs;

const CartDetail = (props) => {
    const [data, setData] = useState(null);
    const [lookup, setLookup] = useState([]);
    const [attachment, setAttachment] = useState(null);
    const [loading, setLoading] = useState(true);
    const { t } = props;

    const formItemLayout = {
        labelCol: { span: 10 },
        wrapperCol: { span: 14 },
    };

    useEffect(() => {
        const loadCartData = async () => {
            if (!props.match?.params?.id) {
                setLoading(false);
                return;
            }
    
            try {
                const token = await TxFindAccessToken();
                const cartId = props.match.params.id;
    
                const cartResponse = await axios.post(
                    `${OI_URL}/api/v1/txshield/cartDetails/${cartId}`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
    
                if (cartResponse.data) {
                    setData(cartResponse.data);
    
                    try {
                        const lookupResponse = await axios.post(
                            `${OI_URL}/api/v1/txshield/lookupHistory/`,
                            {
                                field: ["txShieldOICartReference"],
                                value: [cartId],
                                operator: ["="],
                                page: 1,
                                size: 10,
                                order: "DESC"
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${token}`
                                }
                            }
                        );
    
                        setLookup(lookupResponse.data.list || []);
                    } catch (lookupError) {
                        console.error('Error loading lookup history:', lookupError);
                        message.warning('Could not load lookup history');
                    }
    
                    try {
                        const attachmentResponse = await axios.get(
                            `${OI_URL}/api/v1/cart/${cartId}/attachment/`
                        );
                        setAttachment(attachmentResponse.data);
                    } catch (attachmentError) {
                        console.error('Error loading attachments:', attachmentError);
                        message.warning('Could not load attachments');
                    }
                }
            } catch (error) {
                console.error('Error loading cart data:', error);
                message.error(error.response?.data?.msg || 'Error loading cart data');
            } finally {
                setLoading(false);
            }
        };
    
        loadCartData();
    }, [props.match.params.id]); 
    
    const getPlainText = (text) => text || '-';

    const getLink = (link) => {
        if (!link) return '-';
        return (
            <a href={link} target="_blank" rel="noopener noreferrer" className="ant-form-text">
                {link}
            </a>
        );
    };

    const getFormatDate = (date) => {
        if (!date || !moment(date).isValid()) return '-';
        return moment(date).format("YYYY-MM-DD HH:mm:ss");
    };

    const getCartDetailsLeftForm = () => {
        if (!data) return null;

        const customerInfo = {
            ...data.customerInformation,
            ...(data.details?.customerInformation || {})
        };

        const fields = [
            {
                label: t("contents.oi.form.txshieldoicartreference"),
                name: "txShieldOICartReference",
                value: getPlainText(data.txShieldOICartReference),
            },
            {
                label: t("contents.oi.form.transactiondate"),
                name: "transactionDate",
                value: getFormatDate(data.transactionDate),
            },
            {
                label: "Account ID",
                name: "accountId",
                value: getPlainText(customerInfo.accountId)
            },
            {
                label: "IP Address",
                name: "ipAddress",
                value: getPlainText(data.device?.ipAddress)
            },
        ];

        return showCardTwoBlockInfo(fields);
    };

    const getCartDetailsRightForm = () => {
        if (!data) return null;

        const fields = [

            {
                label: t("contents.oi.form.paymentdescriptor"),
                name: "paymentDescriptor",
                value: getPlainText(data.paymentDescriptor),
            },

           {
                label: "Card Number", 
                name: "cardNumber", 
                value: data.cardBin && data.cardLast4 ?
                    getPlainText(`${data.cardBin}******${data.cardLast4}`) :
                    '-',
            },
             {
                label: "Device ID",
                name: "deviceId",
                value: getPlainText(data.device?.deviceId)
            },
            {
                label: "Device Fingerprint",
                name: "deviceFingerprint",
                value: getPlainText(data.device?.deviceFingerprint)
            },
        ];
        return showCardTwoBlockInfo(fields);
    };

    const getCustomerInformationForm = () => {

        const customerInfo = {
            ...data.customerInformation,
            ...(data.details?.customerInformation || {})
        };

        const fields = [
            {
                label: "Email Address",
                name: "emailAddress",
                value: getPlainText(customerInfo.emailAddress || customerInfo.email || data.email || '')
            },
            {
                label: "Delivery Address",
                name: "deliveryAddress",
                value: getPlainText(data.deliveryAddress?.address1)
            },

            {
                label: "Transaction Amount",
                name: "transactionAmount",
                value: data.amount ?
                    getPlainText(`${FormatNumber(data.amount)} ${data.currency}`) :
                    '-'
            },
            {
                label: "Merchant Name",
                name: "merchantName",
                value: getPlainText(data.merchantInformation?.merchantName)
            },
            {
                label: "Merchant Contact",
                name: "merchantContact",
                value: getPlainText(data.merchantInformation?.merchantContactPhone)
            },
            {
                label: "Store Name",
                name: "storeName",
                value: getPlainText(data.merchantInformation?.storeDetails?.storeName)
            },
            {
                label: "Merchant URL",
                name: "merchantUrl",
                value: getLink(data.merchantInformation?.merchantUrl)
            }
        ];

        if (data.receipt?.productsPurchasedList?.[0]?.deliveryDetails) {
            const delivery = data.receipt.productsPurchasedList[0].deliveryDetails;
            fields.push(
                {
                    label: "Shipping Carrier",
                    name: "shippingCarrier",
                    value: getPlainText(delivery.shippingCarrier)
                },
                {
                    label: "Tracking Number",
                    name: "trackingNumber",
                    value: getPlainText(delivery.trackingNumber)
                },
                {
                    label: "Delivery Status",
                    name: "deliveryStatus",
                    value: getPlainText(delivery.otherStatusDescription)
                },
                {
                    label: "Shipment Date",
                    name: "dateOfShipment",
                    value: getFormatDate(delivery.dateOfShipment)
                },
                {
                    label: "Delivery Date",
                    name: "dateOfDelivery",
                    value: getFormatDate(delivery.dateOfDelivery)
                }
            );
        }

        return fields.length ? showCardInfo(fields) : showCardEmpty();
    };

    const pastTransactionsColumns = [
        {
            title: "Date of Purchase",
            dataIndex: "dateOfPurchase",
            key: "dateOfPurchase",
            render: date => date ? getFormatDate(date) : '-',
        },
        {
            title: "Amount of Purchase",
            dataIndex: "amountOfPurchase",
            key: "amountOfPurchase",
            align: "right",
            render: amount => amount ? FormatNumber(amount) : '-',
        },
        {
            title: "Credit Card Number",
            dataIndex: "creditCardNumber",
            key: "creditCardNumber",
            render: (_, record) =>
            (record.creditCardBin && record.creditCardFour)
                ? `${record.creditCardBin}******${record.creditCardFour}`
                : '-',
        },
        {
            title: "Recurring Transaction",
            dataIndex: "recurringTransaction",
            key: "recurringTransaction",
            render: value => value || '-',
        },
    ];

    const getProductPurchasedTable = () => {
        let dataSource = [];
        if (data.receipt && data.receipt.productsPurchasedList) {
            dataSource = data.receipt.productsPurchasedList.map((product, index) => ({
                ...product,
                key: index, 
                unitPriceAmount: product.unitPriceAmount?.amount,
                unitPriceCurrency: product.unitPriceAmount?.currency,
                productUrl: product.productUrl
            }));
        }
        const columns = [
            {
                dataIndex: "productDescription",
                key: "productDescription",
                title: t("tables.fields.productdescription"),
            },
             {
              dataIndex: "productUrl",
              key: "productUrl",
              title: t("tables.fields.producturl"),
              render: (productUrl) => productUrl ? <a href={productUrl} target="_blank" rel="noopener noreferrer">{productUrl}</a> : '-',
          },
            {
                dataIndex: "unitPriceAmount",
                key: "unitPriceAmount",
                title: t("tables.fields.unitpriceamount"),
                align: "left",
                width: 150,
                render: (unitPriceAmount, record) => `${FormatNumber(unitPriceAmount)} ${record.unitPriceCurrency}`,
            },
            {
                dataIndex: "quantity",
                key: "quantity",
                title: t("tables.fields.quantity"),
                align: "left",
                width: 80,
            },
        ];

        return <Table columns={columns} rowKey="key" pagination={false} dataSource={dataSource} />;
    };

    const getLookupHistoryTable = () => {
        const columns = [
            {
                dataIndex: "_id",
                key: "_id",
                title: t("tables.fields.id"),
            },
            {
                dataIndex: "lookupReference",
                key: "lookupReference",
                title: t("tables.fields.lookupreference"),
            },
            {
                dataIndex: "lookupDate",
                key: "lookupDate",
                title: t("tables.fields.lookupdate"),
                render: (lookupDate, record) => getFormatDate(lookupDate),
            },
        ];

        return <Table columns={columns} rowKey="_id" pagination={false} dataSource={lookup} />;
    };

    const getAttachments = () => {
        const columns = [
            {
                dataIndex: "id",
                key: "id",
                width: 200,
                title: t("contents.oi.form.attachmentsid"),
            },
            {
                dataIndex: "friendlyFileName",
                key: "friendlyFileName",
                title: t("contents.oi.form.attachmentsfile"),
            },
            {
                dataIndex: "data",
                key: "data",
                width: 150,
                title: t("tables.fields.action"),
                render: (data, record) => (
                    <a download={record.friendlyFileName} href={`data:${record.mimeType};base64,${data}`} target="_blank" rel="noopener noreferrer">
                        {t("tables.actions.download")}
                    </a>
                ),
            },
        ];

        return <Table columns={columns} rowKey="id" pagination={false} dataSource={attachment ? attachment : []} />;
    };


    const showCardEmpty = () => (
        <Card style={{ marginBottom: "1rem" }}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Card>
    );

    const showCardInfo = (fields) => (
        <Card style={{ marginBottom: "1rem" }}>
            <Form size="small" {...formItemLayout}>
                <Row gutter={24}>
                    {fields.map((field) => (
                        <Col key={field.name} xs={24} md={12} offset={field.offset} style={{ marginBottom: 12 }}>
                            <span style={{ color: "rgba(0, 0, 0, 0.85)", fontWeight: "500", marginRight: 6 }}>
                                {field.label}:
                            </span>
                            {field.value}
                        </Col>
                    ))}
                </Row>
            </Form>
        </Card>
    );

    const showCardTwoBlockInfo = (fields) => (
        <Form size="small" {...formItemLayout}>
            {fields.map((field) => (
                <Row key={field.name}>
                    <Col span={24} style={{ marginBottom: 12 }}>
                        <span style={{ color: "rgba(0, 0, 0, 0.85)", fontWeight: "500", marginRight: 6 }}>
                            {field.label}:
                        </span>
                        {field.value}
                    </Col>
                </Row>
            ))}
        </Form>
    );

    if (loading) {
        return (
            <div style={{ textAlign: 'center', padding: '50px' }}>
                <Spin size="large" />
            </div>
        );
    }

    if (!data) {
        return (
            <div style={{ textAlign: 'center', padding: '50px' }}>
                <Empty
                    description={t("contents.oi.cart_not_found") || "Cart not found"}
                />
            </div>
        );
    }

    return (
        <div>
            <PageHeader
                className="site-page-header"
                title={`${t("contents.oi.cart_details")} (${data.txShieldOICartReference})`}
            />
            <Row gutter={[16, 16]} style={{ marginBottom: "1rem" }}>
                <Col span={12}>
                    <Card style={{ minHeight: 430 }}>
                        {getCartDetailsLeftForm()}
                    </Card>
                </Col>
                <Col span={12}>
                    <Card style={{ minHeight: 430 }}>
                        {getCartDetailsRightForm()}
                    </Card>
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="tab-wrap">
                <Col span={24}>
                    <Tabs type="card">
                        <TabPane tab={t("contents.oi.tabs.customer_information")} key="customerInformation">
                            {getCustomerInformationForm()}
                        </TabPane>

                        <TabPane tab={t("contents.oi.tabs.product_purchased")} key="productPurchased">
                            {getProductPurchasedTable()}
                        </TabPane>

                        <TabPane tab="Past Transactions" key="pastTransactions">
                            <Table
                                columns={pastTransactionsColumns}
                                dataSource={data.details?.pastTransactions || []}
                                rowKey="dateOfPurchase"
                                pagination={false}
                            />
                        </TabPane>

                        <TabPane tab={t("contents.oi.tabs.lookup_history")} key="lookup">
                            {getLookupHistoryTable()}
                        </TabPane>

                        <TabPane tab={t("contents.oi.tabs.attachments")} key="attachments">
                            {getAttachments()}
                        </TabPane>

                    </Tabs>
                </Col>
            </Row>
        </div>
    );
};

export default CartDetail;
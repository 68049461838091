import { API } from "../config/config";
import axios from "axios";

const FetchUserAccess = async (accessToken, uid) => {
    return new Promise((resolve, reject) => {
        if (Number.isNaN(uid)) { // check if uid is not numeric
            reject(new Error('Invalid Uid'));
        }
        axios.post(API + '/useraccess', { uid: uid }, { withCredentials: true, headers: { Authorization: "Bearer " + accessToken } }).then((res) => {
            if (res.data) {
                resolve({ access: res.data.access });
            }
            reject(new Error('Invalid Result'));
        }).catch((err) => {
            reject(new Error('Invalid Result'));
        })
    })
}

export default FetchUserAccess;
import React, {  useContext, useRef, useState, useCallback, useEffect  } from "react";
import {  Button, Form, Input, Popconfirm, Table, Select, Card, Col, Row, Divider, Modal, Space, Checkbox, Tooltip } from "antd";
import { MinusCircleOutlined, PlusOutlined, ExclamationCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import Swal from "sweetalert2";
import { API_TXALERT, API_MERCHANT, API_RETAILER, API_SITE } from "../../config/config";
import GroupAccess from "./../../helper/GroupAccess";
import htmlParser from "html-react-parser";

const MERCHANT_DATA_URL = API_MERCHANT + "/list";
const RETAILER_DATA_URL = API_RETAILER + "/list";
const SITE_DATA_URL = API_SITE + "/list";
const { confirm } = Modal;
const DATA_URL =  API_TXALERT + "/merchant_descriptor_enrollment/create";
const { Option } = Select;
const EditableContext = React.createContext(null);
const MainContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    saveTableData,
    ...restProps
    }) => {
    const inputRef = useRef(null);
    const mainform = useContext(MainContext);
    const subform = useContext(EditableContext);
    const save = async () => {
        try {
            const values = await subform.validateFields();
            handleSave({
            ...record,
            ...values,
            });
            saveTableData(true);
        } catch (errInfo) {
            //console.log('Save failed:', errInfo);
            saveTableData(false);
        }
    };

    let childNode = children;
    if (editable) {
        childNode = (
            <Form.Item
                style={{margin: 0}}
                name={dataIndex}
                rules={ dataIndex === 'descriptor' ? [{required: true, message: `${title} is required.`,}] : 
                            dataIndex === 'match_type' ? [
                                ({ getFieldValue }) => ({
                                    async validator(_, value) {
                                        try {
                                            const product = mainform.getFieldValue('products');
                                            if (!value && (product.includes('ethoca')||product.includes('verifi'))) {
                                                throw new Error("Match type is required.");
                                            } 
                                            return Promise.resolve();
                                        } catch (error) {
                                            return Promise.reject(error.message);
                                        }
                                    },
                                }),
                            ] :
                            dataIndex === 'mcc' ? [
                                ({ getFieldValue }) => ({
                                    async validator(_, value) {
                                        try {
                                            const product = mainform.getFieldValue('products');
                                            if (!value && product.includes('rdr')) {
                                                throw new Error("MCC is required.");
                                            } 
                                            return Promise.resolve();
                                        } catch (error) {
                                            return Promise.reject(error.message);
                                        }
                                    },
                                }),
                            ] :
                            (dataIndex === 'visa_bin') ? [
                                ({ getFieldValue }) => ({
                                    async validator(_, value) {
                                        try {
                                            const product = mainform.getFieldValue('products');
                                            if ( !value && !getFieldValue('visa_arn') && product.includes('rdr')) {
                                                throw new Error("Visa BIN and CAID are required as a pair if Visa ARN is null.");
                                            } 
                                            return Promise.resolve();
                                        } catch (error) {
                                            return Promise.reject(error.message);
                                        }
                                    },
                                }),
                            ] :
                            (dataIndex === 'visa_caid') ? [
                                ({ getFieldValue }) => ({
                                    async validator(_, value) {
                                        try {
                                            const product = mainform.getFieldValue('products');
                                            if ( !value && !getFieldValue('visa_arn') && product.includes('rdr')) {
                                                throw new Error("Visa BIN and CAID are required as a pair if Visa ARN is null.");
                                            } 
                                            return Promise.resolve();
                                        } catch (error) {
                                            return Promise.reject(error.message);
                                        }
                                    },
                                }),
                            ] :
                            dataIndex === 'visa_arn' ? [
                                ({ getFieldValue }) => ({
                                    async validator(_, value) {
                                        try {
                                            const product = mainform.getFieldValue('products');
                                            if (!value && product.includes('rdr') && (!getFieldValue('visa_bin') && !getFieldValue('visa_caid')) ) {
                                                throw new Error("Visa ARN is required if Visa CAID or Visa BIN are null");
                                            } 
                                            return Promise.resolve();
                                        } catch (error) {
                                            return Promise.reject(error.message);
                                        }
                                    },
                                }),
                            ] 
                            : null
                        }
                >
                {dataIndex === 'match_type' ? 
                <Select 
                    style={{ width: "100%" }} 
                    placeholder="match_type"
                    onChange={save}
                    >
                        <Option value="Starts with">Starts with</Option>
                        <Option value="Exact">Exact</Option>
                </Select> 
                :
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />}
            </Form.Item>
        ) ;
    }
    return (<td {...restProps}>{childNode}</td>);
};
const DescriptorEnrollmentForm = (props) => {
    const [count, setCount] = useState(1);
    const [dataSource, setDataSource] = useState([
        {
            key: 0,
            descriptor: '',
            match_type: '',
            mcc: '',
            visa_bin: '',
            visa_caid: '',
            visa_arn: '',
            rdr_ruleset: ''
        }
    ]);
    const { t } = props;
    const [form] = Form.useForm();
    const [formData, setFormData] = useState();
    const [productEthoca, setProductEthoca] = useState(false);
    const [productVerifi, setProductVerifi] = useState(false);
    const [productRDR, setProductRDR] = useState(false);
    const [merchantList, setMerchantList] = useState([]);
    const [retailerList, setRetailerList] = useState([]);
    const [siteList, setSiteList] = useState([]);
    const [inactiveChecked, setInactiveChecked] = useState(false);
    const [merchant, setMerchant] = useState([]);
    const [merchantName, setMerchantName] = useState('');
    const [retailer, setRetailer] = useState([]);
    const [site, setSite] = useState([]);
    const [siteName, setSiteName] = useState('');
    const [canSubmit, setCanSubmit] = useState(false);

    const handleDelete = (key) => {
        const newData = dataSource.filter((item) => item.key !== key);
        setDataSource(newData);
    };
    const defaultColumns = [
        {
            title: t("tables.fields.descriptor"),
            dataIndex: 'descriptor',
            width: '30%',
            editable: true,
        },
        {
            title: t("tables.fields.match_type"),
            dataIndex: 'match_type',
            editable: true,
            hidden: productEthoca||productVerifi ? false : true
        },
        {
            title: t("tables.fields.mcc"),
            dataIndex: 'mcc',
            editable: true,
            hidden: productRDR ? false : true
        },
        {
            title: t("tables.fields.visa_bin"),
            dataIndex: 'visa_bin',
            editable: true,
            hidden: productRDR ? false : true
        },
        {
            title: t("tables.fields.visa_caid"),
            dataIndex: 'visa_caid',
            editable: true,
            hidden: productRDR ? false : true
        },
        {
            title: <Tooltip title={t("contents.descriptor_enrollment.tooltip.visa_arn")}>{t("tables.fields.visa_arn")} <InfoCircleOutlined /></Tooltip>,
            dataIndex: 'visa_arn',
            editable: true,
            hidden: productRDR ? false : true
        },
        {
            title: <Tooltip title={t("contents.descriptor_enrollment.tooltip.rule_set")}>{t("tables.fields.rdr_ruleset")} <InfoCircleOutlined /></Tooltip>,
            dataIndex: 'rdr_ruleset',
            editable: true,
            tooltip: t("contents.alerts.tooltip.outcome"),
            hidden: productRDR ? false : true
        },
        {
            title: t("tables.fields.action"),
            dataIndex: 'action',
            render: (_, record) =>
            dataSource.length >= 1 ? (
                <Popconfirm  title={t("contents.descriptor_enrollment.delete_confirm")} onConfirm={() => handleDelete(record.key)}>
                    <Button>{t("contents.descriptor_enrollment.delete")}</Button>
                </Popconfirm>
            ) : null,
        },
    ].filter(item => !item.hidden);
    const handleAdd = () => {
        const newData = {
            key: count,
            descriptor: '',
            match_type: '',
            mcc: '',
            visa_bin: '',
            visa_caid: '',
            visa_arn: '',
            rdr_ruleset: ''
        };
        setDataSource([...dataSource, newData]);
        setCount(count + 1);
    };

    const saveTableData = (value) => {
        setCanSubmit(value);
    };

    const handleSave = (row) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        setDataSource(newData);
    };
    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };
    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return (col);
        }
        return ({
            ...col,
            onCell: (record) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave,
            saveTableData
            }),
        });
    });

    const onChangeProduct = (value) => {
        if(value.includes('ethoca')){
            setProductEthoca(true);
        }else setProductEthoca(false);
        if(value.includes('verifi')){
            setProductVerifi(true);
        }else setProductVerifi(false);
        if(value.includes('rdr')){
            setProductRDR(true);
        }else setProductRDR(false);
    }

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.descriptor_enrollment.descriptors")}</h3>
            <Button
                onClick={handleAdd}
                type="primary"
                style={{
                marginBottom: 16,
                }}
            >
                {t("contents.descriptor_enrollment.add_a_row")}
            </Button>
        </div>
    );

    const formValChange = (chgV, allV) => {
        setFormData(allV);
    };

    const onFinish = () => {
        if(!canSubmit){
            Swal.fire({
                icon: "error",
                title: t("contents.descriptor_enrollment.confirm.title_error_save_data_table"),
                text:  t("contents.descriptor_enrollment.confirm.error_save_data_table"),
                showConfirmButton: true,
            });
            return false;
        }
        if(dataSource){
            let emptyRow = dataSource.filter((item) => item.descriptor === '').length;
            if(emptyRow>0){
                Swal.fire({
                    icon: "error",
                    title: t("contents.descriptor_enrollment.confirm.title_emptyrow"),
                    text:  t("contents.descriptor_enrollment.confirm.emptyrow"),
                    showConfirmButton: true,
                });
                return false;
            }
        }
        let data = {};
        if (formData) {

            let emails = [];
            if(formData.emails){
                emails = formData.emails.map(function(item) {
                    return item['email'];
                });
            }
            emails = [JSON.parse(localStorage.getItem("loggedUser")).email].concat(emails);

            data = {
                mid: merchant.toString(),
                merchantname: merchantName,
                sid: site.toString(),
                sitename: siteName,
                email_cc_list: emails.toString(),//array
                products: (formData.products).toString(),//array
                enrollment_items: dataSource
            };
        }
        confirm({
            title: t("contents.descriptor_enrollment.confirm.title"),
            content: (
                <div>
                    <p>
                        <br />
                        {t("contents.descriptor_enrollment.confirm.merchant")}: <strong>{merchantName}</strong> 
                        <br />
                        {t("contents.descriptor_enrollment.confirm.site")}: <strong>{siteName}</strong>
                        <br />
                        {t("contents.descriptor_enrollment.confirm.email_cc_list")}: <strong>{data.email_cc_list}</strong>
                        <br />
                        {t("contents.descriptor_enrollment.confirm.product")}: <strong>{data.products}</strong>
                        <br />
                    </p>
                    <table border="1" style={{width:"100%"}}>
                        <thead>
                            <tr>
                                <th>{t("tables.fields.descriptor")}</th>
                                {productEthoca || productVerifi ? <th>{t("tables.fields.match_type")}</th> : null}
                                {productRDR ? <th>{t("tables.fields.mcc")}</th> : null}
                                {productRDR ? <th>{t("tables.fields.visa_bin")}</th> : null}
                                {productRDR ? <th>{t("tables.fields.visa_caid")}</th> : null}
                                {productRDR ? <th>{t("tables.fields.visa_arn")}</th> : null}
                                {productRDR ? <th>{t("tables.fields.rdr_ruleset")}</th> : null}
                            </tr>
                        </thead>
                        <tbody>
                            {dataSource
                                ? dataSource.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.descriptor}</td>
                                        {productEthoca || productVerifi ? <td>{item.match_type}</td> : null}
                                        {productRDR ? <td>{item.mcc}</td> : null}
                                        {productRDR ? <td>{item.visa_bin}</td> : null}
                                        {productRDR ? <td>{item.visa_caid}</td> : null}
                                        {productRDR ? <td>{item.visa_arn}</td> : null}
                                        {productRDR ? <td>{item.rdr_ruleset}</td> : null}
                                    </tr>
                                  ))
                                : null}
                        </tbody>
                    </table>
                </div>
            ),
            icon: <ExclamationCircleOutlined />,
            okText: t("contents.descriptor_enrollment.confirm.confirm_button"),
            okType: "danger",
            cancelText: t("contents.descriptor_enrollment.confirm.cancel_button"),
            width: '80%',
            onOk() {
                return new Promise((resolve, reject) => {
                    axios
                        .post(DATA_URL, data,{ headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), Authuser: localStorage.getItem("authuser") }})
                        .then(({ data }) => {
                            if (data.status === "ok") {
                                Swal.fire({
                                    icon: "success",
                                    title: "Merchant Descriptor Enrollment Created",
                                    showConfirmButton: true,
                                    onClose: () => {
                                        window.location.reload();
                                    },
                                });
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    title: t("login.dialogs.catch"),
                                    text: data.error_msg.msg ? data.error_msg.msg + (data.error_msg.detail? ". Detail: "+data.error_msg.detail : "") : "",
                                    showConfirmButton: true,
                                });
                            }
                        })
                        .catch((error) => {
                            Swal.fire({
                                icon: "error",
                                title: t("login.dialogs.catch"),
                                text: error.response.data.error_msg.msg ? error.response.data.error_msg.msg + (error.response.data.error_msg.detail? ". Detail: "+error.response.data.error_msg.detail : "") : "",
                                showConfirmButton: true,
                            });
                        });
                });
            },
            onCancel() {
                return false;
            },
        });
    };

    const getMerchantList = useCallback(() => {
        axios
            .post(
                MERCHANT_DATA_URL,
                {getall:true},
                { headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), Authuser: localStorage.getItem("authuser") } }
            )
            .then(async ({ data }) => {
                await setMerchantList(data.list);
                if(data.list.length === 1 ){
                    setMerchant([data.list[0].mid]);
                    setMerchantName(htmlParser(data.list[0].name) + ` (mid: ` + data.list[0].mid + `)`);
                }
                else{
                    setMerchant([]);
                }
            })
            .catch(() => false);
    }, []);

    const getRetailerList = useCallback((mid_change) => {
        axios
            .post(
                RETAILER_DATA_URL,
                { mid: mid_change },
                { headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), Authuser: localStorage.getItem("authuser") } }
            )
            .then(async ({ data }) => {
                await setRetailerList(data.list);
                if(data.list.length === 1 )
                    setRetailer([data.list[0].rid]);
                else{
                    setRetailer([]);
                }
            })
            .catch(() => false);
    }, []);

    const getSiteList = useCallback((mid_change, rid_change) => {
        axios
            .post(
                SITE_DATA_URL,
                { mid: mid_change, rid: rid_change },
                { headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), Authuser: localStorage.getItem("authuser") } }
            )
            .then(async ({ data }) => {
                await setSiteList(data.list);   
                if(data.list.length === 1 ){
                    setSite([data.list[0].sid]);
                    setSiteName(htmlParser(data.list[0].name) + ` (sid: ` + data.list[0].sid + `)`);
                }else{
                    setSite([]);
                }
            })
            .catch(() => false);
    }, []);

    useEffect(() => {
        if (merchantList.length === 0) {           
            getMerchantList();
        }
        if (retailerList.length === 0) {
            getRetailerList();
        }
        if (siteList.length === 0) {
            getSiteList();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeMid = (mid_change,index) => {
        let mid_select = JSON.parse("[" + mid_change + "]");
        setMerchantName(index.children);
        setMerchant(mid_select);
    }

    const onChangeRid = (rid_change,index) => {
        let rid_select = JSON.parse("[" + rid_change + "]");
        setRetailer(rid_select);
    }

    const onChangeSid = (sid_change,index) => {
        let sid_select = JSON.parse("[" + sid_change + "]");
        setSite(sid_select);
        setSiteName(index.children);
    }

    useEffect(() => {
        getRetailerList(merchant);
        getSiteList(merchant, retailer);
        form.setFieldsValue({merchant:merchant});
    }, [merchant]);

    useEffect(() => {
        getSiteList(merchant, retailer);
        form.setFieldsValue({retailer:retailer});
    }, [retailer]);

    useEffect(() => {
        form.setFieldsValue({site:site});
    }, [site]);

    const onChangeInactive = (e) => {
        setInactiveChecked(e.target.checked);
    };

    const getMerchant = () => {
        if (inactiveChecked) {
            return merchantList.map((item) => (
                item.status === 'active' ?
                    <Option key={item.mid} value={item.mid}>
                        {htmlParser(item.name) + ` (mid: ` + item.mid + `)`}
                    </Option>
                    : <Option key={item.mid} value={item.mid} style={{ color: "#f5222d" }}>
                        {htmlParser(item.name) + ` (mid: ` + item.mid + `)`}
                    </Option>
            ))
        } else {
            return merchantList.map((item) => (
                item.status === 'active'
                    ?
                    <Option key={item.mid} value={item.mid}>
                        {htmlParser(item.name) + ` (mid: ` + item.mid + `)`}
                    </Option>
                    :
                    null
            ))
        }
    };
      
    return (
        <div>
            <div className="search-wrap">
                <Card title={t("contents.descriptor_enrollment.title_form")} size="small">
                    <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form"  onFinish={onFinish} onValuesChange={formValChange}>
                        <MainContext.Provider value={form}>
                            <Row gutter={[16, 16]}>
                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item name="merchant"  rules={[{ required: true, message: t('required_field') }]}>
                                        <Select
                                            showSearch
                                            value={merchant}
                                            showArrow="true"
                                            style={{ width: "100%" }}
                                            optionFilterProp="children"
                                            onChange={(val,index) => onChangeMid(val,index)}
                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                            placeholder={t("contents.merchants.title")}
                                            dropdownRender={(menu) => GroupAccess("SYSADMIN") ? (
                                                <>
                                                    {menu}

                                                    <Divider
                                                        style={{
                                                            margin: '8px 0',
                                                        }}
                                                    />
                                                    <Space
                                                        style={{
                                                            padding: '0 8px 4px',
                                                        }}
                                                    >
                                                        <Checkbox onChange={onChangeInactive}>Show Inactive?</Checkbox>
                                                    </Space>
                                                </>
                                            ) : (
                                                <>
                                                    {menu}
                                                </>
                                            )}
                                        >
                                            {merchantList
                                                ? getMerchant()
                                                : null}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item name="retailer">
                                        <Select
                                            showSearch
                                            value={retailer}
                                            showArrow="true"
                                            style={{ width: "100%" }}
                                            optionFilterProp="children"
                                            onChange={(val,index) => onChangeRid(val,index)}
                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                            placeholder={t("contents.retailers.title")}>
                                            {retailerList
                                                ? retailerList.map((item) => (
                                                    <Option key={item.rid} value={item.rid}>
                                                        {htmlParser(item.name) + ` (rid: ` + item.rid + `)`}
                                                    </Option>
                                                ))
                                                : null}
                                        </Select>
                                </Form.Item>
                                </Col>
                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item name="site"  rules={[{ required: true, message: t('required_field') }]}>
                                        <Select
                                            showSearch
                                            value={site}
                                            showArrow="true"    
                                            style={{ width: "100%" }}
                                            optionFilterProp="children"
                                            onChange={(val,index) => onChangeSid(val,index)}
                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                            placeholder={t("contents.sites.title")}>
                                            {siteList
                                                ? siteList.map((item) => (
                                                    <Option key={item.sid} value={item.sid}>
                                                        {htmlParser(item.name) + ` (sid: ` + item.sid + `)`}
                                                    </Option>
                                                ))
                                                : null}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                                <article className="ant-typography" style={{maxHeight: 400, overflowY: "auto"}}>
                                    <div className="ant-typography well">
                                    Completing this form results in an email to our customer care team. Our team will then begin processing your request with the relevant providers. The email [Email address for account] will be cc'd on this email along with any emails you add via Additional Email + Add CC email.
                                    </div>
                                </article>
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.List name="emails">
                                        {(fields, { add, remove }) => (
                                            <>
                                            {fields.map((field, index) => {
                                                return (
                                                    <Row key={index}>
                                                        <Col span={3} style={{ textAlign: 'left' }}>
                                                            {index === 0 ? "Email: ": null}
                                                        </Col>
                                                        <Col span={20}>
                                                            <Form.Item {...field} name={[field.name, 'email']} 
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        type: "email",
                                                                        message: "The input is not valid E-mail!",
                                                                    },
                                                                ]}
                                                                style={{ margin: '5px' }}>
                                                                <Input placeholder={t("tables.fields.email")} style={{ width: '100%' }} />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={1} style={{ alignContent:"center" }}>
                                                            <MinusCircleOutlined onClick={() => remove(field.name)} />
                                                        </Col>
                                                    </Row>
                                                );
                                            })}
                                            
                                            <Form.Item>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => add()}
                                                    block
                                                    icon={<PlusOutlined />}
                                                >
                                                    Add CC email
                                                </Button>
                                            </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                </Col>
                            </Row>
                            <Divider orientation="left">{t("contents.descriptor_enrollment.descriptors_section")}</Divider>
                            <Row gutter={[16, 16]}>
                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>      
                                    <Form.Item name="products" rules={[{ required: true, message: t('required_field') }]}>
                                        <Select placeholder={t("contents.descriptor_enrollment.products")}
                                                showSearch
                                                mode="multiple" 
                                                showArrow="true"
                                                onChange={onChangeProduct}>
                                            <Option value="ethoca">Ethoca</Option>
                                            <Option value="verifi">Verifi CDRN</Option>
                                            <Option value="rdr">RDR</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            
                            <Table
                                title={() => getHeaderTable()}
                                components={components}
                                rowClassName={() => 'editable-row'}
                                bordered
                                dataSource={dataSource}
                                columns={columns}
                                pagination={false}
                                footer={() => (
                                    <div className="d-flex justify-content-end align-items-center">
                                        <Button
                                            onClick={handleAdd}
                                            type="primary"
                                        >
                                            {t("contents.descriptor_enrollment.add_a_row")}
                                        </Button>
                                    </div>)}

                            />
                            <Row type="flex" justify="center" align="center">
                                <Col>
                                    <Form.Item style={{marginTop: 10}}>
                                        <Button type="primary" htmlType="submit" block >
                                        {t("contents.descriptor_enrollment.submit_form")}
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </MainContext.Provider>
                    </Form>
                </Card>
            </div>
        </div>
    );
};

export default DescriptorEnrollmentForm;

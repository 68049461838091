import UserSettings from "../UserSettings";
import Multilingual from "../Multilingual";
import { useLocation } from "react-router-dom";

const CustomPaginationV2 = ({ totalResult, currentPage, requestData, setRequestData, setCurrentPage }) => {
    const [t] = Multilingual(); // eslint-disable-line
    const location = useLocation();
    const { pathname } = location;
    const keys = pathname.substring(1).split("/");
    const pageName = keys[0];
    const [updateSettings] = UserSettings();
    
    // Get user's preferred page size from localStorage
    let initDefaultPageSize = 20;
    let loggedUserSettings = localStorage.getItem("loggedUserSettings") ? JSON.parse(localStorage.getItem("loggedUserSettings")) : {};
    let loggedPageSize = 0;
    
    if (loggedUserSettings) {
        if (loggedUserSettings.defaultTablePageSize) {
            if (loggedUserSettings.defaultTablePageSize[pageName]) {
                initDefaultPageSize = parseInt(loggedUserSettings.defaultTablePageSize[pageName]);
                loggedPageSize = parseInt(loggedUserSettings.defaultTablePageSize[pageName]);
            }
        }
    }

    // Ensure we always use the numeric value of page and size for consistency
    const currentPageNum = parseInt(currentPage) || 1;
    const pageSizeNum = parseInt(requestData.size) || initDefaultPageSize;

    const onPaginationChange = (page, pageSize) => {
        // Ensure page and pageSize are numeric
        const numPage = parseInt(page);
        const numPageSize = parseInt(pageSize);
        
        // Update user settings if page size changed
        if (loggedUserSettings) {
            if (!loggedUserSettings.defaultTablePageSize) {
                loggedUserSettings.defaultTablePageSize = {};
            }
            loggedUserSettings.defaultTablePageSize[pageName] = numPageSize;
            
            if (loggedPageSize !== numPageSize) {
                updateSettings(loggedUserSettings);
                localStorage.setItem("loggedUserSettings", JSON.stringify(loggedUserSettings));
            }
        }

        setCurrentPage(numPage);
        
        setRequestData(prevData => ({
            ...prevData, 
            page: numPage, 
            size: numPageSize 
        }));
    };
    
    // Improved range calculation
    const getShowTotal = (total, range) => {
        if (total === 0) return "0-0 of 0 items";
        
        // Make sure we show correct numbers even if range calculation is wrong
        const start = Math.max(1, (currentPageNum - 1) * pageSizeNum + 1);
        const end = Math.min(total, currentPageNum * pageSizeNum);
        
        return `${start}-${end} of ${total} items`;
    };

    // Make sure we consistently return numbers for page and pageSize
    return {
        current: currentPageNum,
        total: parseInt(totalResult) || 0,
        position: ["topRight", "bottomRight"],
        pageSize: pageSizeNum,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"],
        showTotal: getShowTotal,
        onChange: onPaginationChange,
        onShowSizeChange: onPaginationChange,
    };
};

export default CustomPaginationV2;
import React, { useState, useEffect } from "react";
import { OI_URL } from "../../config/config";
import DataFetchHookOI from "../../helper/DataFetchHookOI";
import PermissionsRequired from "../../helper/PermissionsRequired";
import CreateMerchant from "./CreateMerchant";
import MerchantModal from "./Merchant";
import moment from "moment";
import { Table, PageHeader, Button, Card, Form, Select, Row, Col, Input } from "antd";
import { SearchOutlined, FileSearchOutlined } from "@ant-design/icons";
import AssociationsChecker from "./../../helper/AssociationsChecker";
import CustomPaginationV2 from "../../helper/Table/CustomPaginationV2";
import SortOrder from "../../helper/Table/SortOrder";

const DATA_URL = "/api/v1/txshield/merchants";
const { Option } = Select;

const MerchantList = (props) => {
    // Initial request data with numeric page and size values
    const initialRequest = {
        page: 1,
        size: 20,
        order: "ASC",
        sortField: "dateCreated"
    };
    
    const [{ data, totalResult, isLoading, requestData }, doFetch, setRequestData] = DataFetchHookOI(
        DATA_URL, 
        "POST", 
        null, 
        initialRequest
    );
    
    const [formData, setFormData] = useState({ hideColumn: [], associatedMerchantIds: [] });
    const [form] = Form.useForm();
    const [currentPage, setCurrentPage] = useState(1);
    const [merchant, setMerchant] = useState(null);
    const [sortedInfo, setSortedInfo] = useState({
        columnKey: "dateCreated",
        order: "ascend"
    });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const oiAssocication = localStorage.getItem("oiAssocication") ? localStorage.getItem("oiAssocication").split(",") : [];
    const { t } = props;
    const initFormData = {
        field: "name",
        keyword: null,
        associatedMerchantIds: oiAssocication,
    };

    useEffect(() => {
        formValInit();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // Ensure pagination state is synchronized with requestData
    useEffect(() => {
        if (requestData.page && parseInt(requestData.page) !== parseInt(currentPage)) {
            setCurrentPage(parseInt(requestData.page));
        }
    }, [requestData.page, currentPage]);

    const showMerchant = (merchantId) => {
        setMerchant(merchantId);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleChange = (pagination, filters, sorter) => {
        // Don't do anything if this is a pagination change - that's handled by CustomPaginationV2
        if (pagination.current === currentPage && pagination.pageSize === requestData.size) {
            setSortedInfo(sorter);
            
            if (sorter && sorter.columnKey) {
                // Reset to page 1 when sorting changes
                setCurrentPage(1);
                setRequestData({
                    ...requestData,
                    page: 1, // Always reset to page 1 when sorting changes
                    sortField: sorter.columnKey,
                    order: sorter.order === "ascend" ? "ASC" : "DESC"
                });
            }
        }
    };

    const formValInit = () => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);
    };

    const formValChange = (chgV, allV) => {
        setFormData(allV);
    };

    const clearAll = () => {
        const resetData = {
            ...initFormData,
            sortField: "dateCreated",  
            order: "ASC"               
        };
        
        setFormData(resetData);
        setCurrentPage(1);
        setRequestData({ 
            ...requestData, 
            ...resetData, 
            page: 1,
            size: parseInt(requestData.size) || 20
        });
        form.setFieldsValue(resetData);
        
        setSortedInfo({
            columnKey: "dateCreated",
            order: "ascend"
        });
    }

    const columns = [
        {
            dataIndex: "merchantId",
            key: "merchantId",
            title: t("tables.fields.merchantid"),
            sorter: {
                compare: (a, b) => a.merchantId.localeCompare(b.merchantId),
            },
            sortOrder: SortOrder(sortedInfo, "merchantId"),
        },
        {
            dataIndex: "dateCreated",
            key: "dateCreated",
            title: t("tables.fields.datecreated"),
            width: 180,
            defaultSortOrder: 'ascend',
            sorter: {
                compare: (a, b) => moment(a.dateCreated).diff(moment(b.dateCreated))
            },
            sortOrder: SortOrder(sortedInfo, "dateCreated"),
            render: (dateCreated) => moment(dateCreated).format("YYYY-MM-DD HH:mm:ss")
        },
        {
            dataIndex: "name",
            key: "name",
            title: t("tables.fields.merchantname"),
            sorter: {
                compare: (a, b) => a.name.localeCompare(b.name)
            },
            sortOrder: SortOrder(sortedInfo, "name"),
        },
        {
            dataIndex: "action",
            title: t("tables.fields.action"),
            align: "left",
            fixed: "right",
            width: 120,
            render: (text, record) => (
                <Button type="link" onClick={() => showMerchant(record.merchantId)} style={{ marginLeft: "auto" }}>
                    <FileSearchOutlined style={{ fontSize: "18px", color: "rgba(0, 0, 0, 0.85)" }} />
                </Button>
            ),
        },
    ];

    const onFinish = () => {
        // Create search data object with all necessary parameters
        let searchData = {
            keyword: formData.keyword ? formData.keyword.trim() : null,
            field: formData.field,
            sortField: sortedInfo.columnKey || "dateCreated", 
            order: sortedInfo.order === "ascend" ? "ASC" : "DESC",
            associatedMerchantIds: formData.associatedMerchantIds || [],
            page: 1, // Always reset to page 1 on search
            size: parseInt(requestData.size) || 20 // Maintain current page size
        };
    
        // Update component state and search
        setCurrentPage(1);
        setRequestData(searchData);
    };

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t('tables.actions.search')} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 5 }} xxl={{ span: 4 }}>
                            <Form.Item name="field">
                                <Select 
                                    showSearch
                                    style={{ width: "100%" }} 
                                    placeholder={t('tables.fields.field')}>
                                    <Option value="name">{t("tables.fields.merchantname")}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 9 }} xxl={{ span: 10 }}>
                            <Form.Item name="keyword">
                                <Input placeholder={t('tables.fields.keyword')} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6, offset: 12 }} lg={{ span: 6, offset: 0 }} xl={{ span: 3, offset: 3 }}>
                            <Form.Item>
                                <Button type="secondary" onClick={() => clearAll()} block>
                                    {t('tables.actions.clear_all')}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4}}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> {t('tables.actions.search')}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    const fetchMerchants = () => doFetch(OI_URL + DATA_URL + `?` + moment().unix());

    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.oi.merchants")}</h3>
            <CreateMerchant t={t} fetchMerchants={fetchMerchants} />
        </div>
    );
    
    // Use the proper numeric values for pagination
    const paginationConfig = CustomPaginationV2({
        totalResult: parseInt(totalResult) || 0,
        currentPage: parseInt(currentPage) || 1,
        requestData: {
            ...requestData,
            page: parseInt(requestData.page) || 1,
            size: parseInt(requestData.size) || 20
        },
        setRequestData,
        setCurrentPage
    });

    return PermissionsRequired("SYSADMIN") || AssociationsChecker("OI") ? (
        <div>
            <PageHeader title={t("contents.oi.merchants")}></PageHeader>
            {AdvancedSearchForm()}
            <MerchantModal t={t} merchantId={merchant} isModalVisible={isModalVisible} handleCancel={handleCancel} fetchMerchants={fetchMerchants} />
            <Table
                loading={isLoading}
                columns={columns}
                title={() => getHeaderTable()}
                rowKey="merchantId"
                className="table-v3 custom-pagination"
                size="small"
                dataSource={getDataSource(data.datalist)}
                onChange={handleChange}
                pagination={paginationConfig}
            />
        </div>
    ) : null;
};

export default MerchantList;
import React, { useState, useEffect } from "react";
import GroupAccess from "../../helper/GroupAccess";
import { PageHeader, Table, Tabs, Card, Form, Row, Col, Button, Typography } from "antd";
import FormatNumber from "../../helper/FormatNumber";
import DraggableModal from "../../helper/DraggableModal";
import { REALTIME_DASHBOARD } from "../../config/config";
import { API_TXALERT } from "../../config/config";
// import ChangeAlertStatus from "./ChangeAlertStatus";
import ChangeAlertStatusV2 from "./ChangeAlertStatusV2";
import RequestCredit from "./RequestCredit";
import RepostAlert from "./RepostAlert";
// import AddToBatch from "./AddToBatch";
import AlertsForReview from "./AlertsForReview";
import AlertCredits from "./AlertCredits";
// import AlertCreditDocuments from "./AlertCreditDocuments";
import axios from "axios";
import moment from "moment";
import htmlParser from "html-react-parser";
import { API } from "../../config/config";
import ColumnResizable from "../../helper/Table/ColumnResizable";
import AlertsForReviewRDR from "./AlertsForReview_RDR";
// import { WHITELABEL_DISPLAY_NAME } from "../../config/config";
const { Paragraph } = Typography;
const { TabPane } = Tabs;
const Alert = (props) => {
    const [data, setData] = useState(null);
    const [alert, setAlert] = useState(null);
    const [inabilitytoaction, setInabilitytoaction] = useState(null);
    const [outcome, setOutcome] = useState(null);
    const [reasons, setReasons] = useState([]); // eslint-disable-line
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState({ title: "", content: "" });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false);
    const [requestCreditDisabled, disableRequestCredit] = useState(true);
    // const [addToBatchDisabledButtonStatus, setAddToBatchDisabledButtonStatus] = useState(true);    
    const { t, socket } = props;

    useEffect(() => {
        if (typeof props.match.params.id != "undefined" && loading && !data) {
            getAlert(props.match.params.id);
        }
        //moved to getalert function 
      /*  if (typeof props.match.params.id != "undefined" && loading && reasons.length === 0) {
            getReasons();
        } */
    }, [alert,setAlert]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (alert && alert.alertid) {
            getAlert(alert.alertid);
        }
    }, [alert]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isRefresh) {
            getAlert(props.match.params.id);
        }
        getAlertCredits(props.match.params.id);
    }, [isRefresh]); // eslint-disable-line react-hooks/exhaustive-deps

    if (REALTIME_DASHBOARD && socket) {
        socket.on("requestAlertUpdate", async (receive) => {
            if (receive && receive.alertid && data) {
                if (data.parent[0].id === receive.alertid) {
                    setAlert(receive);
                }
            }
        });
    }

    const formItemLayout = {
        labelCol: { span: 9 },
        wrapperCol: { span: 15 },
    };

    const [columns, setColumns] = useState([
        {
            dataIndex: "alertdtid",
            key: "alertdtid",
            title: t("tables.fields.detail_id"),
            fixed: "left",
            width: 100,
            defaultSortOrder: "descend",
            sorter: (a, b) => a.alertdtid - b.alertdtid,
            hidden: !GroupAccess("SYSADMIN")
        },
        {
            dataIndex: "merchant_updatedate",
            key: "merchant_updatedate",
            title: t("tables.fields.date"),
            width: 100,
            render: (alertdate) => moment(alertdate).format("lll"),
        },
        {
            dataIndex: "merchant_request",
            key: "merchant_request",
            title: t("tables.fields.request"),
            width: 100,
            align: "left",
            render: (merchant_request, record) =>
                merchant_request
                    ? getButtonModal({ title: t("tables.fields.request"), content: merchant_request })
                    : record.action_request
                    ? getButtonModal({ title: t("tables.fields.request"), content: record.action_request })
                    : "",
        },
        {
            dataIndex: "status",
            key: "status",
            title: t("tables.fields.status"),
            width: 100,
            render: (status) => getOutcomeRender(status),
        },
        {
            dataIndex: "reason_code",
            key: "reason_code",
            title: t("tables.fields.reason_code"),
            width: 100,
        },
        {
            dataIndex: "reason",
            key: "reason",
            title: t("tables.fields.reason"),
            width: 250,
            render: (reason, record) =>
                (record.status === 'ERROR' && record.action === 'posttomerchant')
                    ? record.comment
                    : (reason ? htmlParser(reason) : ""),
        },
        {
            dataIndex: "merchant_response",
            key: "merchant_response",
            title: t("tables.fields.response"),
            width: 100,
            align: "left",
            render: (merchant_response, record) =>
                merchant_response
                    ? getButtonModal({ title: t("tables.fields.response"), content: merchant_response })
                    : record.action_response
                    ? getButtonModal({ title: t("tables.fields.response"), content: record.action_response })
                    : "",
        },
        {
            dataIndex: "updated_by",
            key: "updated_by",
            title: t("tables.fields.updated_by"),
            width: 100,
            render: (updated_by, record) =>
                updated_by && record.action
                    ? updated_by + "(" + record.action + ")"
                    : updated_by && !record.action
                    ? updated_by
                    : !updated_by && record.action
                    ? record.action
                    : "",
        },
    ].filter(item => !item.hidden));

    const showModal = (params) => {
        setModal(params);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const getAlert = (id) => {
        const apiUrl = API_TXALERT + `/alert/${id}/details`;
        axios
            .post(apiUrl, {}, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
            .then(({ data }) => {
                setData(data);
                try {
                    data.parent[0].outcome? setOutcome(data.parent[0].outcome.toLowerCase()):setOutcome('');
                 //   getReasons(data.parent[0].parent_providername);
                    setInabilitytoaction(data.parent[0].inabilitytoaction);
                } catch (error) {
                    console.log(error);
                }
                setLoading(false);
            })
            .catch((error) => error);
    };

    const getAlertCredits = () => {
        axios
            .post(
                API + "/alert_credits",
                { alertid: props.match.params.id },
                { headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), Authuser: localStorage.getItem("authuser") } }
            )
            .then((result) => {
                const data = result.data ? result.data : [];
                data.filter((e) => e.credit_status === "NEW").length ? disableRequestCredit(true) : disableRequestCredit(false);
                // setAddToBatchDisabledButtonStatus(result.data ? false : true);
            });
    };

   /* const getReasons = () => {
        const apiUrl = API_TXALERT + `/alertreasons`;
       
        axios
            .post(apiUrl, { isOnlyDisplayOnDashboard: true}, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
            .then(({ data }) => {
                if(data.length !== 0)
                {
                setReasons(ArrangeReasons(data));
                }
               
            })
            .catch((error) => {
                console.log("Alert Reasons", error);
            });
    };*/

    const IsJsonString = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    };

    const getButtonModal = (params, buttonText = "") => (
        <div className="ant-form--button-modal">
            <Button size="small" onClick={() => showModal(params)}>
                {buttonText ? buttonText : t("tables.actions.view")}
            </Button>
        </div>
    );
    const getOutcomeRender = (outcome) => {
        if (!outcome) {
            return <div className="ant-form--text">{outcome}</div>;
        }
        try {
            if (outcome[0].toUpperCase() !== outcome[0].toLowerCase()) {
                return <div className="ant-form--text">{t(`outcomes.${outcome.toLowerCase()}`)}</div>;
            } else {
                return <div className="ant-form--text">{outcome}</div>;
            }
        } catch (error) {
            return <div className="ant-form--text">{outcome}</div>;
        }
    };

    /*const checkIsAlertReasonsAllowCreditRequest = () => {
        if (
            !loading &&
            data &&
            data.details &&
            reasons &&
            Array.isArray(data.details) &&
            Array.isArray(reasons) &&
            data.details.length > 0 &&
            reasons.length > 0
        )   {
            let alertDetails = data.details;
            let alert = data.parent[0];
            if (alert.eligible_for_refunds === 0) {
                return false;
            }
            if (alertDetails && alertDetails.length > 0 && alertDetails[alertDetails.length - 1] && alertDetails[alertDetails.length - 1]["reason_code"]) {
                const current_reason_code = alertDetails[alertDetails.length - 1]["reason_code"];
                if (!current_reason_code) {
                    return false;
                }
                console.log(reasons);
                const alertReasons = reasons.filter(({ reason_code }) => reason_code === current_reason_code);
                if (alertReasons && alertReasons.length > 0) {
                    return !!alertReasons[0]["allow_credit_request"];
                }
            }
        }
        return false;
    }; */

    /*** if there is no credit already created and outcome is resolved,unactioned,declined and site eligible for refunds */
    const isAllowCreditRequest = () => {
        if (
            !loading &&
            data &&
            data.details &&
            Array.isArray(data.details) &&
            data.details.length > 0
        )   {
            let alertDetails = data.details;
            let alert = data.parent[0];
            if (alert.eligible_for_refunds === 0) {
                return false;
            }
            if(requestCreditDisabled === true)
            {
                return false;
            }
            if (alertDetails && alertDetails.length > 0 && alertDetails[alertDetails.length - 1] && alertDetails[alertDetails.length - 1]["reason_code"]) {
                const current_reason_code = alertDetails[alertDetails.length - 1]["reason_code"];
               //if unactioned the reason code will be empty. that can have credit
                if (!current_reason_code && alert.outcome !== 'unactioned') {
                    return false;
                }
               
            }
            const credit_eligible_outcomes = ["unactioned", "resolved", "declined"];
            if(credit_eligible_outcomes.includes(alert.outcome))
            {
                return true;
            }else
            {
                return false;
            }
        }
        return false;
    };
    const getPlainText = (text) => <div className="ant-form--text">{text}</div>;
    const getErrorText = (text) => <div className="ant-form--text" style={{ color: "#ff4d4f" }}>{text}</div>;
    const getPlainTextWithPre = (text) => <div className="ant-form--text" style={{whiteSpace: "pre-wrap"}}>{text}</div>;

    const getFormatDate = (date) => <div className="ant-form--text">{Date.parse(date) ? moment(date).format("lll") : ""}</div>;
    
    const getUpdateOutcome = (alert) => {
        // if(alert.providerclassfile === 'ethocacl' || alert.providerclassfile === 'cdrncl'){
            return <ChangeAlertStatusV2
                key="ChangeAlertStatusV2"
                t={t}
                socket={socket}
                outcome={outcome}
                doFetch={() => getAlert(props.match.params.id)}
                row={alert}                           
            />
        // } else{
        //     return <ChangeAlertStatus
        //     key="ChangeAlertStatus"
        //     t={t}
        //     socket={socket}
        //     outcome={outcome}
        //     doFetch={() => getAlert(props.match.params.id)}
        //     row={alert}                           
        //     />
        // }
    };

    const decodeHtml = (html) => new DOMParser().parseFromString(html, 'text/html').body.textContent;
    const getAlertDetailForm = () => {
        const alert = data.parent[0];
        const outcome = alert.outcome ? alert.outcome.toLowerCase() : alert.outcome;
        let fields = [
            {
                label: t("contents.alerts.alert_type"),
                name: "Alert Type",
                value: getPlainText(alert.alert_type),
            },
            {
                label: t("contents.alerts.sitename") + " (SID)",
                name: "sitename",
                value: getPlainText(decodeHtml(alert.sitename) + ` (${alert.sid})`),
            },
            {
                label: t("contents.alerts.provider_caseid"),
                name: "provider_caseid",
                value: getPlainText(alert.provider_caseid),
                tooltip: "provider case id",
            },
            {
                label: t("contents.alerts.transaction_date"),
                name: "transaction_date",
                value: getFormatDate(alert.transaction_date),
                tooltip: "transaction timestamp",
            },
            {
                label: t("contents.alerts.providername"),
                name: "providername",
                value: getPlainText(alert.providername),
                tooltip: "provider",
            },
            {
                label: t("contents.alerts.transaction_amount"),
                name: "transaction_amount",
                value: alert?.exceeds_ER_max_refund_amount && alert?.alert_type.toLowerCase() !== 'dispute_rdr' ?
                    getErrorText(FormatNumber(alert.transaction_amount) + " " + alert.transaction_currency + " Amount greater than express resolve max " ) :
                    getPlainText(FormatNumber(alert.transaction_amount) + " " + alert.transaction_currency),
                tooltip: "transaction amount",
            },
            {
                label: t("contents.alerts.alertdate"),
                name: "alertdate",
                value: getFormatDate(alert.alertdate),
            },
            {
                label: t("contents.alerts.cardnumber"),
                name: "cardnumber",
                value: getPlainText(alert.cardnumber),
                tooltip: "card",
            },
            {
                label: t("contents.alerts.issuername"),
                name: "issuername",
                value: getPlainText(alert.issuername),
            },
            {
                label: t("contents.alerts.descriptor"),
                name: "descriptor",
                value: getPlainText(alert.descriptor),
            },
            {
                label: t("contents.alerts.outcome"),
                name: "outcome",
                value: getOutcomeRender(alert.outcome),
                // tooltip: t("contents.alerts.tooltip.outcome"),
            }
        ];
        if (alert.alert_type.match("_RDR") && alert.outcome.toLowerCase() === 'declined') {
            fields = [
                ...fields,
                {
                    label: t("contents.alerts.reasoncode"),
                    name: "code",
                    value: getPlainText(alert.reasoncode),
                },
                {
                    label: t("contents.alerts.reason"),
                    name: "reason",
                    value: getPlainText(alert.reason),
                },
            ];
        }
        fields = [
            ...fields,
            {
                label: t("contents.alerts.merchant_transaction_id"),
                name: "merchant_request",
                value: getPlainText(alert.merchant_tx_id),
                tooltip: "alert transactionid",
            },
            {
                label: t("contents.alerts.outcome_updateon"),
                name: "outcome_updateon",
                value: getFormatDate(alert.outcome_updateon),
                // tooltip: t(`contents.alerts.tooltip.outcome_updateon`).replace('TxShield',WHITELABEL_DISPLAY_NAME),
              
            },
            {
                label: t("contents.alerts.arn"),
                name: "arn",
                value: getPlainText(alert.arn),
            },
            // {
            //     label: t("contents.alerts.merchant_updated_on"),
            //     name: "merchant_updated_on",
            //     value: getFormatDate(alert.merchant_updated_on),
            //     // tooltip: t("contents.alerts.tooltip.merchant_updated_on").replace('TxShield',WHITELABEL_DISPLAY_NAME),
            // },
            {
                label: t("contents.alerts.authcode"),
                name: "authcode",
                value: getPlainText(alert.authcode),
            },
            {
                label: t("contents.alerts.is_duplicate"),
                name: "is_duplicate",
                value: getPlainText(alert.is_duplicate ? t("contents.alerts.yes") : t("contents.alerts.no")),
            },
            {
                label: t("contents.alerts.source"),
                name: "source",
                value: getPlainText(alert.source),
            },
            {
                label: t("contents.alerts.dispute_amount"),
                name: "dispute_amount",
                value: 
                    getPlainText(FormatNumber(alert.dispute_amount) + " " + alert.dispute_currency),
                tooltip: "dispute amount",
            },            
            {
                label: t("contents.alerts.dispute_condition_code"),
                name: "Dispute condition code",
                value: getPlainText(alert?.condition_code ? alert.condition_code + "("+alert?.dispute_condition+", " + alert?.category + ")":alert.dispute_condition_code),
                tooltip: "dispute condition code",
            }
        ];
        if (alert.is_duplicate) {
            fields = [
                ...fields,
                {
                    label: t("contents.alerts.duplicate_alertid"),
                    name: "duplicate_alertid",
                    value: getPlainText(
                        <a href={`/alert/${alert.duplicate_alertid}`} target="_blank" rel="noopener noreferrer">
                            {alert.duplicate_alertid}
                        </a>
                    ),
                },
            ];
        }
        fields = [
            ...fields,
            {
                label: t("contents.alerts.chargeback_proceeded"),
                name: "chargeback_proceeded",
                value: getPlainText(alert.chargeback_proceeded ? t("contents.alerts.yes") : t("contents.alerts.no")),
            },  
        ];
                
        fields = [
            ...fields,
            {
                label: t("contents.alerts.receive_data"),
                name: "alertdata",
                value: getButtonModal({ title: t("contents.alerts.receive_data"), content: alert.alertdata }, t("tables.actions.view_alert_data")),
                offset: alert.is_duplicate ? null : 12,
                tooltip: t("contents.alerts.tooltip.receive_data"),
            },
        ];
        
        if (outcome !== "resolved" && data.posturl) {
            fields = [
                ...fields,
                {
                    label: t("contents.alerts.repost_alert"),
                    name: "repost_alert",
                    value: (
                        <div className="ant-form--button-modal">
                            <RepostAlert key="RepostAlert" t={t} row={alert} posturl={data.posturl} doFetch={() => getAlert(props.match.params.id)} />
                        </div>
                    ),
                },
            ];
        }

        if (isAllowCreditRequest()) {
            fields = [
                ...fields,
                {
                    label: t("contents.alerts.request_credit"),
                name: "request_credit",
                value:  (
                    <div className="ant-form--button-modal">
                        <RequestCredit disabled={requestCreditDisabled} t={t} row={alert} alertid={props.match.params.id} setIsRefresh={setIsRefresh} />
                    </div>
                )
                },
            ];
        }

        fields = [
            ...fields,
            {
                label: t("contents.alerts.mcc"),
                name: "MCC",
                value: getPlainText(alert.mcc),
            },
            {
                label: t("contents.alerts.tier"),
                name: "Tier",
                value: getPlainText(alert.tier),
            },
            {
                label: t("contents.alerts.notes"),
                name: "Notes",
                value: getPlainTextWithPre(alert.merchant_notes),
            },
            // Removed by Jericho as of 2023-08-22 since nobody is using it
            // {
            //     label: t("contents.alerts.add_to_batch"),
            //     name: "add_to_batch",
            //     value: (
            //         <div className="ant-form--button-modal">
            //             <AddToBatch addToBatchDisabledButtonStatus={addToBatchDisabledButtonStatus} t={t} alertid={props.match.params.id} />
            //         </div>
            //     ),
            // },
        ];

        return (
            <Card
                key="AertDetail"
                title={t("contents.alerts.alert_details")}
                style={{ marginBottom: "1rem" }}
                extra={[
                    //  outcome !== "resolved" ? (
                    !inabilitytoaction ? ( getUpdateOutcome(alert)) : null,
                    //  ) : null,
                ]}
            >
                <Form size="small" {...formItemLayout}>
                    <Row gutter={24}>
                        {fields.map((field) => (
                            <Col key={field.name} xs={24} md={12} offset={field.offset}>
                                <Form.Item className="ant-form--item" label={<label className="ant-form--label">{field.label}</label>} tooltip={field.tooltip}>
                                    {field.value}
                                </Form.Item>
                            </Col>
                        ))}
                    </Row>
                </Form>
            </Card>
        );
    };

    const getModalComponent = () => (
        <DraggableModal
            name={modal.title}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width={800}
            footer={[
                <Button key="submit" type="primary" onClick={handleOk}>
                    {t("tables.actions.ok")}
                </Button>,
            ]}
            context={
                <Typography>
                    <Paragraph className="well">
                        {IsJsonString(modal.content) ? JSON.stringify(JSON.parse(modal.content), null, "\t") : modal.content}
                    </Paragraph>
                </Typography>
            }
        />
    );

    const mergeColumns = columns.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => ({
            width: column.width,
            onResize: (_, { size }) => {
                const newColumns = [...columns];
                newColumns[index] = { ...newColumns[index], width: size.width };
                setColumns(newColumns);
            },
        }),
    }));

    return (
        <div>
            <PageHeader title={`${t("contents.alerts.alert_id")} ${props.match.params.id}`}></PageHeader>
            {!loading ? (
                <>
                    {getModalComponent()}
                    {getAlertDetailForm()}
                    <Tabs type="card" defaultActiveKey={outcome === "matched" ? "alertsForReview" : ""} >
                        <TabPane tab={t("contents.alerts.merchant_updates")} key="merchantUpdates">
                            <h2 style={{ marginBottom: "1.5rem" }}>{t("contents.alerts.merchant_updates")}</h2>
                            <Table
                                style={{ marginBottom: "1rem" }}
                                rowKey="alertdtid"
                                size="small"
                                components={{
                                    header: {
                                        cell: ColumnResizable,
                                    },
                                }}
                                bordered={false}
                                columns={mergeColumns}
                                dataSource={data.details ? data.details : []}
                                pagination={false}
                            />
                        </TabPane>
                        <TabPane tab={t("contents.alerts.alert_credits")} key="alertCredits">
                            <AlertCredits t={t} alertid={props.match.params.id} isRefresh={isRefresh} />
                            {/* Comment out Alert Credit Documents - https://app.clickup.com/t/86cvgt41h */}
                            {/* <AlertCreditDocuments t={t} alertid={props.match.params.id} /> */}
                        </TabPane>
                        {data.parent[0].er_enabled === 1 ?
                     ( data.parent[0].alert_type.match("_RDR")?
                            <TabPane tab={t("contents.possiblerdrtransactionmatches.title_matched")} key="alertsForReview">
                                <AlertsForReviewRDR t={t} socket={socket} alertid={props.match.params.id} isresolved={outcome === "resolved"} getAlert={getAlert} />
                            </TabPane>
                    :
                            <TabPane tab={t("contents.possibletransactionmatches.title_matched")} key="alertsForReview">
                            <AlertsForReview t={t} socket={socket} alertid={props.match.params.id} isresolved={outcome === "resolved"} />
                        </TabPane>
                    ) : null}
                    </Tabs>
                </>
            ) : null}
        </div>
    );
};

export default Alert;

import React, { useState, useEffect } from "react";
import DataFetchHookOI from "../../helper/DataFetchHookOI";
import { Table, PageHeader, Button, Card, Form, Select, Row, Col, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import CustomPaginationV2 from "../../helper/Table/CustomPaginationV2";
import SortOrder from "../../helper/Table/SortOrder";
import moment from "moment";
import CustomDateRangeV3 from "../../helper/Table/CustomDateRangeV3";

const DATA_URL = "/api/v1/txshield/ce_notifications/list";
const { Option } = Select;

const MAX_DATERANGE = 3;
const START_DATE = moment().subtract(MAX_DATERANGE, "months");
const END_DATE = moment();

const CELookup = (props) => {
    const { t } = props;
    const [{ data, totalResult, isLoading, requestData }, , setRequestData] = DataFetchHookOI(DATA_URL, "POST", null, {
        page: 1,
        size: 20,
        order: "DESC",
        dateFrom: START_DATE.format("YYYY-MM-DD HH:mm:ss"),
        dateTo: END_DATE.format("YYYY-MM-DD HH:mm:ss"),
    });    

    const [formData, setFormData] = useState({ hideColumn: [] });
    const [form] = Form.useForm();
    const [currentPage, setCurrentPage] = useState(1);
    const [sortedInfo, setSortedInfo] = useState({});
    const [dates, setDates] = useState([START_DATE, END_DATE]);
    const [isDateExceeded, setIsDateExceeded] = useState(false);

    const initFormData = {
        field: "caseId",
        keyword: null,
        order: "DESC",
        dateRange: [START_DATE, END_DATE]
    };

    useEffect(() => {
        formValInit();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (pagination, filters, sorter, { currentDataSource }) => {
        setSortedInfo(sorter);
    };

    const formValInit = () => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);
    };

    const formValChange = (chgV, allV) => {
        if (chgV.dateRange) {
            setDates(chgV.dateRange);
        }
        setFormData(allV);
    };

    const clearAll = () => {
        const newStartDate = moment().subtract(MAX_DATERANGE, "months");
        const newEndDate = moment();
        setDates([newStartDate, newEndDate]);
        setFormData({
            ...initFormData,
            dateRange: [newStartDate, newEndDate]
        });
        form.setFieldsValue({
            ...initFormData,
            dateRange: [newStartDate, newEndDate]
        });
        setRequestData({ 
            ...requestData, 
            dateFrom: newStartDate.startOf('day').toISOString(),
            dateTo: newEndDate.endOf('day').toISOString(),
            page: 1 
        });
    };

    const columns = [
        {
            dataIndex: "caseDate",
            key: "caseDate",
            title: "Case Date",
            width: 180,
            sorter: {
                compare: (a, b) => moment(a.caseDate).diff(moment(b.caseDate))
            },
            sortOrder: SortOrder(sortedInfo, "caseDate"),
            render: (caseDate) => moment(caseDate).format("YYYY-MM-DD HH:mm:ss")
        },
        {
            dataIndex: "caseId",
            key: "caseId",
            title: "Case ID",
            width: 200,
            sorter: {
                compare: (a, b) => a.caseId.localeCompare(b.caseId)
            },
            sortOrder: SortOrder(sortedInfo, "caseId"),
        },
        {
            dataIndex: "caseStatus",
            key: "caseStatus",
            title: "Status",
            width: 100,
        },
        {
            dataIndex: "arn",
            key: "arn",
            title: "ARN",
            width: 200,
        },
        {
            dataIndex: "cardBin",
            key: "cardBin",
            title: "Card BIN",
            width: 100,
        },
        {
            dataIndex: "cardLast4",
            key: "cardLast4",
            title: "Last 4",
            width: 80,
        },
        {
            dataIndex: "paymentDescriptor",
            key: "paymentDescriptor",
            title: "Payment Descriptor",
            width: 200,
        },
    ];

    const onFinish = () => {
        let startDate = null;
        let endDate = null;
        let searchData = {};
        
        if (formData) {
            if (dates) {
                startDate = dates[0] ? dates[0] : null;
                endDate = dates[1] ? dates[1] : null;
            }
            
            searchData = {
                keyword: formData.keyword ? formData.keyword.trim() : null,
                field: formData.field,
                dateFrom: startDate ? moment(startDate).format("YYYY-MM-DD HH:mm:ss") : null,
                dateTo: endDate ? moment(endDate).format("YYYY-MM-DD HH:mm:ss") : null,
            };

        }

        setCurrentPage(1);
        setRequestData({ ...requestData, ...searchData, page: 1 });

    };

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title="Search" size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12} md={6}>
                            <Form.Item 
                                name="dateRange"
                                initialValue={[START_DATE, END_DATE]}
                                rules={[
                                    {
                                        validator: () => {
                                            if (isDateExceeded) {
                                                return Promise.reject('Date range cannot exceed 3 months');
                                            } else if ((dates[0] && !dates[1]) || (!dates[0] && dates[1])) {
                                                return Promise.reject('Please select at least 1 day');
                                            } else {
                                                return Promise.resolve();
                                            }
                                        },
                                    },
                                ]}
                            >
                                <CustomDateRangeV3 
                                    t={t}
                                    setIsDateExceeded={setIsDateExceeded} 
                                    state={{ dates, setDates }} 
                                    unit="months" 
                                    limit={MAX_DATERANGE} 
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6}>
                            <Form.Item name="field">
                                <Select showSearch>
                                    <Option value="caseId">Case ID</Option>
                                    <Option value="arn">ARN</Option>
                                    <Option value="cardBin">Card BIN</Option>
                                    <Option value="cardLast4">Last 4</Option>
                                    <Option value="paymentDescriptor">Payment Descriptor</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6}>
                            <Form.Item name="keyword">
                                <Input placeholder="Enter search keyword" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6}>
                            <Form.Item>
                                <Button type="secondary" onClick={() => clearAll()} block>
                                    Clear All
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> Search
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">TxFind - CE Lookup</h3>
        </div>
    );

    return (
        <div>
            <PageHeader title="CE Lookup"></PageHeader>
            {AdvancedSearchForm()}
            <Table
                loading={isLoading}
                title={() => getHeaderTable()}
                columns={columns}
                rowKey="caseId"
                className="table-v3 custom-pagination"
                size="small"
                dataSource={data.datalist || []}
                onChange={handleChange}
                pagination={CustomPaginationV2({ 
                    totalResult, 
                    currentPage, 
                    requestData, 
                    setRequestData, 
                    setCurrentPage 
                })}
            />
        </div>
    );
};

export default CELookup;
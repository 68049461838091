import React, { useState, useEffect, useCallback } from "react";
import DataFetchHookV3 from "../../helper/DataFetchHookV3";
import { Table, PageHeader, Card, Form, Select, Row, Button, Tooltip, Col, Input, Divider, Space, Checkbox } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useLocation } from 'react-router-dom';

import CustomEmptyTextProps from "../../helper/Table/CustomEmptyTextProps";
import ExportModalV3 from "../../helper/ExportModalV3";
import AssociationsChecker from "../../helper/AssociationsChecker";

// import ChangeAlertStatus from "./ChangeAlertStatus";
import ChangeAlertStatusV2 from "./ChangeAlertStatusV2";
import axios from "axios";
import moment from "moment";
import { API_TXALERT } from "../../config/config";
import { API_MERCHANT, API_RETAILER, API_SITE, API_DATA_SOURCE } from "../../config/config";
import Icon from "@ant-design/icons";
import FormatAmount from "../../helper/FormatAmount";
//import CustomPaginationV3 from "../../helper/Table/CustomPaginationV3";
import CustomPaginationV2 from "../../helper/Table/CustomPaginationV2";
import CustomDateRangeV3 from "../../helper/Table/CustomDateRangeV3";
// import SortOrder from "../../helper/Table/SortOrder"; // commented due to unused var
import ColumnResizable from "../../helper/Table/ColumnResizable";
import GroupAccess from "./../../helper/GroupAccess";
import htmlParser from "html-react-parser";

const { Option } = Select;

const DATA_URL = API_TXALERT + "/dashboardalerts";
const DATA_URL_EXPORT = API_TXALERT + "/reports";
// const DATA_ALERT = "/alert";
// const DATA_ALERT_TYPES_URL = "/alerttypes";

const MERCHANT_DATA_URL = API_MERCHANT + "/list";
const RETAILER_DATA_URL = API_RETAILER + "/list";
const SITE_DATA_URL = API_SITE + "/list";
const RESELLER_DATA_URL = API_DATA_SOURCE + "/reseller";
const DATA_ALERT_PROVIDER_URL = API_TXALERT + "/provider";
const DATA_ALERT_TYPE_URL = API_TXALERT + "/type";

const STATUS_ACTION_REQUIRED = "Action Required";
const STATUS_UPDATE = "Update";
const STATUS_WIP = "WIP";

const MAX_DATERANGE = 3;
const DEFAULT_DATE_RANGE = 7;
const DEFAULT_DATE_PERIOD = "days";
let START_DATE = moment().subtract(DEFAULT_DATE_RANGE, DEFAULT_DATE_PERIOD);
let END_DATE = moment();

const AlertList = (props) => {
    const [keyFromDashboard, setKeyFromDashboard] = useState(useLocation().state);
    let actionFromDashboard = ["ACTION_REQUIRED", "WIP"];
    let expiry_datetime = null;
    if (keyFromDashboard !== undefined){
        window.history.replaceState({}, '')
        START_DATE = moment();
        if (keyFromDashboard === 'action_required'){
            actionFromDashboard = ["ACTION_REQUIRED"];
        }else if (keyFromDashboard === 'wip'){
            actionFromDashboard = ["WIP"];
        }else if (keyFromDashboard === 'expiry_datetime'){
            actionFromDashboard = [];
            expiry_datetime = moment();
            START_DATE = null;
            END_DATE = null;
        }
    }

    //eslint-disable-next-line
    const [{ data, totalResult, isLoading, requestData, isUpdated }, doFetch, setRequestData] = DataFetchHookV3(DATA_URL, "POST", {
        startDate: START_DATE,
        endDate: END_DATE,
        page: 1,
        size: 20,
        order: "ASC",
        action: actionFromDashboard,
        expiry_datetime: expiry_datetime,
        sort_field:"id",
    }); // eslint-disable-line
    
    // const [alerTypes, setAlertTypes] = useState([]);
    // const [sortedInfo, setSortedInfo] = useState({});  // commented due to unused var
    const [dates, setDates] = useState([START_DATE, END_DATE]);
    const [formData, setFormData] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [latestTotal, setLatestTotal] = useState(null); // eslint-disable-line
    const { t, theme, socket } = props;
    const [merchantList, setMerchantList] = useState([]);
    const [retailerList, setRetailerList] = useState([]);
    const [siteList, setSiteList] = useState([]);
    const [resellerList, setResellerList] = useState([]);
    const [inactiveChecked, setInactiveChecked] = useState(false);
    const [providerList, setProviderList] = useState([]);
    const [typeList, setTypeList] = useState([]);
    const [isDateExceeded, setIsDateExceeded] = useState(false);
    const [isSortUpdate, setIsSortUpdate] = useState(false);

    const initFormData = {
        dateRange: [START_DATE, END_DATE],
        action: [],
        outcome: [],
        keyword: "",
        field: null,
        order: "ASC",
        merchant: [],
        expiry_datetime: null,
        sort_field: "id",
    };

    const [form] = Form.useForm();

    const getResellerList = useCallback(() => {
        axios
            .post(
                RESELLER_DATA_URL,
                {},
                { headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), Authuser: localStorage.getItem("authuser") } }
            )
            .then(async ({ data }) => {
                await setResellerList(data);
            })
            .catch(() => false);
    }, []);

    const getMerchantList = useCallback(() => {
        axios
            .post(
                MERCHANT_DATA_URL,
                {getall:true},
                { headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), Authuser: localStorage.getItem("authuser") } }
            )
            .then(async ({ data }) => {
                await setMerchantList(data.list);
            })
            .catch(() => false);
    }, []);

    const getRetailerList = useCallback((mid_change) => {
        axios
            .post(
                RETAILER_DATA_URL,
                { mid: mid_change },
                { headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), Authuser: localStorage.getItem("authuser") } }
            )
            .then(async ({ data }) => {
                await setRetailerList(data.list);
            })
            .catch(() => false);
    }, []);

    const getSiteList = useCallback((mid_change, rid_change) => {
        axios
            .post(
                SITE_DATA_URL,
                { mid: mid_change, rid: rid_change },
                { headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), Authuser: localStorage.getItem("authuser") } }
            )
            .then(async ({ data }) => {
                await setSiteList(data.list);
            })
            .catch(() => false);
    }, []);

    const getProviderList = useCallback(() => {
        axios
            .post(
                DATA_ALERT_PROVIDER_URL,
                {},
                { headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), Authuser: localStorage.getItem("authuser") } }
            )
            .then(async ({ data }) => {
                await setProviderList(data);
            })
            .catch(() => false);
    }, []);

    const getTypeList = useCallback(() => {
        axios
            .post(
                DATA_ALERT_TYPE_URL,
                {},
                { headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), Authuser: localStorage.getItem("authuser") } }
            )
            .then(async ({ data }) => {
                await setTypeList(data);
            })
            .catch(() => false);
    }, []);

    useEffect(() => {
        formValInit();
        if (merchantList.length === 0) {           
            getMerchantList();
        }
        if (retailerList.length === 0) {
            getRetailerList();
        }
        if (siteList.length === 0) {
            getSiteList();
        }
        if (resellerList.length === 0) {
            getResellerList();
        }
        if (providerList.length === 0) {
            getProviderList()
        }
        if (typeList.length === 0) {
            getTypeList()
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        formValInit();
    }, [isSortUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

    // const handleChange = (pagination, filters, sorter, { currentDataSource }) => {
    //     console.log(sorter);
    //     setSortedInfo(sorter);
    // };

    const handleChange = (pagination, filters, sorter, { currentDataSource }) => {
        var orderBy = 'ASC';
        if(sorter.order === 'descend'){
            orderBy = 'DESC';
        }
        var sortField = 'id';
        if (sorter.field !== undefined){
            sortField = sorter.field;
        }
        var p = pagination.current;
        if (Object.keys(sorter).length >0 && requestData.sort_field !== sortField){
            p = 1;
            setCurrentPage(1);
        }

        setRequestData({ ...requestData, order: orderBy, sort_field: sortField, page: p, size: pagination.pageSize  });
        setIsSortUpdate(true);

    };



    const setExpirySort = () => {
        /* this.setState({
          sortedInfo: {
            order: 'descend',
            columnKey: 'expirydatetime',
          },
        });*/
        // setSortedInfo({
        //     order: "ascend",
        //     columnKey: "expirydatetime",
        // });
    };

    const formValInit = () => {
        setFormData({ ...initFormData, action: actionFromDashboard });
        form.setFieldsValue({ ...initFormData, action: actionFromDashboard });
        setExpirySort();
       
    };

    const clearAll = () => {
        window.history.replaceState({}, '')
        expiry_datetime = null;
        setKeyFromDashboard(undefined)

        setCurrentPage(1);
        setFormData(initFormData);
        setRequestData({ ...requestData, ...initFormData, startDate: null, endDate: null, page: 1 });
        form.setFieldsValue(initFormData);
    };

    const formValChange = (chgV, allV) => {
        if (["providername", "alert_type"].includes(chgV?.field)) {
            form.setFieldsValue({ keyword: undefined });
        }
        setFormData(allV);
    };


    let mid_select = null;
    let rid_select = null;
    const onChangeMid = (mid_change) => {
        mid_select = mid_change
        getRetailerList(mid_select);
        getSiteList(mid_select, rid_select);
    }

    const onChangeRid = (rid_change) => {
        rid_select = rid_change
        getSiteList(mid_select, rid_select);
    }

    const onFinish = () => {
        window.history.replaceState({}, '')
        expiry_datetime = null;
        setKeyFromDashboard(undefined);

        let startDate = null;
        let endDate = null;
        let searchData = {};
        if (formData) {
            if (dates) {
                startDate = dates[0] ? dates[0] : null;
                endDate = dates[1] ? dates[1] : null;
            }
            searchData = {
                field: formData.field,
                keyword: formData.keyword ? formData.keyword.trim() : null,
                action: formData.action,
                outcome: formData.outcome,
                order: formData.order ? formData.order : 'ASC',
                mid: formData.merchant,
                rid: formData.retailer,
                sid: formData.site,
            };
            if (GroupAccess("SYSADMIN")) {
                searchData = { ...searchData, reseller: formData.reseller }
            }
        }

        setCurrentPage(1);
        setRequestData({ ...requestData, ...searchData, startDate: startDate, endDate: endDate, page: 1 });
    };

    const customLocale = {
        emptyText: CustomEmptyTextProps(
            <>
                {t("tables.emptytext") + " "}
                <span style={{ cursor: "pointer", color: theme }} onClick={clearAll}>
                    {t("tables.actions.clear_filters")}
                </span>
            </>
        ),
    };

    const customLoading = {
        emptyText: CustomEmptyTextProps(
            <>
                <span>{t("tables.loading")}</span>
            </>,
            isLoading
        ),
    };

    const exportHeaders = [
        { label: "Alert Date", key: "DATE_FORMAT(alertdate, '%b %d, %Y %I:%i %p')"},
        { label: "Alert Reason", key:  "ptor.description_short_form"  },
        { label: "Alert Type", key: "a.alert_type" },
        { label: "Amount", key: "transaction_amount" },
        { label: "ARN", key: "arn" },
        { label: "Auth Code", key: "authcode" },
        { label: "Belongs to Merchant", key: "belongtomerchant" },
        { label: "Card No.", key: "cardnumber" },
        { label: "Chargeback Occurred", key: "chargeback_proceeded" },
        { label: "Condition Code", key: "condition_code_text_category" },
        { label: "Currency", key: "transaction_currency" },
        { label: "Descriptor", key: "a.descriptor" },
        { label: "Duplicate", key: "is_duplicate" },
        { label: "ID", key: "a.id" },
        { label: "Issuername", key: "issuername" },
        { label: "Dispute Amount", key: "dispute_amount" },
        { label: "Dispute Currency", key: "dispute_currency" },
        { label: "Matched Descriptor", key: "ad.descriptor" },
        { label: "MCC", key: "mcct.mcc" },
        { label: "Merchant", key: "m.name" },
        { label: "Merchant Update On", key: "DATE_FORMAT(merchant_updated_on, '%b %d, %Y %I:%i %p')" },
        { label: "Outcome", key: "a.outcome" },
        { label: "Outcome Update On", key: "DATE_FORMAT(outcome_updateon, '%b %d, %Y %I:%i %p')" },
        { label: "Partner ID", key: "a.merchantname" },
        { label: "Provider", key: "providername" },
        { label: "Provider Reference", key: "provider_caseid" },
        { label: "Reason Code", key: "a.reasoncode" },
        { label: "Retailer Name", key: "r.name" },
        { label: "Site Name", key: "rs.name" },
        { label: "Source", key: "source" },
        { label: "Tier", key: "mcct.tier" },
        { label: "Transaction Date", key: "DATE_FORMAT(transaction_date, '%b %d, %Y %I:%i %p')" },
        { label: "Updated By", key: "u.username" },
        { label: "Notes", key: "a.merchant_notes" },
    ];

    const exportHeadersMinimal = [
        { label: "Alert Date", key: "DATE_FORMAT(alertdate, '%b %d, %Y %I:%i %p')" },
        { label: "Alert Reason", key: "ptor.description_short_form" },
        { label: "Amount", key: "transaction_amount" },
        { label: "ARN", key: "arn" },
        { label: "Auth Code", key: "authcode" },
        { label: "Card No.", key: "cardnumber" },
        { label: "Condition Code", key: "condition_code_text_category" },
        { label: "Currency", key: "transaction_currency" },
        { label: "Descriptor", key: "a.descriptor" },
        { label: "ID", key: "a.id" },
        { label: "Issuername", key: "issuername" },
        { label: "Dispute Amount", key: "dispute_amount" },
        { label: "Dispute Currency", key: "dispute_currency" },
        { label: "Matched Descriptor", key: "ad.descriptor" },
        { label: "MCC", key: "mcct.mcc" },
        { label: "Merchant Update On", key: "DATE_FORMAT(merchant_updated_on, '%b %d, %Y %I:%i %p')" },
        { label: "Outcome", key: "a.outcome" },
        { label: "Outcome Update On", key: "DATE_FORMAT(outcome_updateon, '%b %d, %Y %I:%i %p')"},
        { label: "Provider", key: "providername" },
        { label: "Provider Reference", key: "provider_caseid" },
        { label: "Reason Code", key: "a.reasoncode" },
        { label: "Retailer Name", key: "r.name" },
        { label: "Site Name", key: "rs.name" },
        { label: "Source", key: "source" },
        { label: "Tier", key: "mcct.tier" },
        { label: "Transaction Date", key: "DATE_FORMAT(transaction_date, '%b %d, %Y %I:%i %p')" },
        { label: "Type", key: "a.alert_type" },
        { label: "Updated By", key: "u.username" },
        { label: "Notes", key: "a.merchant_notes" },
    ];

    const exportHeadersOptional = [
        "providername",
        "provider_caseid",
        "alert_type",
        "a.merchantname",
        "m.name",
        "r.name",
        "rs.name",
        "issuername",
        "source",
        "ad.descriptor",
        "DATE_FORMAT(outcome_updateon, '%b %d, %Y %I:%i %p')",
        "DATE_FORMAT(merchant_updated_on, '%b %d, %Y %I:%i %p')",
        "belongtomerchant",
        "chargeback_proceeded",
        "is_duplicate",
        "u.username",
        "reasoncode",
        "ar.reason_description",
        "mcct.mcc",
        "mcct.tier",
        "condition_code_text_category",
        'a.merchant_notes'
    ];

    const CautionSvg = () => (
        <svg height="1rem" viewBox="0 0 128 128" width="1rem" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path
                    d="m57.362 26.54-37.262 64.535a7.666 7.666 0 0 0 6.639 11.5h74.518a7.666 7.666 0 0 0 6.639-11.5l-37.258-64.535a7.665 7.665 0 0 0 -13.276 0z"
                    fill="#ffb400"
                />
                <g fill="#fcf4d9">
                    <rect height="29.377" rx="4.333" width="9.638" x="59.181" y="46.444" />
                    <circle cx="64" cy="87.428" r="4.819" />
                </g>
            </g>
        </svg>
    );

    const CautionIcon = (props) => <Icon component={CautionSvg} {...props} />;

    const getUpdateOutcome = (alert, outcome) => {
        // if(alert.providerclassfile === 'ethocacl' || alert.providerclassfile === 'cdrncl'){
            return <ChangeAlertStatusV2
                key="ChangeAlertStatusV2"
                t={t}
                socket={socket}
                outcome={outcome}
                doFetch={() => doFetch(DATA_URL + `?` + moment().unix())}
                row={alert}                           
            />
        // } else{
        //     return <ChangeAlertStatus
        //     key="ChangeAlertStatus"
        //     t={t}
        //     socket={socket}
        //     outcome={outcome}
        //     doFetch={() => doFetch(DATA_URL + `?` + moment().unix())}
        //     row={alert}                           
        //     />
        // }
    };

    const [columns, setColumns] = useState([
        {
            dataIndex: "id",
            key: "id",
            title: t("tables.fields.alertid"),
            fixed: "left",
            width: 95,
            onCell: (record) => ({ className: getRowClass(record) }),
            render: (id, record) => (
                <a href={`/alert/${id}`} target="_blank" rel="noopener noreferrer">
                    {record.outcome && record.outcome.toLowerCase() === 'matched' ? (
                        <Tooltip placement="topLeft" title={t("contents.alerts.tooltip.potential_transaction_matches")}>
                            {id} <CautionIcon style={{ fontSize: "1rem" }} />
                        </Tooltip>
                    ) : (
                        id
                    )}
                </a>
            ),
            sorter: {
                compare: (a, b) => a.id - b.id,
                //   multiple: 1,
            },
            // sortOrder: SortOrder(sortedInfo, "id"),
        },
        {
            dataIndex: "alertdate",
            key: "alertdate",
            title: t("tables.fields.alertdate"),
            width: 170,
            render: (alertdate) => moment(alertdate).format("lll"),
            sorter: {
                compare: (a, b) => moment(a.alertdate).diff(moment(b.alertdate)),
                //  multiple: 2,
            },
            // sortOrder: SortOrder(sortedInfo, "alertdate"),
        },
        {
            dataIndex: "descriptor",
            key: "descriptor",
            title: t("tables.fields.descriptor"),
            width: 150,
            ellipsis: true,
            sorter: {
                compare: (a, b) => a.descriptor.localeCompare(b.descriptor),
                //    multiple: 3,
            },
            // sortOrder: SortOrder(sortedInfo, "descriptor"),
        },
        {
            dataIndex: "providername",
            key: "providername",
            title: t("tables.fields.providername"),
            width: 130,
            ellipsis: true,
            sorter: {
                compare: (a, b) => a.providername.localeCompare(b.providername),
                //    multiple: 8,
            },
            // sortOrder: SortOrder(sortedInfo, "providername"),
        },
        {
            dataIndex: "transaction_date",
            key: "transaction_date",
            title: t("tables.fields.transaction_date"),
            ellipsis: true,
            width: 170,
            render: (transaction_date) => moment(transaction_date).format("lll"),
            sorter: {
                compare: (a, b) => moment(a.transaction_date).diff(moment(b.transaction_date)),
                //   multiple: 4,
            },
            // sortOrder: SortOrder(sortedInfo, "transaction_date"),
        },
        {
            dataIndex: "transaction_amount",
            key: "transaction_amount",
            title: t("tables.fields.amount"),
            align: "left",
            width: 80,
            ellipsis: true,
            render: (transaction_amount, record) => FormatAmount(transaction_amount, record.transaction_currency),
            sorter: {
                compare: (a, b) => a.transaction_amount - b.transaction_amount,
                //  multiple: 5,
            },
            // sortOrder: SortOrder(sortedInfo, "transaction_amount"),
        },
        {
            dataIndex: "cardnumber",
            key: "cardnumber",
            title: t("tables.fields.card_no"),
            ellipsis: true,
            width: 120,
            sorter: {
                compare: (a, b) => a.cardnumber.localeCompare(b.cardnumber),
                //    multiple: 6,
            },
            // sortOrder: SortOrder(sortedInfo, "cardnumber"),
        },
        {
            dataIndex: "alert_type",
            key: "alert_type",
            title: t("tables.fields.alert_type"),
            ellipsis: true,
            width: 130,
            sorter: {
                compare: (a, b) => a.alert_type.localeCompare(b.alert_type),
                //    multiple: 8,
            },
            // sortOrder: SortOrder(sortedInfo, "alert_type"),
        },
        {
            dataIndex: "dispute_amount",
            key: "dispute_amount",
            title: t("tables.fields.dispute_amount"),
            align: "left",
            width: 80,
            ellipsis: true,
            render: (dispute_amount, record) => FormatAmount(dispute_amount, record.dispute_currency),
            sorter: {
                compare: (a, b) => a.dispute_amount - b.dispute_amount,
            },
            
        },
        {
            dataIndex: "expirydatetime",
            key: "expirydatetime",
            title: t("tables.fields.expiry_date"),
            align: "left",
            fixed: "right",
            width: 120,
            render: (expirydatetime, record) => (
                record.alert_type === "DISPUTE_RDR" ? <span>N/A</span> :
                    <span>
                        {moment(expirydatetime).format("lll")}
                    </span>
            ),
            sorter: {
                compare: (a, b) => moment(a.expirydatetime).diff(moment(b.expirydatetime)),
                //   multiple: 4,
            },
            // sortOrder: SortOrder(sortedInfo, "expirydatetime"),
        },
        {
            dataIndex: "outcome",
            key: "outcome",
            title: t("tables.fields.outcome"),
            ellipsis: true,
            align: "left",
            fixed: "right",
            width: 120,
            render: (outcome, record) => (outcome ? getOutcomeRender(outcome) : getAction(record.outcome, record.is_duplicate, record)),
            sorter: {
                compare: (a, b) => (a.outcome ? a.outcome.localeCompare(b.outcome || "") : String("").localeCompare(b.outcome || "")),
                //    multiple: 7,
            },
            // sortOrder: SortOrder(sortedInfo, "outcome"),
        }
    ]);

    const getStatusColor = (status) => {
        switch (status.toLowerCase()) {
            case "resolved":
                return "#52c41a";
            case "processing":
                return "#2f54eb";
            case "other":
                return "#fa8c16";
            case "declined":
                return "#f5222d";
            case "error":
                return "#f5222d";
            default:
                return "default";
        }
    };

    const getOutcomeRender = (outcome) => {
        try {
            if (outcome === "wip") {
                return <span>WIP</span>;
            }
            if (outcome[0].toUpperCase() !== outcome[0].toLowerCase()) {
                return (
                    <span style={{ color: getStatusColor(outcome) }} className="text-capital">
                        {t(`outcomes.${outcome.toLowerCase()}`)}
                    </span>
                );
            } else {
                return <span>{outcome}</span>;
            }
        } catch (error) {
            return <span>{outcome}</span>;
        }
    };

    const getAction = (outcome, is_duplicate, record) => {
        
        if (outcome && outcome.toLowerCase() === "resolved") {
            return null;
        }

        // if (record?.expired === true) {
        //     return null;
        // } 

        if (record?.inabilitytoaction) {
            return null;
        } 

        if (is_duplicate && is_duplicate.toLowerCase() !== "no" && outcome === "") {
            return <Button size="small" onClick={() => window.open(`/alert/${is_duplicate}`, "_blank")}>{`${t("outcomes.duplicate")} ${is_duplicate}`}</Button>;
        }
        
       
        
        // else if (outcome && outcome.toLowerCase() === "matched") {
        //     return null;
        // }
        return ( getUpdateOutcome(record, outcome)
            // <ChangeAlertStatus
            //     t={t}
            //     socket={socket}
            //     outcome={outcome}
            //     doFetch={() => doFetch(DATA_URL + `?` + moment().unix())}
            //     row={record}
            // />
        );
    };

    const getLocale = () => {
        if (isLoading) {
            return customLoading;
        } else if (data.datalist) {
            return customLocale;
        } else {
            return {};
        }
    };

 
    useEffect(() => {
        if (!isLoading) {
       
            //comment out by Trang - Jun 13, 2024 - https://app.clickup.com/t/86cvhdp4k
            // let temp = columns;
            //         let act = [{
            //             dataIndex: "action",
            //             key: "action",
            //             title: t("tables.fields.action"),
            //             align: "left",
            //             fixed: "right",
            //             width: 120,
            //             render: (text, record) => getAction(record.outcome, record.is_duplicate, record),
            //         },];
            //         temp = [...temp, ...act];
            //         setColumns(temp);
            
        /*    axios
                .post(
                    DATA_ALERT_REASONS_URL,
                    { isOnlyDisplayOnDashboard: true },
                    { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } }
                )
                .then(({ data }) => {
                    setReasons(ArrangeReasons(data));
                    let temp = columns;
                    let act = [{
                        dataIndex: "action",
                        key: "action",
                        title: t("tables.fields.action"),
                        align: "left",
                        fixed: "right",
                        width: 120,
                        render: (text, record) => getAction(record.outcome, record.is_duplicate, record, data),
                    },];
                    temp = [...temp, ...act];
                    setColumns(temp);

                })
                .catch((error) => {
                    console.log("Alert Reasons", error);
                });
*/
            
        }
    }, []); // eslint-disable-line


    const onChangeInactive = (e) => {
        setInactiveChecked(e.target.checked);
    };

    const getMerchant = () => {
        if (inactiveChecked) {
            return merchantList.map((item) => (
                item.status === 'active' ?
                    <Option key={item.mid} value={item.mid}>
                        {htmlParser(item.name) + ` (mid: ` + item.mid + `)`}
                    </Option>
                    : <Option key={item.mid} value={item.mid} style={{ color: "#f5222d" }}>
                        {htmlParser(item.name) + ` (mid: ` + item.mid + `)`}
                    </Option>
            ))
        } else {
            return merchantList.map((item) => (
                item.status === 'active'
                    ?
                    <Option key={item.mid} value={item.mid}>
                        {htmlParser(item.name) + ` (mid: ` + item.mid + `)`}
                    </Option>
                    :
                    null
            ))
        }
    };

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="dateRange"
                                rules={[
                                    {
                                        validator: () => {
                                            if(isDateExceeded){
                                                return Promise.reject(t('max_3_months'));
                                            }else if((dates[0] && !dates[1]) || (!dates[0] && dates[1])){
                                                return Promise.reject(t('min_1_day'));
                                            }else{
                                                return Promise.resolve();
                                            }
                                        }
                                    }
                                ]}
                            >
                                <CustomDateRangeV3 t={t} setIsDateExceeded={setIsDateExceeded} state={{ dates, setDates }} unit="months" limit={MAX_DATERANGE} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="action">
                                <Select mode="multiple" showArrow="true" maxTagCount="responsive" allowClear style={{ width: "100%" }} placeholder="Action">
                                    <Option value="ACTION_REQUIRED">{STATUS_ACTION_REQUIRED}</Option>
                                    <Option value="UPDATE">{STATUS_UPDATE}</Option>
                                    <Option value="WIP">{STATUS_WIP}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="outcome">
                                <Select mode="multiple" showArrow="true" maxTagCount="responsive" allowClear style={{ width: "100%" }} placeholder={t("tables.fields.outcome")}>
                                    <Option value="wip">WIP</Option>
                                    <Option value="resolved">Resolved</Option>
                                    <Option value="declined">Declined</Option>
                                    <Option value="error">Error</Option>
                                    <Option value="other">Other</Option>
                                    <Option value="matched">Matched</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="merchant">
                                <Select
                                    showSearch
                                    mode="multiple"
                                    showArrow="true"
                                    maxTagCount="responsive"
                                    style={{ width: "100%" }}
                                    optionFilterProp="children"
                                    onChange={onChangeMid}
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                    placeholder={t("contents.merchants.title")}
                                    dropdownRender={(menu) => GroupAccess("SYSADMIN") ? (
                                        <>
                                            {menu}

                                            <Divider
                                                style={{
                                                    margin: '8px 0',
                                                }}
                                            />
                                            <Space
                                                style={{
                                                    padding: '0 8px 4px',
                                                }}
                                            >
                                                <Checkbox onChange={onChangeInactive}>Show Inactive?</Checkbox>
                                            </Space>
                                        </>
                                    ) : (
                                        <>
                                            {menu}
                                        </>
                                    )}
                                >
                                    {merchantList
                                        ? getMerchant()
                                        : null}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="retailer">
                                <Select
                                    showSearch
                                    mode="multiple"
                                    showArrow="true"
                                    maxTagCount="responsive"
                                    style={{ width: "100%" }}
                                    optionFilterProp="children"
                                    onChange={onChangeRid}
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                    placeholder={t("contents.retailers.title")}>
                                    {retailerList
                                        ? retailerList.map((item) => (
                                            <Option key={item.rid} value={item.rid}>
                                                {htmlParser(item.name) + ` (rid: ` + item.rid + `)`}
                                            </Option>
                                        ))
                                        : null}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="site">
                                <Select
                                    showSearch
                                    mode="multiple"
                                    maxTagCount="responsive"
                                    showArrow="true"
                                    style={{ width: "100%" }}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                    placeholder={t("contents.sites.title")}>
                                    {siteList
                                        ? siteList.map((item) => (
                                            <Option key={item.sid} value={item.sid}>
                                                {htmlParser(item.name) + ` (sid: ` + item.sid + `)`}
                                            </Option>
                                        ))
                                        : null}
                                </Select>
                            </Form.Item>
                        </Col>
                        {GroupAccess("SYSADMIN") ? (
                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item name="reseller">
                                    <Select
                                        showSearch
                                        mode="multiple"
                                        showArrow="true"
                                        maxTagCount="responsive"
                                        style={{ width: "100%" }}
                                        optionFilterProp="children"
                                        placeholder={t("contents.resellers.title")}
                                    >
                                        {resellerList
                                            ? resellerList.map((item) => (
                                                <Option key={item.reseller_id} value={item.reseller_id}>
                                                    {item.reseller_id + ` (reseller: ` + item.name + `)`}
                                                </Option>
                                            ))
                                            : null}
                                    </Select>
                                </Form.Item>
                            </Col>
                        ) : (
                            ""
                        )}
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="field">
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    style={{ width: "100%" }}
                                    placeholder={t("tables.fields.field")}>
                                    <Option value="alert_id">{t("contents.alerts.alert_id")}</Option>
                                    <Option value="descriptor">{t("tables.fields.descriptor")}</Option>
                                    <Option value="transaction_amount">{t("tables.fields.amount")}</Option>
                                    <Option value="card_number">{t("tables.fields.card_no")}</Option>
                                    <Option value="alert_type">{t("tables.fields.alert_type")}</Option>
                                    <Option value="arn">{t("tables.fields.arn")}</Option>
                                    <Option value="providername">{t("tables.fields.providername")}</Option>
                                    <Option value="provider_caseid">{t("tables.fields.provider_caseid")}</Option>
                                    <Option value="reason">{t("tables.fields.reason")}</Option>
                                    <Option value="auth_code">{t("tables.fields.auth_code")}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            {form.getFieldValue("field") === "providername" ? (
                                <Form.Item name="keyword">
                                    <Select
                                        showSearch
                                        options={providerList.map((item) => ({ label: item?.dataIndex, value: item?.dataIndex }))}
                                        filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                                        placeholder={t("tables.fields.keyword")}
                                    />
                                </Form.Item>
                            ) : form.getFieldValue("field") === "alert_type" ? (
                                <Form.Item name="keyword">
                                    <Select
                                        showSearch
                                        options={typeList.map((item) => ({ label: item?.alert_type, value: item?.alert_type }))}
                                        filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                                        placeholder={t("tables.fields.keyword")}
                                    />
                                </Form.Item>
                            ) : (
                                <Form.Item name="keyword">
                                    <Input placeholder={t("tables.fields.keyword")} />
                                </Form.Item>
                            )}
                        </Col>
                        <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 12 }}
                            md={{ span: 6, offset: 12 }}
                            lg={{ span: 6, offset: 12 }}
                            xl={{ span: 3, offset: 17 }}
                        >
                            <Form.Item>
                                <Button type="secondary" onClick={() => clearAll()} block>
                                    {t("tables.actions.clear_all")}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> {t("tables.actions.search")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.alerts.title")}</h3>
            <ExportModalV3
                t={t}
                export_header={true}
                keyname="export_all"
                title={t("tables.actions.export")}
                export_option={true}
                data={requestData}
                data_url={DATA_URL_EXPORT}
                headers={AssociationsChecker("Merchant|Retailer") ? exportHeadersMinimal : exportHeaders}
                fieldHeadersOptional={exportHeadersOptional}
                filename={`alert_${moment().format("YYMMDD_HHmmss_SSSS")}.csv`}
                style={{ marginLeft: 8 }}
            />
        </div>
    );
    const getRowClass = (record) => {
        const utctime = moment().utc().format('YYYY-MM-DD HH:mm:ss');
        const diff = moment(utctime).diff(moment(record.expirydatetime), "hours");
        //if system time is > expiry show in red
        if (diff > 0 && (record.outcome === null || record.outcome === "" || record.outcome.toLowerCase() === "matched" || record.outcome.toLowerCase() === "wip")) {
            return "expiringalert";
        }
    };

    const mergeColumns = columns.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => ({
            width: column.width,
            onResize: (_, { size }) => {
                const newColumns = [...columns];
                newColumns[index] = { ...newColumns[index], width: size.width };
                setColumns(newColumns);
            },
        }),
    }));

    return (
        <>
            <PageHeader title={ keyFromDashboard === 'expiry_datetime' ? t("contents.alerts.title_expiry") : t("contents.alerts.title")}></PageHeader>
            {AdvancedSearchForm()}
            <Table
                size="small"
                locale={getLocale()}
                loading={isLoading}
                title={() => getHeaderTable()}
                rowKey="id"
                className="table-v3 custom-pagination custom-pagination-v3"
                components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }}
                columns={mergeColumns}
                scroll={{ x: 1500 }}
                dataSource={getDataSource(data.datalist)}
                onChange={handleChange}
                sortDirections={["ascend", "descend"]}
                rowClassName={(record) => getRowClass(record)}
                // pagination={CustomPaginationV3(
                //     { totalResult, currentPage, requestData, setRequestData, setCurrentPage, latestTotal, setLatestTotal },
                //     isUpdated
                // )}
                pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}
            />
        </>
    );
};

export default AlertList;

import { API } from "../config/config";
import axios from "axios";

const FetchUserAssociations = async (accessToken, uid) => {
    return new Promise((resolve, reject) => {
        if (Number.isNaN(uid)) { // check if uid is not numeric
            reject(new Error('Invalid Uid'));
        }

        return axios.post(API + '/userassociations', { uid: uid }, { withCredentials: true, headers: { Authorization: "Bearer " + accessToken } }).then((res) => {
            if (res.data) {
                resolve({ associations: res.data.associations });
            }
            reject(new Error('Invalid Result'));
        }).catch((err) => {
            reject(new Error('Invalid Result'));
        })
    })
}

export default FetchUserAssociations;
import React from "react";
import { Menu } from "antd";
import {
    PieChartOutlined,
    ShopOutlined,
    ClusterOutlined,
    PartitionOutlined,
    RetweetOutlined,
   // SecurityScanOutlined,
    AlertOutlined,
    MessageOutlined,
    CheckCircleOutlined,
    RollbackOutlined,
    DollarCircleOutlined,
    UnorderedListOutlined,
    ReconciliationOutlined,SettingOutlined,ContainerOutlined,BarsOutlined,FileAddOutlined
    // LinkOutlined,
} from "@ant-design/icons";
import GroupAccess from "./../../helper/GroupAccess";
import MenuAccess from "./../../helper/MenuAccess";
import AssociationsChecker from "./../../helper/AssociationsChecker";
import { THEME, DISABLE_OI, DISABLE_CBM, DISABLE_ALERTS, DISABLE_3DS, DISABLE_PAYMENTS, DISABLE_TXALERT2, DISPLAY_PAYMENT_LINK, DISABLE_THREEDS_MICROSERVICE, DEV_MODE } from "../../config/config";
import Pdf from '../../PreDisputeAlertHandlingAndDashboardGuideJune2024.pdf';
import UserMessages from "../../helper/UserMessages";
const Sidebar = (props) => {

    if(DEV_MODE) {
        UserMessages(); // show message notifications
    }

    const { t, theme, pathname } = props;

    const getDefaultSelectedKeys = () => {
        if (pathname === '/') {
            return "dashboard";
        }
        const keys = pathname.substring(1).split("/");
    
        if (keys.length === 1) {
            return keys[0];
        }

        switch (keys[0]) {
            case "merchant":
                return "merchants";
            case "alert":
                return "alerts";
            case "alertdescriptor":
                return "alertdescriptors";
            case "descriptorvariation":
                return "descriptorvariations";
            case "txfind_cart":
                return "txfind_carts";
            case "txfind_descriptor":
                return "txfind_descriptors";
            case "txalertmenu":
                return "txalertmenu";
            case "txfind_bincaids":
                return "txfind_bincaids";
            case "txfind":
                return keys[1] || "txfind"; // This will handle sub-routes of txfind
            default:
                return keys[0];
        }
    };

    const getDefaultOpenKeys = () => {
        const keyname = pathname.substring(1).split("/")[0];
//console.log(keyname);
        if (
            keyname === "transactions" ||
            keyname === "refunds" ||
            keyname === "virtualterminal" ||
            keyname === "virtualterminal-cliq-cc" ||
            keyname === "virtualterminal-cliq-ach" ||
            keyname === "balance" 
        ) {
            return ['tx'];
        }
        if (
            keyname === "3dsecure" || 
            keyname === "3dsstats" || 
            keyname === "3dsecure_overview" ||
            keyname === "3dsecure_merchant" ||
            keyname === "3dsbinmid"
        ) {
            return ['3ds_microservice'];
        }
        if(
            keyname === "3ds_transaction"
        ){
            return ['3ds'];
        }
        if (
            keyname === "alerts" || 
            keyname === "alert" || 
            keyname === "bincaiddescriptors" ||
            keyname === "alertstats" ||
            keyname === "rdractivity"||
            keyname === "descriptorenrollmentform"
        ) {
            return ['alertmenu'];
        }
        if(
            keyname === 'alertdescriptors' ||
            keyname === 'alertdescriptor'  ||
            keyname === "descriptorvariations"
        ) {
            return ['alertmenu','alertdescriptorsparent'];
        }
        if (
            keyname === "chargeback_merchants" ||
            keyname === "chargeback_providers" ||
            keyname === "chargeback_merchant_providers" ||
            keyname === "chargebacks" ||
            keyname === "chargeback_alertproviders" ||
            keyname === "chargeback_alertprovider_logins"
        ) {
            return ['cbm'];
        }
        if (
            keyname === "txfind_merchants" ||
            keyname === "txfind_descriptors" ||
            keyname === "txfind_bincaids" ||
            keyname === "txfind_carts" ||
            keyname === "txfind_carts_lookup" ||
            keyname === "txfind" || 
            keyname === "txfind_ce_lookup"
        ) {
            return ['txfind'];
        }
        if (
            keyname === "alertlist" || 
            keyname === "alertdetail" || 
            keyname === "descriptorlist" || 
            keyname === "descriptor" || 
            keyname === "bincaidlist" ||
            keyname === "bincaid" ||
            keyname === "merchantlist" ||
            keyname === "merchantdetails"
           
        ) {
            return ['txalertmenu'];
        }
        if( 
            keyname === "enrollmentstatuslist" ||
            keyname === "disputeconditioncodelist" ||
            keyname === "alerttypelist" ||
            keyname === "creditreasonlist" ||
            keyname === "creditstatuslist" ||
            keyname === "mcctierlist"  ||
            keyname === "alertproviderlist" ||
            keyname === "alertproviderdeatil" ||
            keyname === "alertproviderlogins" ||
            keyname === "erproviderlist" ||
            keyname === "erproviderlogins" ||
            keyname === "alertresponses" ||
            keyname === "acquiringbanklist" ||
            keyname === "acquiringbankaccountlist" || 
            keyname === "ertransactionmatches" ||
            keyname === "ertransactionmatch" ||
            keyname === "creditrequests" ||
            keyname === "themelist" ||
            keyname === "emailtemplatelist" ||
            keyname === "generatealert" ||
            keyname === "issuerbinlist"
            ){
                return ['txalertmenu','txalert_admin'];
        }
        if( 
            keyname === "posttoalertproviderlogs" ||
            keyname === "posttomerchantlogs" ||
            keyname === "posttomerchantretrylogs" ||
            keyname === "posttoalertproviderretrylogs" ||
            keyname === "emailsendlogs" ||
            keyname === "backgroundprocesslogs"
            ){
                return ['txalertmenu','txalert_logs'];
        }
    };

    const ItemAccess = (condition) => ({ className: !condition ? "d-none" : "" });

    const ItemLabel = (href, text) => ({
        label: (
            <a className="nav-link" href={href}>
                <span>{text}</span>
            </a>
        ),
    });

    const UserGuideLabel = () => ({
        label: (
            <a className="nav-link" href = {Pdf} target = "_blank" rel="noopener noreferrer">
                <span>{t("sidebar.userguide")}</span>
            </a>
        ),
    });

    let menuItems = [
        {
            key: "dashboard",
            icon: <PieChartOutlined />,
            ...ItemLabel("/dashboard", t("sidebar.dashboard")),
        },
        {
            key: "merchants",
            icon: <ShopOutlined />,
            ...ItemLabel("/merchants", t("sidebar.merchants")),
            ...ItemAccess(GroupAccess("SYSADMIN|RESELLER|MERCHANT") && MenuAccess('merchants')),
        },
        {
            key: "tx",
            icon: <RetweetOutlined />,
            label: t("sidebar.payments"),
            children: [
                {
                    key: "transactions",
                    ...ItemLabel("/transactions", t("sidebar.transactions")),
                },
                {
                    key: "virtualterminal",
                    ...ItemLabel("/virtualterminal", t("sidebar.virtual_terminal")),
                    ...ItemAccess(THEME.toLowerCase() !== "cliq" && MenuAccess('virtualterminal')),
                },
                {
                    key: "virtualterminal-cliq-cc",
                    ...ItemLabel("/virtualterminal-cliq-cc", t("sidebar.virtualterminal-cliq-cc")),
                    ...ItemAccess(THEME.toLowerCase() === "cliq" && MenuAccess('virtualterminal')),
                },
                {
                    key: "virtualterminal-cliq-ach",
                    ...ItemLabel("/virtualterminal-cliq-ach", t("sidebar.virtualterminal-cliq-ach")),
                    ...ItemAccess(THEME.toLowerCase() === "cliq" && MenuAccess('virtualterminal')),
                },
                {
                    key: "fraudchecks",
                    // icon: <SecurityScanOutlined />,
                    ...ItemLabel("/fraudchecks", t("sidebar.fraudchecks")),
                    ...ItemAccess(MenuAccess('fraudchecks')),
                },
                {
                    key: "balance",
                    // icon: <SecurityScanOutlined />,
                    ...ItemLabel("/balance", t("sidebar.balance")),
                    ...ItemAccess(GroupAccess("SYSADMIN|RESELLER|MERCHANT")),
                },
                {
                    key: 'Payment Links',
                    ...ItemLabel("/paymentlink", t("sidebar.paymentlink")),
                    ...ItemAccess(DISPLAY_PAYMENT_LINK),
                }

            ],
            ...ItemAccess(MenuAccess('payments') && !DISABLE_PAYMENTS),
        },
        {
            key: "3ds",
            icon: <CheckCircleOutlined />,
            label: t("sidebar.3dsecure"),
            children: [
                {
                    key: "3ds_transaction",
                    ...ItemLabel("/3ds_transaction", t("sidebar.transactions")),
                },
            ],
            ...ItemAccess(MenuAccess('threeDS') && ! DISABLE_3DS),
        },
        {
            key: "3ds_microservice",
            icon: <CheckCircleOutlined />,
            label: t("sidebar.3dsecure_microservice"),
            children: [
                {
                    key: "3dsecure_overview",
                    ...ItemLabel("/3dsecure_overview", t("sidebar.3dsecure_overview")),
                },
                {
                    key: "3dsstats",
                    ...ItemLabel("/3dsstats", t("sidebar.statistics")),
                },
                {
                    key: "3dsecure_merchant",
                    ...ItemLabel("/3dsecure_merchant", t("sidebar.3dsecure_merchant")),
                },
                {
                    key: "3dsecure",
                    ...ItemLabel("/3dsecure", t("sidebar.transactions")),
                },
                {
                    key: "3dsbinmid",
                    ...ItemLabel("/3dsbinmid", t("sidebar.binmid")),
                },
            ],
            ...ItemAccess((GroupAccess("SYSADMIN") || AssociationsChecker("THREEDS")) && !DISABLE_THREEDS_MICROSERVICE && MenuAccess('threeDS')),
        },
        {
            key: "alertmenu",
            icon: <AlertOutlined />,
            label: t("sidebar.alerts"),
            children: [
                {
                    key: "alerts",
                    icon: <AlertOutlined />,
                    ...ItemLabel("/alerts", t("sidebar.alerts")),
                },
                {
                    key: "alertdescriptorsparent",
                    icon: <ClusterOutlined />,
                    label:t("sidebar.descriptors"),
                    children:[
                        {
                            key: "alertdescriptors",
                            icon: <ClusterOutlined />,
                            ...ItemLabel("/alertdescriptors", t("sidebar.descriptors"))
                        },
                        {
                            key: "descriptorvariations",
                            icon: <PartitionOutlined />,
                            ...ItemLabel("/descriptorvariations", t("sidebar.descriptorvariations")),
                        },
                        {
                            key: "descriptorenrollmentform",
                            icon: <FileAddOutlined />,
                            ...ItemAccess(DEV_MODE),
                            ...ItemLabel("/descriptorenrollmentform", t("sidebar.descriptorenrollmentform")),
                        },
                    ]
                    
                },
               
                {
                    key: "bincaiddescriptors",
                    icon: <ReconciliationOutlined />,
                    ...ItemLabel("/bincaiddescriptors", t("sidebar.bincaiddescriptors")),
                },
                {
                    key: "alertstats",
                    icon: <UnorderedListOutlined />,
                    ...ItemLabel("/alertstats", t("sidebar.statistics")),                           
                    ...ItemAccess(GroupAccess("SYSADMIN|MERCHANT")),
                },
                {
                    key: "rdractivity",
                    icon: <UnorderedListOutlined />,
                    ...ItemLabel("/rdractivity", t("sidebar.rdractivity")),                           
                    ...ItemAccess(GroupAccess("SYSADMIN|MERCHANT")),
                },
                {
                    key: "userguide",
                    icon: <MessageOutlined />,
                    ...UserGuideLabel(),
                    ...ItemAccess(THEME.toLowerCase() === "default"),
                },
            ],
            ...ItemAccess(MenuAccess('alerts') && !DISABLE_ALERTS),
        },
        {
            key: "messages",
            icon: <MessageOutlined />,
            ...ItemLabel("/messages", t("sidebar.messages")),
            ...ItemAccess(MenuAccess('messages')),
        },
        {
            key: "txalertmenu",
            icon: <AlertOutlined />,
            label: t("sidebar.txalert"),
            ...ItemAccess(!DISABLE_TXALERT2),
            children: [
                {
                    key: "alertlist",
                    icon: <AlertOutlined />,
                    ...ItemLabel("/alertlist", t("sidebar.alerts")),
                },
                {
                    key: "descriptorlist",
                    icon: <ClusterOutlined />,
                    ...ItemLabel("/descriptorlist", t("sidebar.descriptors")),
                },
                {
                    key: "bincaidlist",
                    icon: <ReconciliationOutlined />,
                    ...ItemLabel("/bincaidlist", t("sidebar.bincaiddescriptors")),
                },
                {
                    key: "merchantlist",
                    icon: <ShopOutlined />,
                    ...ItemLabel("/merchantlist", t("sidebar.merchants")),
                    ...ItemAccess(GroupAccess("SYSADMIN|MERCHANT")),
                },
                {
                    key: "txalert_admin",
                    icon: <SettingOutlined />,
                    label:  t("sidebar.admin"),
                    ...ItemAccess(GroupAccess("SYSADMIN")),
                    children: [
                        {
                            key: "acquiringbanklist",
                            icon: <ShopOutlined />,
                            ...ItemLabel("/acquiringbanklist", t("sidebar.acquiringbanklist")),
                        },
                        {
                            key: "acquiringbankaccountlist",
                            icon: <ShopOutlined />,
                            ...ItemLabel("/acquiringbankaccountlist", t("sidebar.acquiringbankaccountlist")),
                        },
                        {
                            key: "alertproviderlist",
                            icon: <ShopOutlined />,
                            ...ItemLabel("/alertproviderlist", t("sidebar.alertproviders")),
                        },
                        {
                            key: "alertproviderlogins",
                            icon: <ShopOutlined />,
                            ...ItemLabel("/alertproviderlogins", t("sidebar.alertproviderlogins")),
                        },
                        {
                            key: "enrollmentstatuslist",
                            icon: <BarsOutlined />,
                            ...ItemLabel("/enrollmentstatuslist", t("sidebar.enrollmentstatuslist"))
                        },
                        {
                            key: "disputeconditioncodelist",
                            icon: <BarsOutlined />,
                            ...ItemLabel("/disputeconditioncodelist", t("sidebar.disputeconditioncodelist"))
                        },
                        {
                            key: "alerttypelist",
                            icon: <ContainerOutlined />,
                            ...ItemLabel("/alerttypelist", t("sidebar.alerttypelist"))
                        },
                        {
                            key: "creditrequests",
                            icon: <ShopOutlined />,
                            ...ItemLabel("/creditrequests", t("sidebar.creditrequests")),
                        },
                        {
                            key: "creditreasonlist",
                            icon: <ContainerOutlined />,
                            ...ItemLabel("/creditreasonlist", t("sidebar.creditreasonlist"))
                        },
                        {
                            key: "creditstatuslist",
                            icon: <ContainerOutlined />,
                            ...ItemLabel("/creditstatuslist", t("sidebar.creditstatuslist"))
                        },
                        {
                            key: "mcctierlist",
                            icon: <ContainerOutlined />,
                            ...ItemLabel("/mcctierlist", t("sidebar.mcctierlist"))
                        },
                        {
                            key: "erproviderlist",
                            icon: <ShopOutlined />,
                            ...ItemLabel("/erproviderlist", t("sidebar.erproviders")),
                        },
                        {
                            key: "erproviderlogins",
                            icon: <ShopOutlined />,
                            ...ItemLabel("/erproviderlogins", t("sidebar.erproviderlogins")),
                        },
                        {
                            key: "alertresponses",
                            icon: <ShopOutlined />,
                            ...ItemLabel("/alertresponses", t("sidebar.alertresponses")),
                        },
                        {
                            key: "ertransactionmatches",
                            icon: <ShopOutlined />,
                            ...ItemLabel("/ertransactionmatches", t("sidebar.ertransactionmatches")),
                        },
                        {
                            key: "themelist",
                            icon: <ShopOutlined />,
                            ...ItemLabel("/themelist", t("sidebar.themelist")),
                        },
                        {
                            key: "emailtemplatelist",
                            icon: <ShopOutlined />,
                            ...ItemLabel("/emailtemplatelist", t("sidebar.emailtemplatelist")),
                        },
                        {
                            key: "issuerbinlist",
                            icon: <ShopOutlined />,
                            ...ItemLabel("/issuerbinlist", t("sidebar.issuerbinlist")),
                        },
                        {
                            key: "generatealert",
                            icon: <ShopOutlined />,
                            ...ItemLabel("/generatealert", t("sidebar.generatealert")),
                        }]
                },
                {
                    key: "txalert_logs",
                    icon: <SettingOutlined />,
                    label:  t("sidebar.logs"),
                    children: [                        
                        {
                            key: "posttoalertproviderlogs",
                            icon: <ShopOutlined />,
                            ...ItemLabel("/posttoalertproviderlogs", t("sidebar.posttoalertproviderlogs")),                            
                            ...ItemAccess(GroupAccess("SYSADMIN")),
                        },
                        {
                            key: "posttomerchantlogs",
                            icon: <ShopOutlined />,
                            ...ItemLabel("/posttomerchantlogs", t("sidebar.posttomerchantlogs")),                            
                            ...ItemAccess(GroupAccess("SYSADMIN|MERCHANT")),
                        },
                        {
                            key: "posttoalertproviderretrylogs",
                            icon: <ShopOutlined />,
                            ...ItemLabel("/posttoalertproviderretrylogs", t("sidebar.posttoalertproviderretrylogs")),                            
                            ...ItemAccess(GroupAccess("SYSADMIN")),
                        },
                        {
                            key: "posttomerchantretrylogs",
                            icon: <ShopOutlined />,
                            ...ItemLabel("/posttomerchantretrylogs", t("sidebar.posttomerchantretrylogs")),                            
                            ...ItemAccess(GroupAccess("SYSADMIN|MERCHANT")),
                        },
                        {
                            key: "emailsendlogs",
                            icon: <ShopOutlined />,
                            ...ItemLabel("/emailsendlogs", t("sidebar.emailsendlogs")),                            
                            ...ItemAccess(GroupAccess("SYSADMIN")),
                        },
                        {
                            key: "backgroundprocesslogs",
                            icon: <ShopOutlined />,
                            ...ItemLabel("/backgroundprocesslogs", t("sidebar.backgroundprocesslogs")),                            
                            ...ItemAccess(GroupAccess("SYSADMIN")),
                        },
                        {
                            key: "expressresolvelogs",
                            icon: <ShopOutlined />,
                            ...ItemLabel("/expressresolvelogs", t("sidebar.expressresolvelogs")),
                            ...ItemAccess(GroupAccess("SYSADMIN")),
                        },]
                    }
            ],
        },
        {
            key: "cbm",
            icon: <RollbackOutlined />,
            label: t("sidebar.chargebacks"),
            children: [
                {
                    key: "chargeback_merchants",
                    ...ItemLabel("/chargeback_merchants", t("sidebar.merchants")),
                    ...ItemAccess(GroupAccess("SYSADMIN") || AssociationsChecker("CBM")),
                },
                {
                    key: "chargeback_providers",
                    ...ItemLabel("/chargeback_providers", t("sidebar.providers")),
                    ...ItemAccess(GroupAccess("SYSADMIN")),
                },
                {
                    key: "chargeback_merchant_providers",
                    ...ItemLabel("/chargeback_merchant_providers", t("sidebar.merchant_providers")),
                    ...ItemAccess(GroupAccess("SYSADMIN")),
                },
                {
                    key: "chargebacks",
                    ...ItemLabel("/chargebacks", t("sidebar.chargebacks")),
                },
                {
                    key: "chargeback_alertproviders",
                    ...ItemLabel("/chargeback_alertproviders", t("sidebar.alertproviders")),
                    ...ItemAccess(GroupAccess("SYSADMIN")),
                },
                {
                    key: "chargeback_alertprovider_logins",
                    ...ItemLabel("/chargeback_alertprovider_logins", t("sidebar.merchantalertproviders")),
                    ...ItemAccess(GroupAccess("SYSADMIN")),
                }
            ],
            ...ItemAccess((GroupAccess("SYSADMIN") || AssociationsChecker("CBM")) && !DISABLE_CBM && MenuAccess('cbm')),
        },
        {
            key: "txfind",
            icon: <DollarCircleOutlined />,
            label: t("sidebar.order_insight"),
            ...ItemAccess((GroupAccess("SYSADMIN") || AssociationsChecker("OI")) && (!DISABLE_OI || !DISABLE_CBM) && MenuAccess('order_insights')),
            children: [
              {
                key: "txfind_merchants",
                ...ItemLabel("/txfind_merchants", t("sidebar.merchants")),
              },
              {
                key: "txfind_descriptors",
                ...ItemLabel("/txfind_descriptors", t("sidebar.descriptors")),
              },
              {
                key: "txfind_bincaids",
                ...ItemLabel("/txfind_bincaids", t("sidebar.bincaids")),
              },
              {
                key: "txfind_carts",
                ...ItemLabel("/txfind_carts", t("sidebar.carts")),
              },
              {
                key: "txfind_carts_lookup",
                ...ItemLabel("/txfind_carts_lookup", t("sidebar.lookup")),
              },
              {
                key: "txfind_ce_lookup",
                ...ItemLabel("/txfind_ce_lookup", "CE Lookup"),
              },
            ],
          },
    ];

    return (
        <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[getDefaultSelectedKeys()]}
            defaultOpenKeys={getDefaultOpenKeys()}
            style={{ borderRight: 0, backgroundColor: theme }}
            items={menuItems}
            _internalRenderMenuItem={node =>
                React.cloneElement(node, {
                  style: {
                    ...node.props.style,
                    backgroundColor: theme,
                  },
                })
              }
            
        />
    );
};

export default Sidebar;

import { Button, notification, Alert } from 'antd';
import { WarningOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import React, {useState} from 'react';
import { debounce } from "lodash";

import { API_BASE_URL } from '../config/config';

const Notification = () => {

  const [user] = useState(JSON.parse(localStorage.getItem("loggedUser")));  
  const markAsRead = debounce((id) => {
    axios({
      url: API_BASE_URL + '/v3/usermessages/read',
      method: "POST",
      data: { amid: id, uid: user.userid},
      headers: { Authorization: "Bearer " + localStorage.getItem("access_token") }
    }).catch((err) => {
      console.log(err);
    })

    notification.close(id); // Close notification
  }, 500);


  // Fetch user messages
  axios
    ({
      url: API_BASE_URL + '/v3/usermessages',
      method: "POST",
      headers: { Authorization: "Bearer " + localStorage.getItem("access_token") }
    })
    .then(({ data }) => {

      if (data.messages && data.messages.length) {

        data.messages.forEach(element => {

          element.message = String(element.message);
          if (element.message.includes('crons/rates.php')) {
            let url = String(API_BASE_URL).replace('api', 'crons/rates.php');
            element.message = <div>
              <Alert message="Exchange Rate in the system is outdated." type="error" />
              Run cron manually from <a target='_blank' rel="noopener noreferrer" href={url}> Update Rates</a>
            </div>
          }
          const key = element.amid;
          const btn = (
            <Button size="small" onClick={() => { markAsRead(key) }}>
              Mark as read
            </Button>
          );
          notification.open({
            message: element.subject,
            description:
              element.message,
            btn,
            key,
            duration: 0,
            icon: (String(element.type).toLowerCase() === 'warn') ? <WarningOutlined style={{ color: 'orange' }} /> : <ExclamationCircleOutlined style={{ color: '#1890FF' }} />,
            style: {
              backgroundColor: '#f0f0f0',
              borderRadius: '5px',
              boxShadow: '0px 5px 12px 4px rgba(0, 0, 0, 0.35)'
            },
          });
        });
      }
    }).catch((err) => {
      console.log(err);
      notification.open({
        message: 'System Message Request Failed',
        description:
          'Unable to request system message. If the issue persists, please contact support.',
        key: `open${Date.now()}`,
        icon: <WarningOutlined style={{ color: 'orange' }} />,
        duration: 0,
        style: {
          backgroundColor: '#f0f0f0',
          borderRadius: '5px',
          boxShadow: '0px 5px 12px 4px rgba(0, 0, 0, 0.5)'
        },
      });
    })
};

export default Notification
import React, { useState, useEffect} from "react";
import DataFetchHookV3 from "../../helper/DataFetchHookV3";
import { Table, PageHeader, Card, Form, Row, Button, Col, Input, Select, Checkbox } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import GroupAccess from "../../helper/GroupAccess";
import ExportModalV3 from "../../helper/ExportModalV3";
import CustomPaginationV2 from "../../helper/Table/CustomPaginationV2";
import CustomDateRangeV3 from "../../helper/Table/CustomDateRangeV3";
import {API_TXALERT} from "../../config/config";
import moment from "moment";
import ColumnResizable from "../../helper/Table/ColumnResizable";
import MergeColumns from "../../helper/Table/MergeColumns";
import MerchantList from "./../../components/MerchantList";
import RetailerList from "./../../components/RetailerList";
import SiteList from "./../../components/SiteList";
import { Typography } from 'antd';
const { Text } = Typography;

const DATA_URL = API_TXALERT + "/rdractivity";
const DATA_URL_EXPORT = API_TXALERT + "/rdractivity/reports";

const { Option } = Select;

const MAX_DATERANGE = 3;
const DEFAULT_DATERANGE = 7;
const DEFAULT_RANGETYPE = "days";
const START_DATE = moment().subtract(DEFAULT_DATERANGE, DEFAULT_RANGETYPE);
const END_DATE = moment();


const RDRActivity = (props) => {
    let actionFromDashboard = ["resolved", "declined"];
    const dataFetchHookObj = DataFetchHookV3(DATA_URL,"POST", {
        keyword: "",
        startDate: START_DATE,
        endDate: END_DATE,
        field: null,
        merchant: [],
        retailer: [],
        site: [],
        order: "DESC",
        sort_field: "alertdate",
        size: 20,
        page: 1,
        outcome: actionFromDashboard,
    });

    const [{ data, totalResult, isLoading, requestData }] = dataFetchHookObj;
    const setRequestData = dataFetchHookObj[2];

    const [dates, setDates] = useState([START_DATE, END_DATE]);
    const [midSelect, setMidSelect] = useState('');
    const [ridSelect, setRidSelect] = useState('');
    const [formData, setFormData] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [isDateExceeded, setIsDateExceeded] = useState(false);
    const [isSortUpdate, setIsSortUpdate] = useState(false);
    const [rdrIsDelete, setRdrIsDelete] = useState(false);
    const { t } = props;

    const initFormData = {
        keyword: "",
        dateRange: [START_DATE, END_DATE],
        field: null,
        merchant: [],
        retailer: [],
        site: [],
        order: "DESC",
        sort_field: "alertdate",
        outcome: actionFromDashboard,
    };

    const [form] = Form.useForm();

    useEffect(() => {
        formValInit();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        formValInit();
    }, [isSortUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (pagination, filters, sorter, { currentDataSource }) => {
        // setSortedInfo(sorter);
        var orderBy = 'ASC';
        if(sorter.order === 'descend'){
            orderBy = 'DESC';
        }
        var sortField = 'alertdate';
        if (sorter.field !== undefined){
            sortField = sorter.field;
        }
        var p = pagination.current;
        if (Object.keys(sorter).length >0 && requestData.sort_field !== sortField){
            p = 1;
            setCurrentPage(1);
        }

        setRequestData({ ...requestData, order: orderBy, sort_field: sortField, page: p, size: pagination.pageSize  });
        setIsSortUpdate(true);
    };

    const sorterCompare = (a,b) => {
        if(!isNaN(a) && !isNaN(b)){
            return a - b;
        }
        a = (a !== null) ? String(a) : " ";
        b = (b !== null) ? String(b) : " ";
        return a?.localeCompare(b);
    }
    
    const renderData = (text, record, outcome = false) => {
        if(outcome) {
            let textColor = {};
            if(text && String(text).toLowerCase() === 'declined' ) {
                textColor = { color: "#ff4d4f" };
            }else if(text && String(text).toLowerCase() === 'resolved'){
                textColor = { color: "#52c41a" };
            }
            return text = <Text style={textColor}> {text} </Text>;;
        }
        return (String(record?.case_event).toLowerCase() === 'delete' && text) ? <Text style={{ color: "#ff4d4f" }}> {text} </Text> : text;
    }

    const onChangeRetailer = async (value) => {
        await setRidSelect(value)
    }

    const onChangeMerchant = async (value) => {
        await setMidSelect(value)
    }

    const clearAll = () => {
        setRidSelect('');
        setMidSelect('');
        setCurrentPage(1);
        setFormData(initFormData);
        setRequestData({ ...requestData, ...initFormData, startDate: null, endDate: null, page: 1 });
        form.setFieldsValue(initFormData);
    };

    const formValInit = () => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);
    };

    const formValChange = (chgV, allV) => {
        setFormData(allV);
    };

    const onChangeChkbox  = (e) => {
            setRdrIsDelete(e.target.checked)
    }

    const onFinish = () => {
        let startDate = null;
        let endDate = null;
        let searchData = {};

        if (formData) {
            if (dates) {
                startDate = dates[0] ? dates[0] : null;
                endDate = dates[1] ? dates[1] : null;
            }
            searchData = {
                field: formData.field,
                keyword: formData.keyword ? formData.keyword.trim() : null,
                order: formData.order,
                mid: formData.merchant,
                rid: formData.retailer,
                sid: formData.site,
                outcome: formData.outcome,
                delete: rdrIsDelete
            };
        }
        // Reset Page Index to 1
        setCurrentPage(1);
        setRequestData({ ...requestData, ...searchData, startDate: startDate, endDate: endDate, page: 1 });
    };


    const exportHeadersOptional = [];
    const exportHeaders = [
        { label: "Alert ID", key: "a.id" },
        { label: "Alert Date", key: "a.alertdate" },
        { label: "Descriptor", key: "a.descriptor" },
        { label: "Transaction Date", key: "DATE_FORMAT(a.transaction_date, '%b %d, %Y')" },
        { label: "Amount", key: "a.transaction_amount" },
        { label: "Card Number", key: "a.cardnumber" },
        { label: "ARN", key: "a.arn" },
        { label: "Bin", key: "a.bin" },
        { label: "Caid", key: "a.caid" },
        { label: "Delete Case ID", key: "ara.caseid" },
        { label: "Delete Received Date", key: "DATE_FORMAT(received_date, '%b %d, %Y')" },
        { label: "Outcome", key: "a.outcome" }
    ];

    const columns = [
        {
            dataIndex: "alert_id",
            key: "alert_id",
            title: t("tables.fields.alert_id"),
            width: 100,
            ellipsis: true,
            sorter: {
                compare: (a,b) => sorterCompare(a.alert_id, b.alert_id)
            },
            render: (text,record) => renderData(text,record)
        },
        {
            dataIndex: "alertdate",
            key: "alertdate",
            title: t("tables.fields.alertdate"),
            width: 170,
            ellipsis: true,
            sorter: {
                compare: (a,b) => sorterCompare(a.alert_id, b.alert_id)
            },
            render: (text,record) => renderData( moment(text).format("lll") ,record)
        },
        {
            dataIndex: "descriptor",
            key: "descriptor",
            title: t("tables.fields.descriptor"),
            width: 150,
            ellipsis: true,
            sorter: {
                compare: (a,b) => sorterCompare(a.descriptor, b.descriptor)
            },
            render: (text,record) => renderData(text,record)
        },
        {
            dataIndex: "transaction_date",
            key: "transaction_date",
            title: t("tables.fields.transaction_date"),
            width: 150,
            ellipsis: true,
            sorter: {
                compare: (a, b) => sorterCompare(a.transaction_date, b.transaction_date),
            },
            render: (text, record) => renderData( moment(text).format("lll") ,record),
        },
        {
            dataIndex: "amount",
            key: "amount",
            title: t("tables.fields.amount"),
            width: 80,
            ellipsis: true,
            sorter: {
                compare: (a, b) => sorterCompare(a.amount, b.amount)
            },
            render: (text,record) => {
                text = ` ${ (text)? text: '' }  ${ (record.transaction_currency) ? record.transaction_currency : ''  } `;
                return renderData(text,record);
            }
        },
        {
            dataIndex: "cardnumber",
            key: "cardnumber",
            title: t("tables.fields.card_no"),
            width: 150,
            ellipsis: true,
            sorter: {
                compare: (a, b) => sorterCompare(a.cardnumber, b.cardnumber),
            },
            render: (text,record) => renderData(text,record)
        },
        {
            dataIndex: "arn",
            key: "arn",
            title: t("tables.fields.arn"),
            width: 150,
            ellipsis: true,
            sorter: {
                compare: (a, b) => sorterCompare(a.arn, b.arn),
            },
            render: (text,record) => renderData(text,record)
        },
        {
            dataIndex: "bin",
            key: "bin",
            title: t("tables.fields.bin"),
            width: 100,
            ellipsis: true,
            sorter: {
                compare: (a,b) => sorterCompare(a.bin, b.bin)
            },
            render: (text,record) => renderData(text,record)
        },
        {
            dataIndex: "caid",
            key: "caid",
            title: t("tables.fields.caid"),
            width: 100,
            ellipsis: true,
            sorter: {
                compare: (a,b) => sorterCompare(a.caid, b.caid)
            },
            render: (text,record) => renderData(text,record)
        },
        {
            dataIndex: "caseid",
            key: "caseid",
            title: t("tables.fields.delete_case_id"),
            width: 200,
            ellipsis: true,
            sorter: {
                compare: (a, b) => sorterCompare(a.caseid, b.caseid),
            },
            render: (text,record) => {
                text = (record.received_date && record.caseid) ? record.caseid : '';
                return renderData(text,record)
            }
        },
        {
            dataIndex: "received_date",
            key: "received_date",
            title: t("tables.fields.delete_received_date"),
            width: 160,
            ellipsis: true,
            fixed: 'right',
            sorter: {
                compare: (a, b) => sorterCompare(a.received_date, b.received_date)
            },
            render: (text,record) => {
                text = (record.received_date && record.caseid) ? moment(record.received_date).format('lll') : '';
                return renderData( text,record)
            }
        },
        {
            dataIndex: "outcome",
            key: "outcome",
            title: t("tables.fields.outcome"),
            width: 80,
            ellipsis: true,
            fixed: 'right',
            sorter: {
                compare: (a, b) => sorterCompare(a.outcome, b.outcome)
            },
            render: (text,record) => renderData(text, record, true)
        },
    ];

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                            <Form.Item name="dateRange" rules={[
                                    {
                                        validator: () => {
                                            if(isDateExceeded){
                                                return Promise.reject(t('max_3_months'));
                                            }else if((dates[0] && !dates[1]) || (!dates[0] && dates[1])){
                                                return Promise.reject(t('min_1_day'));
                                            }else{
                                                return Promise.resolve();
                                            }
                                        }
                                    }
                                ]}>
                                <CustomDateRangeV3 t={t} setIsDateExceeded={setIsDateExceeded} state={{ dates, setDates }} unit="months" limit={MAX_DATERANGE} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="outcome">
                                <Select mode="multiple" showArrow="true" maxTagCount="responsive" allowClear style={{ width: "100%" }} placeholder={t("tables.fields.outcome")}>
                                    <Option value="resolved">Resolved</Option>
                                    <Option value="declined">Declined</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                       <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item name="merchant">
                                 <MerchantList placeholder={t("contents.merchants.title")} onChange={onChangeMerchant}
                                    style={{ width: "100%" }} />
                                </Form.Item>
                       </Col>
                       <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="retailer">
                                <RetailerList placeholder={t("contents.retailers.title")} mid={midSelect} onChange={onChangeRetailer}
                                    style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="site">
                                <SiteList placeholder={t("contents.sites.title")} mid={midSelect} rid={ridSelect}
                                    style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                            <Form.Item name="field">
                                <Select 
                                    showSearch
                                    style={{ width: "100%" }} 
                                    placeholder={t("tables.fields.field")}
                                    >
                                    <Option value="alert_id">{t("tables.fields.alert_id")}</Option>
                                    <Option value="descriptor">{t("tables.fields.descriptor")}</Option>
                                    <Option value="providername">{t("tables.fields.providername")}</Option>
                                    <Option value="amount">{t("tables.fields.amount")}</Option>
                                    <Option value="cardnumber">{t("tables.fields.card_no")}</Option>
                                    <Option value="arn">{t("tables.fields.arn")}</Option>
                                    <Option value="caseid">{t("tables.fields.caseid")}</Option>
                                    <Option value="bin">{t("tables.fields.bin")}</Option>
                                    <Option value="caid">{t("tables.fields.caid")}</Option>
                                    <Option value="bin|caid">{t("tables.fields.bin")}|{t("tables.fields.caid")}</Option>
                                   </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                            <Form.Item name="keyword">
                                <Input placeholder={t("tables.fields.keyword")} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                            <Checkbox  onChange={onChangeChkbox}> {t("tables.fields.show_delete_only")} </Checkbox>
                        </Col>
                        </Row>
                        <Row gutter={[16,16]}>
                        <Col flex="auto"></Col>
                        <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 12 }}
                            md={{ span: 6, offset: 0 }}
                            lg={{ span: 4, offset: 16 }}
                            xl={{ span: 3, offset: GroupAccess("SYSADMIN|Merchant") ? 0 : 5 }}
                        >
                            <Form.Item>
                                <Button type="secondary" onClick={() => clearAll()} block>
                                    {t("tables.actions.clear_all")}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> {t("tables.actions.search")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.rdractivity.title")}</h3>
            <ExportModalV3
                t={t}
                keyname="export_all"
                title={t("tables.actions.export")}
                data={requestData}
                data_url={DATA_URL_EXPORT}
                headers={exportHeaders}
                fieldHeadersOptional={exportHeadersOptional}
                filename={`rdractivity_${moment().format("YYMMDD_HHmmss_SSSS")}.csv`}
                style={{ marginLeft: 8 }}
            />
        </div>
    );

    return (
        <>
            <PageHeader title={t("contents.rdractivity.title")}></PageHeader>
            {AdvancedSearchForm()}
            <Table
                scroll={{ x: '100%' }}
                rowKey="alert_id"
                size="small"
                title={() => getHeaderTable()}
                loading={isLoading}
                className="tableOverflow table-v3 custom-pagination custom-pagination-v3"
                components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }}
                columns={MergeColumns(columns)}
                dataSource={getDataSource(data.datalist)}
                onChange={handleChange}
                pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}
            />
        </>
    );
};

export default RDRActivity;

import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { OI_URL } from "../config/config";
import TxFindAccessToken from './TxFindAccessToken';
import DefaultPageSize from "./Table/DefaultPageSize";

const DataFetchHookOI = (initialurl, requestMethod = "POST", accessToken = null, defaultRequestData = { page: 1, size: 20, order: "DESC", sortField: "dateCreated" }, maxRange = 3) => {
    const [data, setData] = useState({ datalist: [] });
    const [totalResult, setTotalResult] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [isError, setIsError] = useState(false);
    const [url, setUrl] = useState(OI_URL + initialurl);
    
    // Ensure we start with numeric values for pagination
    const initialPageSize = parseInt(DefaultPageSize()) || 20;
    const initialRequestData = {
        ...defaultRequestData,
        page: parseInt(defaultRequestData.page) || 1,
        size: initialPageSize
    };
    
    const [requestData, setRequestData] = useState(initialRequestData);
    const [method, setMethod] = useState(requestMethod);
    const [token, setToken] = useState(accessToken);
    const [maxDateRange, setMaxDateRange] = useState(maxRange);
    const DATE_FORMAT_FROM = "YYYY-MM-DD 00:00:00";
    const DATE_FORMAT_TO = "YYYY-MM-DD 23:59:59";

    // FIX: Pass through dateFrom and dateTo as they are, without trying to transform them
    // This allows the CartList component to have full control over date formatting
    const getStartDate = () => requestData.dateFrom || null;
    const getEndDate = () => requestData.dateTo || null;
   
    // Ensure pagination parameters are always numeric
    const getPage = () => parseInt(requestData?.page) || 1;
    const getSize = () => parseInt(requestData?.size) || initialPageSize;
    
    // Ensure sort parameters have valid values
    const getOrder = () => 
        requestData?.order === "ASC" ? "ASC" : "DESC";
    
    const getSortField = () => 
        requestData?.sortField || "dateCreated";

    // Custom requestData setter that ensures page and size are numbers
    const safeSetRequestData = (newData) => {
        // If newData is a function, call it with current data and normalize the result
        if (typeof newData === 'function') {
            setRequestData(prevData => {
                const updatedData = newData(prevData);
                return {
                    ...updatedData,
                    page: parseInt(updatedData.page) || 1,
                    size: parseInt(updatedData.size) || initialPageSize
                };
            });
        } else {
            // Otherwise, just normalize the new data
            setRequestData({
                ...newData,
                page: parseInt(newData.page) || 1,
                size: parseInt(newData.size) || initialPageSize
            });
        }
    };

    useEffect(() => {
        const fetchdata = async () => {
            setIsLoading(true);
            setIsError(false);
            
            try {
                const newtoken = await TxFindAccessToken();
                if (!newtoken) {
                    console.error("Error: Token is undefined");
                    setIsError(true);
                    setIsLoading(false);
                    return;
                }
                
                let options = { headers: { Authorization: "Bearer " + newtoken } };
        
                // Ensure we always send proper pagination parameters
                const pageParam = getPage();
                const sizeParam = getSize();
                
                let param = { 
                    ...requestData, 
                    dateFrom: getStartDate(), 
                    dateTo: getEndDate(), 
                    page: pageParam, 
                    size: sizeParam, 
                    order: getOrder(),
                    sortField: getSortField()
                };
                                
                let result;                
                if (method === "POST") {
                    result = await axios.post(url, param, options);
                } else {
                    result = await axios.get(url, options);
                }
                
                if (result.data) {
                    if (result.data.list) {
                        setData({ datalist: result.data.list });
                    }
                    if (result.data.totalResult >= 0) {
                        setTotalResult(parseInt(result.data.totalResult));
                    }
                    if (result.data.msg) {
                        setErrorMsg(result.data);
                        setIsError(true);
                    }
                } else {
                    console.error("DataFetchHookOI - No data received from API");
                    setIsError(true);
                }
            } catch (err) {
                console.error("Error fetching data:", err);
                setIsError(true);
                setErrorMsg(err.message);
            }
            
            setIsLoading(false);
        };
        
        fetchdata();
    }, [url, requestData, method]); // eslint-disable-line react-hooks/exhaustive-deps

    return [{ data, totalResult, requestData, isLoading, isError, errorMsg }, setUrl, safeSetRequestData, setMethod, setToken];
};

export default DataFetchHookOI;
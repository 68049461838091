import React, { useEffect, useState } from 'react'
import { PageHeader } from 'antd';
import { Table } from 'antd';
import DataFetchHookThreeDS from './../../helper/DataFetchHookThreeDS';
import { API_DATA_SOURCE, THREEDS_URL } from '../../config/config';
import CustomPaginationV2 from "../../helper/Table/CustomPaginationV2";
import SortOrder from "../../helper/Table/SortOrder";
import ColumnResizable from "../../helper/Table/ColumnResizable";
import MergeColumns from "../../helper/Table/MergeColumns";
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import AddMerchantModal from './AddMerchantModal';
import axios from 'axios';
import { Card } from 'antd';
import { Form } from 'antd';
import { Row } from 'antd';
import { Col } from 'antd';
import moment from "moment";
import { Select } from 'antd';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import { Typography } from 'antd';
import GroupAccess from '../../helper/GroupAccess';
const { Text } = Typography;


const { Option } = Select;
const DEFAULT_DATE_RANGE = 2;
const DEFAULT_DATE_PERIOD = "days";
const START_DATE = moment().subtract(DEFAULT_DATE_RANGE, DEFAULT_DATE_PERIOD);
const END_DATE = moment();
const DATA_URL = THREEDS_URL + "/api/v1/merchants";

const initFormData = {
    dateRange: [START_DATE, END_DATE],
    mids: [],
    field: null,
    keyword: null
};

let countryList = '';
let currencyList = '';
if(localStorage.getItem("access_token")) {
axios
    .post(API_DATA_SOURCE + `/country`, {}, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
    .then(({ data }) => {
        countryList = data;
    })
    .catch((error) => console.log(error));

axios
    .post(API_DATA_SOURCE + `/currency`, {}, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
    .then(({ data }) => {
        currencyList = data;
    })
    .catch((error) => console.log(error));
}

const Threeds_Merchant = (props) => {
    const [{ data, totalResult, isLoading, isError, errorMsg, requestData, isUpdated }, doFetch, setRequestData] = DataFetchHookThreeDS(DATA_URL); // eslint-disable-line
    const { t } = props;
    const [isAddMerchantModalVisible, setIsAddMerchantModalVisible] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortedInfo] = useState({});
    const [form] = Form.useForm();
    const [formData, setFormData] = useState();
    const [dates] = useState([START_DATE, END_DATE]);
    const [merchantData, setMerchantData] = useState(false);
    const [merchantList, setMerchantList] = useState(null);
    const [searchField, setSearchField] = useState(null);
    const searchFieldList = {
        'rs.name': (GroupAccess('SYSADMIN') ? t('contents._3dsecure_merchant.form.name'): t('contents._3dsecure_merchant.form.dba_name')),

        ...( GroupAccess('SYSADMIN') ? {
            'm.description': t('contents._3dsecure_merchant.form.description'),
        'm.contact': t('contents._3dsecure_merchant.form.contact'),
        'm.status': 'Status',
        'm.remarks': t('contents._3dsecure_merchant.form.remarks'),
        'rsss.threedsprotocolversion': t('contents._3dsecure_merchant.form.3ds_protocol_version'),
        'rsss.three_ds_provider': t('contents._3dsecure_merchant.form.3ds_provider'),
        'rsss.hitrust_account_id': t('contents._3dsecure_merchant.form.hitrust_account_id'),
        'rsss.hitrust_password': t('contents._3dsecure_merchant.form.hitrust_password'),
        'rsss.hitrust_base_url': t('contents._3dsecure_merchant.form.hitrust_base_url'),
        'rsss.hitrust_mastercard_prefix': t('contents._3dsecure_merchant.form.hitrust_mastercard_requestor_prefix'),
        'rsts.clientMode': t('contents._3dsecure_merchant.form.client_mode'),
        'rsts.enableResult': t('contents._3dsecure_merchant.form.enable_result'),
        'rsts.enable3ds1Frictionless': t('contents._3dsecure_merchant.form.enable_3ds1_friction_less'),
        'rsts.enable3DS1Only': t('contents._3dsecure_merchant.form.enable_3ds1_only'),
        'rsts.requestorName': t('contents._3dsecure_merchant.form.requestor_name'),
        'rsts.acquirerMerchantPwd': t('contents._3dsecure_merchant.form.acquirer_merchant_id_password'),
        'rsts.messageCategory': t('contents._3dsecure_merchant.form.message_category'),
        'rsts.acctType': t('contents._3dsecure_merchant.form.acct_type'),
        } : {} ),

        'rsts.requestorURL': ( GroupAccess('SYSADMIN') ? t('contents._3dsecure_merchant.form.requestor_url') : t('contents._3dsecure_merchant.form.sales_url') ) ,
        'rsts.disableChallenge': t('contents._3dsecure_merchant.form.disable_challenge'),
        'rsts.authenticationInd': t('contents._3dsecure_merchant.form.3DS_requestor_authentication_indicator'),
        'rsts.challengeInd': t('contents._3dsecure_merchant.form.3DS_requestor_challenge_indicator'),
        'rsts.mcc': t('contents._3dsecure_merchant.form.merchant_category_code'),
        'rsts.merchantCountryCode': t('contents._3dsecure_merchant.form.merchant_country_code'),
        'rsts.purchaseCurrency': t('contents._3dsecure_merchant.form.purchase_currency'),
        'rsts.transType': t('contents._3dsecure_merchant.form.transaction_type'),
    }

    const clientModeOption = [
        {
            key: 'app',
            value: '1',
            label: t('contents._3dsecure_merchant.form.client_mode_option_app')
        },
        {
            key: 'brw',
            value: '2',
            label: t('contents._3dsecure_merchant.form.client_mode_option_brw')
        },
        {
            key: '3ri',
            value: '3',
            label: t('contents._3dsecure_merchant.form.client_mode_option_3ri')
        }
    ];

    const enableResultOption = [
        {
            key: 'Y',
            value: 'Y',
            label: t('contents._3dsecure_merchant.form.enable_result_option_y'),
        },
        {
            key: 'N',
            value: 'N',
            label: t('contents._3dsecure_merchant.form.enable_result_option_n'),
        }
    ]

    const disableChallengeOption = [
        {
            key: 'Y',
            value: 'Y',
            label: t('contents._3dsecure_merchant.form.disable_challenge_option_y')
        },
        {
            key: 'N',
            value: 'N',
            label: t('contents._3dsecure_merchant.form.disable_challenge_option_n')
        }
    ]

    const enable3ds1FrictionLessOption = [
        {
            key: 'Y',
            value: 'Y',
            label: t('contents._3dsecure_merchant.form.enable_3ds1_friction_less_option_y')
        },
        {
            key: 'N',
            value: 'N',
            label: t('contents._3dsecure_merchant.form.enable_3ds1_friction_less_option_n')
        }
    ]

    const enable3ds1OnlyOption = [
        {
            key: 'Y',
            value: 'Y',
            label: t('contents._3dsecure_merchant.form.enable_3ds1_only_option_y')
        },
        {
            key: 'N',
            value: 'N',
            label: t('contents._3dsecure_merchant.form.enable_3ds1_only_option_n')
        }
    ]

    const authenticationIndicatorOption = [
        {
            key: '01',
            value: '01',
            label: t('contents._3dsecure_merchant.form.3DS_requestor_authentication_indicator_option_payment_transaction')
        },
        {
            key: '02',
            value: '02',
            label: t('contents._3dsecure_merchant.form.3DS_requestor_authentication_indicator_option_recurring_transaction')
        },
        {
            key: '03',
            value: '03',
            label: t('contents._3dsecure_merchant.form.3DS_requestor_authentication_indicator_option_installment_transaction')
        },
        {
            key: '04',
            value: '04',
            label: t('contents._3dsecure_merchant.form.3DS_requestor_authentication_indicator_option_add_card')
        },
        {
            key: '05',
            value: '05',
            label: t('contents._3dsecure_merchant.form.3DS_requestor_authentication_indicator_option_maintain_card')
        },
        {
            key: '06',
            value: '06',
            label: t('contents._3dsecure_merchant.form.3DS_requestor_authentication_indicator_option_cardholder_verification')
        },
    ]

    const challengeIndicatorOption = [
        {
            key: '01',
            value: '01',
            label: t('contents._3dsecure_merchant.form.3DS_requestor_challenge_indicator_option_payment_transaction')
        },
        {
            key: '02',
            value: '02',
            label: t('contents._3dsecure_merchant.form.3DS_requestor_challenge_indicator_option_recurring_transaction')
        },
        {
            key: '03',
            value: '03',
            label: t('contents._3dsecure_merchant.form.3DS_requestor_challenge_indicator_option_installment_transaction')
        },
        {
            key: '04',
            value: '04',
            label: t('contents._3dsecure_merchant.form.3DS_requestor_challenge_indicator_option_add_card')
        }
    ];

    const accountTypeOption = [
        {
            key: '01',
            value: '01',
            label: t('contents._3dsecure_merchant.form.acct_type_option_not_applicable')
        },
        {
            key: '02',
            value: '02',
            label: t('contents._3dsecure_merchant.form.acct_type_option_credit')
        },
        {
            key: '03',
            value: '03',
            label: t('contents._3dsecure_merchant.form.acct_type_option_debit')
        }
    ]

    const messageCategoryOption = [
        {
            key: '01',
            value: '01',
            label: t('contents._3dsecure_merchant.form.message_category_option_pa')
        },
        {
            key: '02',
            value: '02',
            label: t('contents._3dsecure_merchant.form.message_category_option_npa')
        },
    ]
    
    const transactionTypeOption = [
        {
            key: 'gs',
            value: '01',
            label: t('contents._3dsecure_merchant.form.transaction_type_option_gs')
        },
        {
            key: 'ca',
            value: '03',
            label: t('contents._3dsecure_merchant.form.transaction_type_option_ca')
        },
        {
            key: 'af',
            value: '10',
            label:t('contents._3dsecure_merchant.form.transaction_type_option_af')
        },
        {
            key: 'qt',
            value: '11',
            label:t('contents._3dsecure_merchant.form.transaction_type_option_qt')
        },
        {
            key: 'pal',
            value: '28',
            label:t('contents._3dsecure_merchant.form.transaction_type_option_pal')
        }
    ]

    useEffect(() => {
        if (!merchantList) {
            getMerchantList();
        }
    }, [merchantList])

    const getDataSource = (datalist) => {
        return datalist && Array.isArray(datalist) ? datalist : []
    };

    const formValChange = (chgV, allV) => {
        setFormData(allV);
    };

    // Callback for Table Change
    const handleChange = (pagination, filters, sorter, { currentDataSource }) => {
        var orderBy = 'DESC';
        if (sorter.order === 'ascend') {
            orderBy = 'ASC';
        }
        var sortField = 'timestamp';
        if (sorter.field !== undefined) {
            sortField = sorter.field;
        }
        var p = pagination.current;
        if (Object.keys(sorter).length > 0 && requestData.sort_field !== sortField) {
            p = 1;
            setCurrentPage(1);
        }
        setRequestData({ ...requestData, order: orderBy, sort_field: sortField, page: p, size: pagination.pageSize });
    };

    // Fetch 3DS MICROSERVICE Merchants
    const getMerchantList = async () => {
        const apiUrl = THREEDS_URL + "/api/v1/merchants";
        await axios
            .post(apiUrl, {}, { headers: { Authorization: "Bearer " + localStorage.getItem("threeds_token") } })
            .then(({ data }) => {
                setMerchantList(data.list);
            })
            .catch((error) => console.log(error));
    };

    // 
    const searchFieldInputChange = (val) => {
        setSearchField(val);
    }

    const onFinish = () => {
        let startDate = null;
        let endDate = null;
        let searchData = {};
        
        if (formData) {
            if (dates) {
                startDate = dates[0] ? dates[0] : null;
                endDate = dates[1] ? dates[1] : null;
            }
            searchData = {
                field: formData.field,
                keyword: formData.keyword,
                mids: formData.mids
            }
        }
        setCurrentPage(1);
        setRequestData({ ...requestData, ...searchData, startDate: startDate, endDate: endDate, page: 1 });
    }

    const showMerchantData = (record) => {
        setMerchantData(record);
        setIsAddMerchantModalVisible(true);
    }

    // Render the type of keyword input dynamically (Select or Input)
    const renderDropdownKeywordSearchInput = (val) => {
        let option = false;
        if (val === 'rsts.clientMode') {
            option = <>{clientModeOption.map((item, index) => (
                <Option key={item.key} value={item.value}> {item.label} </Option>
            ))}</>;
        } else if (val === 'rsts.enableResult') {
            option = <>{enableResultOption.map((item, index) => (
                <Option key={item.key} value={item.value}> {item.label} </Option>
            ))}</>;
        } else if (val === 'rsts.disableChallenge') {
            option = <>{disableChallengeOption.map((item, index) => (
                <Option key={item.key} value={item.value}> {item.label} </Option>
            ))}</>;
        } else if (val === 'rsts.enable3ds1Frictionless') {
            option = <>{enable3ds1FrictionLessOption.map((item, index) => (
                <Option key={item.key} value={item.value}> {item.label} </Option>
            ))}</>;
        } else if (val === 'rsts.enable3DS1Only') {
            option = <>{enable3ds1OnlyOption.map((item, index) => (
                <Option key={item.key} value={item.value}> {item.label} </Option>
            ))}</>;
        } else if (val === 'rsts.authenticationInd') {
            option = <>{authenticationIndicatorOption.map((item, index) => (
                <Option key={item.key} value={item.value}> {item.label} </Option>
            ))}</>;
        } else if (val === 'rsts.challengeInd') {
            option = <>{challengeIndicatorOption.map((item, index) => (
                <Option key={item.key} value={item.value}> {item.label} </Option>
            ))}</>;
        } else if (val === 'rsts.acctType') {
            option = <>{accountTypeOption.map((item, index) => (
                <Option key={item.key} value={item.value}> {item.label} </Option>
            ))}</>;
        } else if (val === 'rsts.merchantCountryCode') {
            option = <>{Object.keys(countryList).map((item, index) => (
                <Option key={index} value={countryList[index].abrv_numeric}> {countryList[index].name} - {countryList[index].abrv_numeric} </Option>
            ))}</>
        } else if (val === 'rsts.messageCategory') {
            option = <>{messageCategoryOption.map((item, index) => (
                <Option key={item.key} value={item.value}> {item.label} </Option>
            ))}</>;
        } else if (val === 'rsts.purchaseCurrency') {
            option = <>{Object.keys(countryList).map((item, index) => (
                <Option key={index} value={countryList[index].abrv_numeric}> {countryList[index].name} - {countryList[index].abrv_numeric} </Option>
            ))}</>
        } else if (val === 'rsts.transType') {
            option = <>{transactionTypeOption.map((item, index) => (
                <Option key={item.key} value={item.value}> {item.label} </Option>
            ))}</>;
        }

        if (option) {
            return <Select
                placeholder={t('please_select')}
                showSearch
                optionFilterProp="children"
            >
                {option}
            </Select>
        } else {
            return <Input placeholder={t("tables.fields.keyword")} />
        }
    }

    const clearAll = () => {
        setCurrentPage(1);
        setFormData(initFormData);
        setRequestData({ ...requestData, ...initFormData, startDate: START_DATE, endDate: END_DATE, page: 1 });
        form.setFieldsValue(initFormData);
    };

    const reloadTable = () => {
        setRequestData({ ...requestData, ...initFormData, startDate: START_DATE, endDate: END_DATE, page: currentPage });
    }

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>

                    <Row gutter={[16, 16]}>
                        {/* Merchant list Search */}
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item name="mids">
                                <Select mode="multiple" optionFilterProp="children" maxTagCount="responsive" allowClear style={{ width: "100%" }} placeholder={t("tables.fields.merchant")}>
                                    {
                                        merchantList
                                            ? merchantList.map((item) => (
                                                <Option key={item.mid} value={item.mid}>
                                                    {item.site_name}
                                                </Option>
                                            ))
                                            : null
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        {/* FIELD SEARCH INPUT */}
                        <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 8 }}
                            xl={{ span: 8 }}
                        >
                            <Form.Item name="field">
                                <Select onChange={searchFieldInputChange} maxTagCount="responsive" allowClear style={{ width: "100%" }} placeholder={t("tables.fields.field")}>
                                    {
                                        Object.keys(searchFieldList).map(function (val, index) {
                                            return <Option key={index} value={val}>
                                                {searchFieldList[val]}
                                            </Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        {/* KEYWORD SEARCH INPUT */}
                        <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 8 }}
                            xl={{ span: 8 }}
                        >
                            <Form.Item name="keyword">
                                {searchField ? renderDropdownKeywordSearchInput(searchField)
                                    : <Input placeholder={t("tables.fields.keyword")} />}
                            </Form.Item>
                        </Col>

                        <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 12 }}
                            md={{ span: 6, offset: 12 }}
                            lg={{ span: 6, offset: 12 }}
                            xl={{ span: 3, offset: 17 }}
                        >
                            <Form.Item>
                                <Button type="secondary" onClick={() => clearAll()} block>
                                    {t("tables.actions.clear_all")}
                                </Button>
                            </Form.Item>
                        </Col>

                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> {t("tables.actions.search")}
                                </Button>
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </Card>
        </div>
    )

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.3dsecure.merchant.merchantlist")}</h3>
            {
                GroupAccess('SYSADMIN') ? <div>
                    <Button type="link" onClick={() => { setIsAddMerchantModalVisible(true); setMerchantData(false) }} style={{ marginLeft: "auto", zIndex:9999  }}>
                        <PlusOutlined /> {t("contents.3dsecure.merchant.new_merchant")}
                    </Button>
                </div> : null
            }
        </div>
    );

    const columns = [
        {
            dataIndex: "sid",
            key: "sid",
            title: t("tables.fields.mid"),
            width: 100,
            sorter: {
                compare: (a, b) => String(a.sid).localeCompare(String(b.sid))
            },
            render: (text) => {
                return <Text style={{ color: "#1677ff" }} > {text} </Text>
            },
            SortOrder: SortOrder(sortedInfo, 'sid')
        },
        {
            dataIndex: "site_name",
            key: "site_name",
            title: t("tables.fields.dba_name"),
            width: 300,
            sorter: {
                compare: (a, b) => a.name.localeCompare(b.name)
            },
            SortOrder: SortOrder(sortedInfo, 'site_name')
        },


        ... (!GroupAccess('SYSADMIN') ? [
            {
                dataIndex: "requestorURL",
                key: "requestorURL",
                title: t("tables.fields.sales_url") ,
                width: 300,
                sorter: {
                    compare: (a, b) => a.requestorURL.localeCompare(b.requestorURL)
                },
                SortOrder: SortOrder(sortedInfo, 'requestorURL')
            },
            {
                dataIndex: "disableChallenge",
                key: "disableChallenge",
                title: t('contents._3dsecure_merchant.form.disable_challenge'),
                width: 150,
                sorter: {
                    compare: (a, b) => (a?.disableChallenge ?? "").localeCompare(b?.disableChallenge ?? "")
                },
                SortOrder: SortOrder(sortedInfo, 'disableChallenge'),
                render: (text, record) => {
                    let disableChallenge = disableChallengeOption.filter(item => (
                        item.value === text
                    ))
                    return disableChallenge[0]?.label ?? '';
                }
            },
            {
                dataIndex: "transType",
                key: "transType",
                title: t('contents._3dsecure_merchant.form.transaction_type'),
                width: 150,
                sorter: {
                    compare: (a, b) => (a?.transType ?? "").localeCompare(b?.transType ?? "")
                },
                SortOrder: SortOrder(sortedInfo, 'transType'),
                render: (text, record) => {
                    let transType = transactionTypeOption.filter(item => (
                        item.value === text
                    ))
                    return transType[0]?.label ?? '';
                }
            },
            {
                dataIndex: "authenticationInd",
                key: "authenticationInd",
                title: t('contents._3dsecure_merchant.form.3DS_requestor_authentication_indicator'),
                width: 300,
                sorter: {
                    compare: (a, b) => (a?.authenticationInd ?? "").localeCompare(b?.authenticationInd ?? "")
                },
                SortOrder: SortOrder(sortedInfo, 'authenticationInd'),
                render: (text, record) => {
                    let authenticationIndicator = authenticationIndicatorOption.filter(item => (
                        item.value === text
                    ))
                    return authenticationIndicator[0]?.label ?? '';
                }
            },
            {
                dataIndex: "challengeInd",
                key: "challengeInd",
                title: t('contents._3dsecure_merchant.form.3DS_requestor_challenge_indicator'),
                width: 300,
                sorter: {
                    compare: (a, b) => (a?.challengeInd ?? "").localeCompare(b?.challengeInd ?? "")
                },
                SortOrder: SortOrder(sortedInfo, 'challengeInd'),
                render: (text, record) => {
                    let challengeIndicator = challengeIndicatorOption.filter(item => (
                        item.value === text
                    ))
                    return challengeIndicator[0]?.label ?? '';
                }
            },
            {
                dataIndex: "mcc",
                key: "mcc",
                title: t('contents._3dsecure_merchant.form.merchant_category_code'),
                width: 200,
                sorter: {
                    compare: (a, b) => (a?.mcc ?? "").localeCompare(b?.mcc ?? "")
                },
                SortOrder: SortOrder(sortedInfo, 'mcc'),
            },
            {
                dataIndex: "merchantCountryCode",
                key: "merchantCountryCode",
                title: t('contents._3dsecure_merchant.form.merchant_country_code'),
                width: 300,
                sorter: {
                    compare: (a, b) => (a?.merchantCountryCode ?? "").localeCompare(b?.merchantCountryCode ?? "")
                },
                SortOrder: SortOrder(sortedInfo, 'merchantCountryCode'),
                render: (text, record) => {
                    let country = '';
                    if(countryList){
                        country = countryList.filter(item => (
                            item.abrv_numeric === text
                        ))
                    }
                    return (country && country.length) ? country[0]?.name + ' - ' + country[0]?.abrv_numeric : '';
                }
            },
            {
                dataIndex: "purchaseCurrency",
                key: "purchaseCurrency",
                title: t('contents._3dsecure_merchant.form.purchase_currency'),
                width: 300,
                sorter: {
                    compare: (a, b) => (a?.purchaseCurrency ?? "").localeCompare(b?.purchaseCurrency ?? "")
                },
                SortOrder: SortOrder(sortedInfo, 'purchaseCurrency'),
                render: (text, record) => {
                    let currency = '';
                    if(currencyList){
                        currency = currencyList.filter(item => (
                            item.numeric3 === text
                        ))
                    }
                    return (currency && currency.length) ? currency[0]?.name + ' - ' + currency[0]?.alpha3 + ' - ' + currency[0]?.numeric3 : '';
                }
            }
        ] : []),

        ... ( GroupAccess('SYSADMIN') ? [{ // SHOW ADMIN COLUMNS
            dataIndex: "verbose",
            key: "verbose",
            title: t("tables.fields.verbose"),
            width: 150,
            render: (text, record) => (
                (text) ? <Badge status="success" text="On" /> : <Badge status="error" text="Off" />
            )
        },
        {
            dataIndex: "clientMode",
            key: "clientMode",
            title: t('contents._3dsecure_merchant.form.client_mode'),
            width: 150,
            sorter: {
                compare: (a, b) => (a?.clientMode ?? "").localeCompare(b?.clientMode ?? "")
            },
            SortOrder: SortOrder(sortedInfo, 'clientMode'),
            render: (text, record) => {
                let clientMode = clientModeOption.filter(item => (
                    item.value === text
                ))
                return clientMode[0]?.label ?? '';
            }
        },
        {
            dataIndex: "enableResult",
            key: "enableResult",
            title: t('contents._3dsecure_merchant.form.enable_result'),
            width: 150,
            sorter: {
                compare: (a, b) => (a?.enableResult ?? "").localeCompare(b?.enableResult ?? "")
            },
            SortOrder: SortOrder(sortedInfo, 'enableResult'),
            render: (text, record) => {
                let enableResult = enableResultOption.filter(item => (
                    item.value === text
                ))
                return enableResult[0]?.label ?? '';
            }
        },
        {
            dataIndex: "disableChallenge",
            key: "disableChallenge",
            title: t('contents._3dsecure_merchant.form.disable_challenge'),
            width: 150,
            sorter: {
                compare: (a, b) => (a?.disableChallenge ?? "").localeCompare(b?.disableChallenge ?? "")
            },
            SortOrder: SortOrder(sortedInfo, 'disableChallenge'),
            render: (text, record) => {
                let disableChallenge = disableChallengeOption.filter(item => (
                    item.value === text
                ))
                return disableChallenge[0]?.label ?? '';
            }
        },
        {
            dataIndex: "enable3ds1Frictionless",
            key: "enable3ds1Frictionless",
            title: t('contents._3dsecure_merchant.form.enable_3ds1_friction_less'),
            width: 300,
            sorter: {
                compare: (a, b) => (a?.enable3ds1Frictionless ?? "").localeCompare(b?.enable3ds1Frictionless ?? "")
            },
            SortOrder: SortOrder(sortedInfo, 'enable3ds1Frictionless'),
            render: (text, record) => {
                let enable3ds1FrictionLess = enable3ds1FrictionLessOption.filter(item => (
                    item.value === text
                ))
                return enable3ds1FrictionLess[0]?.label ?? '';
            }
        },
        {
            dataIndex: "enable3DS1Only",
            key: "enable3DS1Only",
            title: t('contents._3dsecure_merchant.form.enable_3ds1_only'),
            width: 200,
            sorter: {
                compare: (a, b) => (a?.enable3DS1Only ?? "").localeCompare(b?.enable3DS1Only ?? "")
            },
            SortOrder: SortOrder(sortedInfo, 'enable3DS1Only'),
            render: (text, record) => {
                let enable3ds1Only = enable3ds1OnlyOption.filter(item => (
                    item.value === text
                ))
                return enable3ds1Only[0]?.label ?? '';
            }
        },
        {
            dataIndex: "requestorName",
            key: "requestorName",
            title: t('contents._3dsecure_merchant.form.requestor_name'),
            width: 150,
            sorter: {
                compare: (a, b) => (a?.requestorName ?? "").localeCompare(b?.requestorName ?? "")
            },
            SortOrder: SortOrder(sortedInfo, 'requestorName'),
        },
        {
            dataIndex: "requestorURL",
            key: "requestorURL",
            title: t('contents._3dsecure_merchant.form.requestor_url'),
            width: 150,
            sorter: {
                compare: (a, b) => (a?.requestorURL ?? "").localeCompare(b?.requestorURL ?? "")
            },
            SortOrder: SortOrder(sortedInfo, 'requestorURL'),
        },
        {
            dataIndex: "authenticationInd",
            key: "authenticationInd",
            title: t('contents._3dsecure_merchant.form.3DS_requestor_authentication_indicator'),
            width: 300,
            sorter: {
                compare: (a, b) => (a?.authenticationInd ?? "").localeCompare(b?.authenticationInd ?? "")
            },
            SortOrder: SortOrder(sortedInfo, 'authenticationInd'),
            render: (text, record) => {
                let authenticationIndicator = authenticationIndicatorOption.filter(item => (
                    item.value === text
                ))
                return authenticationIndicator[0]?.label ?? '';
            }
        },
        {
            dataIndex: "challengeInd",
            key: "challengeInd",
            title: t('contents._3dsecure_merchant.form.3DS_requestor_challenge_indicator'),
            width: 300,
            sorter: {
                compare: (a, b) => (a?.challengeInd ?? "").localeCompare(b?.challengeInd ?? "")
            },
            SortOrder: SortOrder(sortedInfo, 'challengeInd'),
            render: (text, record) => {
                let challengeIndicator = challengeIndicatorOption.filter(item => (
                    item.value === text
                ))
                return challengeIndicator[0]?.label ?? '';
            }
        },
        {
            dataIndex: "acctType",
            key: "acctType",
            title: t('contents._3dsecure_merchant.form.acct_type'),
            width: 300,
            sorter: {
                compare: (a, b) => (a?.acctType ?? "").localeCompare(b?.acctType ?? "")
            },
            SortOrder: SortOrder(sortedInfo, 'acctType'),
            render: (text, record) => {
                let accountType = accountTypeOption.filter(item => (
                    item.value === text
                ))
                return accountType[0]?.label ?? '';
            }
        },
        {
            dataIndex: "acquirerMerchantPwd",
            key: "acquirerMerchantPwd",
            title: t('contents._3dsecure_merchant.form.acquirer_merchant_id_password'),
            width: 300,
            sorter: {
                compare: (a, b) => (a?.acquirerMerchantPwd ?? "").localeCompare(b?.acquirerMerchantPwd ?? "")
            },
            SortOrder: SortOrder(sortedInfo, 'acquirerMerchantPwd'),
        },
        {
            dataIndex: "mcc",
            key: "mcc",
            title: t('contents._3dsecure_merchant.form.merchant_category_code'),
            width: 200,
            sorter: {
                compare: (a, b) => (a?.mcc ?? "").localeCompare(b?.mcc ?? "")
            },
            SortOrder: SortOrder(sortedInfo, 'mcc'),
        },
        {
            dataIndex: "merchantCountryCode",
            key: "merchantCountryCode",
            title: t('contents._3dsecure_merchant.form.merchant_country_code'),
            width: 300,
            sorter: {
                compare: (a, b) => (a?.merchantCountryCode ?? "").localeCompare(b?.merchantCountryCode ?? "")
            },
            SortOrder: SortOrder(sortedInfo, 'merchantCountryCode'),
            render: (text, record) => {
                let country = '';
                if(countryList){
                    country = countryList.filter(item => (
                        item.abrv_numeric === text
                    ))
                }
                return (country && country.length) ? country[0]?.name + ' - ' + country[0]?.abrv_numeric : '';
            }
        },
        {
            dataIndex: "messageCategory",
            key: "messageCategory",
            title: t('contents._3dsecure_merchant.form.message_category'),
            width: 150,
            sorter: {
                compare: (a, b) => (a?.messageCategory ?? "").localeCompare(b?.messageCategory ?? "")
            },
            SortOrder: SortOrder(sortedInfo, 'messageCategory'),
            render: (text, record) => {
                let messageCategory = messageCategoryOption.filter(item => (
                    item.value === text
                ))
                return messageCategory[0]?.label ?? '';
            }
        },
        {
            dataIndex: "purchaseCurrency",
            key: "purchaseCurrency",
            title: t('contents._3dsecure_merchant.form.purchase_currency'),
            width: 300,
            sorter: {
                compare: (a, b) => (a?.purchaseCurrency ?? "").localeCompare(b?.purchaseCurrency ?? "")
            },
            SortOrder: SortOrder(sortedInfo, 'purchaseCurrency'),
            render: (text, record) => {
                let currency = '';
                if(currencyList){
                    currency = currencyList.filter(item => (
                        item.numeric3 === text
                    ))
                }
                return (currency && currency.length) ? currency[0]?.name + ' - ' + currency[0]?.alpha3 + ' - ' + currency[0]?.numeric3 : '';
            }
        },
        {
            dataIndex: "transType",
            key: "transType",
            title: t('contents._3dsecure_merchant.form.transaction_type'),
            width: 150,
            sorter: {
                compare: (a, b) => (a?.transType ?? "").localeCompare(b?.transType ?? "")
            },
            SortOrder: SortOrder(sortedInfo, 'transType'),
            render: (text, record) => {
                let transType = transactionTypeOption.filter(item => (
                    item.value === text
                ))
                return transType[0]?.label ?? '';
            }
        },] : [] ),
        
        {
            dataIndex: 'action',
            key: 'action',
            title: t('tables.fields.action'),
            width: 90,
            ellipsis: true,
            fixed: 'right',
            render: (text, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => showMerchantData(record)}>
                        <FileSearchOutlined style={{ color: "rgba(0, 0, 0, 0.85)", fontSize: "18px" }} />
                    </Button>
                </Space>
            ),
        },

    ];

    return (
        <>
            <PageHeader title={t("contents.3dsecure.merchant.title")}></PageHeader>
            {AdvancedSearchForm()}
            <AddMerchantModal t={t} setIsAddMerchantModalVisible={setIsAddMerchantModalVisible} isAddMerchantModalVisible={isAddMerchantModalVisible} countryList={countryList} merchantData={merchantData} reloadTable={reloadTable} clientModeOption={clientModeOption} enableResultOption={enableResultOption} disableChallengeOption={disableChallengeOption} enable3ds1FrictionLessOption={enable3ds1FrictionLessOption} enable3ds1OnlyOption={enable3ds1OnlyOption} authenticationIndicatorOption={authenticationIndicatorOption} challengeIndicatorOption={challengeIndicatorOption} accountTypeOption={accountTypeOption} messageCategoryOption={messageCategoryOption} transactionTypeOption={transactionTypeOption} currencyList={currencyList} />
            <Table
                scroll={{ x: '100%' }}
                size="small"
                loading={isLoading}
                components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }}
                columns={MergeColumns(columns)}
                title={() => getHeaderTable()}
                rowKey="mid"
                className="table-v3 custom-pagination custom-pagination-v2"
                dataSource={data.datalist && Array.isArray(data.datalist) ? getDataSource(data.datalist) : []}
                onChange={handleChange}
                pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}
            />
        </>
    );
}

export default Threeds_Merchant
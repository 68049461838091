import React, { useState, useEffect } from "react";
import { Card } from "antd";
import { API, SKIP_2FA, THEME, SESSION_TIMEOUT} from "../config/config";
import OtpVerify from "../components/OtpVerify";
import OptionVerify from "../components/OptionVerify";
import Multilingual from "./../helper/Multilingual";
import htmlParser from "html-react-parser";
import md5 from "md5";
import {sha256} from "js-sha256";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";

import { Form, Input, Button, Row, Col, ConfigProvider } from "antd";
import FetchUserGroups from "../helper/FetchUserGroups";
import FetchUserAccess from "../helper/FetchUserAccess";
import FetchUserAssociations from "../helper/FetchUserAssociations";

const ResetPassword = (props) => {
    const [t, locale, getDropdownSelectLanguage] = Multilingual();
    const [data, setData] = useState({ username: "", new_password: "", confirm_password: "" });
    const [token, setToken] = useState(null);
    const [user, setUser] = useState(null);
    const [otp, setOtp] = useState("");
    const [message, setMessage] = useState("");
    const [isResetPasswordSuccess, setIsResetPasswordSuccess] = useState(false);

    const timeoutAfter = SESSION_TIMEOUT && Number.isInteger(SESSION_TIMEOUT) && SESSION_TIMEOUT > 1 ? SESSION_TIMEOUT : 15;

    const [form] = Form.useForm();

    useEffect(() => {
        document.title = t("resetpassword.title");
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const optionChange = (payload) => {
        setUser({ ...user, ...payload });
    };

    const otpChange = (otp) => {
        setOtp(otp);
    };

    const layout = {
        layout: "vertical",
    };
    const tailLayout = {};

    const formValChange = (chgV, allV) => {
        setData(allV);
    };

    const onVerify = async (userbypass = null) => {
        if (!SKIP_2FA) {
            const isVerified = await axios
                .post(API + "/otpverify", { otp: otp }, { headers: { Authorization: "Bearer " + user.access_token } })
                .then(({ data }) => data.verify)
                .catch(() => false);

            if (!isVerified) {
                setOtp("");
                Swal.fire({
                    icon: "error",
                    title: t("login.dialogs.verify_error"),
                    showConfirmButton: false,
                    timer: 2000,
                });

                return false;
            }
        }

        let userdata = null;

        if (user) {
            userdata = user;
        } else if (userbypass) {
            userdata = userbypass;
        } else {
            return false;
        }

        let userAccess, userAssociations, userGroups;
        try {
            [userAccess, userAssociations, userGroups] = await Promise.all([
                FetchUserAccess(userdata.access_token, userdata.userid),
                FetchUserAssociations(userdata.access_token, userdata.userid),
                FetchUserGroups(userdata.access_token, userdata.userid)
            ]);
        } catch (err) {
            Swal.fire({
                icon: "error",
                title: t("login.dialogs.catch"),
                showConfirmButton: false,
                timer: 2000,
            });
            return false;
        }
        userdata = {
            ...userdata,
            ...userAccess,
            ...userAssociations,
            ...userGroups
        }

        userdata = { ...userdata, fullName: `${userdata.firstname} ${userdata.lastname}` };
        userdata = { ...userdata, validate: md5(JSON.stringify(userdata)) };
        userdata = { ...userdata, expiry: moment().add(timeoutAfter, "minutes").format() };

        localStorage.setItem("authuser", window.btoa(`${userdata.userid}:${userdata.username}`));
        localStorage.setItem("access_token", userdata.access_token);
        localStorage.setItem("loggedUser", JSON.stringify(userdata));

        if (userdata.theme) {
            if (Array.isArray(userdata.theme)) {
                // Removed by Jericho 2023-08-23 - Removing the txshield themes/reseller logo
                // if (userdata.theme[0].logo) {
                //     localStorage.setItem("logo", userdata.theme[0].logo);
                // }
                if (userdata.theme[0].dashboardtheme) {
                    localStorage.setItem("dashboardtheme", userdata.theme[0].dashboardtheme.replaceAll('/"', "'"));
                }
            }
        }
        Swal.fire({
            icon: "success",
            title: t("login.dialogs.login_success"),
            showConfirmButton: false,
            timer: 2000,
            onClose: () => {
                props.history.push("/dashboard");
            },
        });
    };

    const onFinish = (values) => {
        axios
            .post(API + "/resetpassword", { username: values.username, password: sha256(values.new_password), token: token })
            .then(({ data }) => { //eslint-disable-line
                if (!SKIP_2FA) {
                    setIsResetPasswordSuccess(true);
                } else {
                    Swal.fire({
                        icon: "success",
                        title: t("login.dialogs.password_changed"),
                        showConfirmButton: false,
                        timer: 2000,
                        onClose: () => {
                            props.history.push("/");
                        },
                    });
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: t("login.dialogs.catch"),
                    text: error.response ? error.response.data.error_msg : "",
                    showConfirmButton: true,
                });
            });
    };

    if (localStorage.getItem("loggedUser")) {
        const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));

        if (loggedUser) {
            localStorage.removeItem("loggedUser");
            localStorage.removeItem("access_token");
            localStorage.removeItem("aggregates");
            localStorage.removeItem("aggregates_updated");
            localStorage.removeItem("logo");
            localStorage.removeItem("dashboardtheme");
            localStorage.removeItem("expiry");
            // props.history.push("/dashboard");
        }
    }

    const getSelectLanguage = () => {
        return (
            <Row type="flex" gutter={[8, 0]} justify="end" style={{ marginBottom: "1rem" }}>
                <Col>{getDropdownSelectLanguage()}</Col>
            </Row>
        );
    };

    const checkToken = (token) => {
        const apiUrl = "/getuserbytoken";
        axios
            .post(API + apiUrl, { token: token })
            .then(({ data }) => {

                if (data.success) {

                    form.setFieldsValue({ username: data.username, new_password: "", confirm_password: "" });
                    setUser(data);
                } else {
                    setMessage(data.message);
                }
            })
            .catch((error) => error);
    };

    if (typeof props.match.params.token != "undefined" && !token) {
        setToken(props.match.params.token);
        checkToken(props.match.params.token);
    }

    return !isResetPasswordSuccess ? (
        <ConfigProvider locale={locale}>
            <div className={`login-warp ${locale.locale}`}>
                <div className="login-warp--box">
                    <img
                        src={`../themes/${THEME ? THEME : "default"}/payshield-origin.png`}
                        width={"100%"}
                        style={{ display: "block", maxWidth: "300px", margin: "0 auto 1rem auto" }}
                        alt="payshield dashboard"
                    />
                    {message !== "" ? (
                        <Card className="text-center" style={{ width: 400 }}>
                            <h2>{t("resetpassword.title")}</h2>
                            <p>{htmlParser(message)}</p>
                        </Card>
                    ) : (
                        <>
                            {getSelectLanguage()}
                            <Form {...layout} form={form} onValuesChange={formValChange} onFinish={() => onFinish(data)}>
                                <Form.Item name="username" rules={[{ required: true, message: t("login.required.username") }]}>
                                    <Input size="large" placeholder={t("login.username")} style={{ backgroundColor: "#eee" }} readOnly={true} />
                                </Form.Item>
                                <Form.Item
                                    name="new_password"
                                    dependencies={["old_password"]}
                                    rules={[
                                        { required: true, message: t("contents.profile.form.required_messages.changepass_new_required") },
                                        { min: 8, message: t("contents.profile.form.required_messages.changepass_new_min8") },
                                    ]}
                                >
                                    <Input.Password size="large" placeholder={t("contents.profile.form.new_password")} />
                                </Form.Item>
                                <Form.Item
                                    name="confirm_password"
                                    dependencies={["new_password"]}
                                    rules={[
                                        { required: true, message: t("contents.profile.form.required_messages.changepass_confirm_required") },
                                        { min: 8, message: t("contents.profile.form.required_messages.changepass_confirm_min8") },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (!value || getFieldValue("new_password") === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(t("contents.profile.form.required_messages.changepass_confirm_nomatch"));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password size="large" placeholder={t("contents.profile.form.confirm_password")} />
                                </Form.Item>
                                <Form.Item {...tailLayout} className="text-center" style={{ marginBottom: ".5rem" }}>
                                    <Button type="primary" htmlType="submit" className="login-form-button" size="large" shape="round">
                                        {t("login.submit")}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </>
                    )}
                </div>
            </div>
        </ConfigProvider>
    ) : !SKIP_2FA ? (
        !user["verify_option"] ? (
            <ConfigProvider locale={locale}>
                <OptionVerify t={t} email={user.email} access_token={user.access_token} handleChange={optionChange} />
            </ConfigProvider>
        ) : (
            <ConfigProvider locale={locale}>
                <OtpVerify
                    t={t}
                    otp={otp}
                    handleChange={otpChange}
                    onSubmit={onVerify}
                    option={user["verify_option"]}
                    qrcode={user["2fa_qrcode"]}
                    secret={user["2fa_secret"]}
                    firstlogin_succeed={user["first_time_verify"]}
                />
            </ConfigProvider>
        )
    ) : null;
};

export default ResetPassword;

import React ,{ useState, useEffect} from "react";
import {Table, Button} from "antd";
import { ExportOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash";

import * as XLSX from "xlsx";
import { saveAs } from "file-saver";


const BreakdownByType = (props) => {
    const { t, statsData, requestData, detailsBoxTitle } = props; // eslint-disable-line


    //for export 
        const [groupStatsData,setGroupStatsData] = useState([]);
    
    useEffect(() => {
            const data = Object.keys(statsData.alert_type).map((key) => ({
                key,
                alert_type: statsData.alert_type[key]?.alert_type,
                count: statsData.alert_type[key]?.count,
              }))
          
            //To preserve the correct order, extract keys, sort them manually, and map values correctly
            setGroupStatsData(Object.values(data).sort((a, b) => a.alert_type.localeCompare(b.alert_type)));
        }, [statsData]);



  
  // Handle sorting changes
  const handleTableChange = (pagination, filters, sorter) => {
   
    if(sorter.order){
        if(sorter.columnKey === 'alert_type'){
            if(sorter.order === 'ascend' ){
                groupStatsData.sort((a, b) => a.alert_type.localeCompare(b.alert_type));
            }else
            {
                groupStatsData.sort((a, b) => b.alert_type.localeCompare(a.alert_type));
            }
        }else{
            if(sorter.order === 'ascend' ){
                groupStatsData.sort((a, b) => a.count - b.count);
            }else{
                groupStatsData.sort((a, b) => b.count - a.count);
            }
        }
    }


  };

  function fitToColumn(arrayOfArray) {
    // get maximum character of each column
    return arrayOfArray[0].map((a, i) => ({ wch: Math.max(...arrayOfArray.map(a2 => a2[i] ? a2[i].toString().length : 0)) }));
}

  const exportDataToExcel = (data, fileName = "alert_statistics_by_alert_type.xlsx") => {
          
           const formattedData = [];
   
           
           // Header row
           
              formattedData.push(["Alert Type", "Count"]);
              data.forEach((record) => {             
                  formattedData.push([record.alert_type, record.count]);  
              });
        
   
           const ws = XLSX.utils.aoa_to_sheet(formattedData);
           const wb = XLSX.utils.book_new();
           XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
         
           // Adjust column widths for better readability
           ws['!cols'] = fitToColumn(formattedData);
  
           // Convert to Blob and Save
           const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
           const blob = new Blob([excelBuffer], {
             type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
           });
         
           saveAs(blob, fileName);
         };
    
    const getDetails = () => (
        <div className="table-v3">
            <div className="ant-table ant-table-small">
                <div className="ant-table-title">
                    <div className="custom-table--header d-flex justify-content-between align-items-center">
                        <h3 className="table-title">{t(`contents.alerts.${detailsBoxTitle}`)}</h3>                     
                        <Button key="btn_export" type="link"  disabled={isEmpty(groupStatsData)} onClick={() => exportDataToExcel(groupStatsData)}>
                            <div className="d-flex align-items-center">
                                <ExportOutlined style={{ fontSize: "18px" }} /> <div style={{ marginLeft: "4px" }}>Export</div>
                            </div>
                        </Button>
                    </div>
                </div>
                <div className="ant-table-container">
                    <div className="ant-table-content">
                        <Table dataSource={groupStatsData} pagination={false} onChange={handleTableChange}>
                        <Table.Column
                            title={t("contents.alerts.alert_type")}
                            dataIndex="alert_type"
                            key = "alert_type"
                            sorter={(a, b) => a.alert_type.localeCompare(b.alert_type)}
                            width="70%"
                        />
                        <Table.Column
                            title={<div className="text-center">{t("contents.alerts.count")}</div>}
                            dataIndex="count"
                            key="count"
                            sorter={(a, b) => a.count - b.count}
                            align="center"
                            width="30%"
                        />
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    );

    return getDetails();
};

export default BreakdownByType;

import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { OI_URL } from "../../config/config";
import DataFetchHookOI from "../../helper/DataFetchHookOI";
import PermissionsRequired from "../../helper/PermissionsRequired";
import AddBinCaidModal from "./AddBinCaidModal";
import EditBinCaidModal from "./EditBinCaidModal";
import { Table, PageHeader, Button, Card, Form, Select, Row, Col, Input, message } from "antd";
import { SearchOutlined, FileSearchOutlined, PlusOutlined } from "@ant-design/icons";
import AssociationsChecker from "../../helper/AssociationsChecker";
import CustomPaginationV2 from "../../helper/Table/CustomPaginationV2";
import SortOrder from "../../helper/Table/SortOrder";
import moment from "moment";

const { Option } = Select;
const DATA_URL = "/api/v1/txshield/bincaidList";

const BinCaidList = (props) => {
    const isMounted = useRef(true);
    
    const [{ data, totalResult, isLoading, requestData }, doFetch, setRequestData] = DataFetchHookOI(DATA_URL, "GET", null, {
        page: 1,
        size: 20,
        order: "ASC",
    });
    const [formData, setFormData] = useState({ hideColumn: [], associatedMerchantIds: [] });
    const [form] = Form.useForm();
    const [currentPage, setCurrentPage] = useState(1);
    const [sortedInfo, setSortedInfo] = useState({});
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    const [selectedBinCaid, setSelectedBinCaid] = useState(null);
    const oiAssocication = localStorage.getItem("oiAssocication") ? localStorage.getItem("oiAssocication").split(",") : [];
    const { t } = props;

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const initFormData = useMemo(() => ({
        field: "bin",
        keyword: null,
        mcc: null,
        status: null,
        order: "ASC",
        associatedMerchantIds: oiAssocication,
    }), [oiAssocication]);

    const showEditBinCaid = useCallback((record) => {
        if (isMounted.current) {
            setSelectedBinCaid(record);
            setIsEditModalVisible(true);
        }
    }, []);

    const handleChange = useCallback((pagination, filters, sorter) => {
        if (isMounted.current) {
            setSortedInfo(sorter);
        }
    }, []);

    const formValChange = useCallback((chgV, allV) => {
        if (isMounted.current) {
            setFormData(allV);
        }
    }, []);

    const clearAll = useCallback(() => {
        if (!isMounted.current) return;
        
        setFormData(initFormData);
        form.setFieldsValue(initFormData);
        const params = { ...initFormData, page: 1, timestamp: moment().unix() }; 
        setRequestData(params); 
        const queryString = new URLSearchParams(params).toString(); 
        doFetch(`${OI_URL}${DATA_URL}?${queryString}`, "GET"); 
    }, [doFetch, form, initFormData, setRequestData]);

    const fetchBinCaids = useCallback(() => {
        if (isMounted.current) {
            doFetch(`${OI_URL}${DATA_URL}?timestamp=${moment().unix()}`);
        }
    }, [doFetch]);

    useEffect(() => {
        if (isMounted.current) {
            setFormData(initFormData);
            form.setFieldsValue(initFormData);
        }
        
        return () => {
            isMounted.current = false;
        };
    }, [form, initFormData]);

    const onFinish = useCallback((values) => {
        if (!isMounted.current) return;
        
        const searchData = {
            keyword: values.keyword ? values.keyword.trim() : null,
            field: values.field,
            mcc: values.mcc,
            status: values.status,
        };

        setCurrentPage(1);
        const params = { ...requestData, ...searchData, page: 1, timestamp: moment().unix() }; 
        setRequestData(params); 
        const queryString = new URLSearchParams(params).toString(); 

        doFetch(`${OI_URL}${DATA_URL}?${queryString}`, "GET") 
            .catch(error => {
                if (isMounted.current) {
                    console.error("Error fetching data:", error);
                    message.error(t("messages.error_fetching_bincaids")); 
                }
            });
    }, [doFetch, requestData, setRequestData, t]);

    const columns = useMemo(() => [
        {
            dataIndex: "bin",
            key: "bin",
            title: t("tables.fields.bin"),
            sorter: {
                compare: (a, b) => a.bin.localeCompare(b.bin)
            },
            sortOrder: SortOrder(sortedInfo, "bin"),
        },
        {
            dataIndex: "caid",
            key: "caid",
            title: t("tables.fields.caid"),
        },
        {
            dataIndex: "mcc",
            key: "mcc",
            title: t("tables.fields.mcc"),
        },
        {
            dataIndex: "status",
            key: "status",
            title: t("tables.fields.status"),
        },
        {
            dataIndex: "action",
            title: t("tables.fields.action"),
            align: "left",
            fixed: "right",
            width: 120,
            render: (text, record) => (
                <Button type="link" onClick={() => showEditBinCaid(record)} style={{ marginLeft: "auto" }}>
                    <FileSearchOutlined style={{ fontSize: "18px", color: "rgba(0, 0, 0, 0.85)" }} />
                </Button>
            ),
        },
    ], [sortedInfo, t, showEditBinCaid]);

    const getDataSource = useCallback((datalist) => {
        return datalist && Array.isArray(datalist) ? datalist : [];
    }, []);

    const getHeaderTable = useCallback(() => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.oi.bincaids")}</h3>
            <Button 
                type="primary" 
                onClick={() => setIsAddModalVisible(true)}
                style={{ marginLeft: "auto", position: "relative", zIndex: 10 }}
            >
                <PlusOutlined /> {t("contents.oi.new_bincaid")}
            </Button>
        </div>
    ), [t]);

    const AdvancedSearchForm = useCallback(() => (
        <div className="search-wrap">
            <Card title={t('tables.actions.search')} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                        <Col className="gutter-row" xs={24} sm={12} md={6} lg={6} xl={4}>
                            <Form.Item name="field">
                                <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder={t('tables.fields.field')}>
                                    <Option value="bin">{t("tables.fields.bin")}</Option>
                                    <Option value="caid">{t("tables.fields.caid")}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={6} lg={6} xl={4}>
                            <Form.Item name="keyword">
                                <Input placeholder={t('tables.fields.keyword')} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={6} lg={6} xl={4}>
                            <Form.Item name="mcc">
                                <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder={t('tables.fields.mcc')}>
                                    <Option value="9505">9505</Option>
                                    <Option value="9701">9701</Option>
                                    <Option value="9705">9705</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={6} lg={6} xl={4}>
                            <Form.Item name="status">
                                <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder={t('tables.fields.status')}>
                                    <Option value="requested">Requested</Option>
                                    <Option value="pending">Pending</Option>
                                    <Option value="enrolled">Enrolled</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={6} lg={6} xl={4}>
                            <Form.Item>
                                <Button type="secondary" onClick={clearAll} block>
                                    {t('tables.actions.clear_all')}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={6} lg={6} xl={4}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> {t('tables.actions.search')}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    ), [form, formValChange, onFinish, t, clearAll]);

    if (!(PermissionsRequired("SYSADMIN") || AssociationsChecker("OI"))) {
        return null;
    }

    return (
        <div>
            <PageHeader title={t("contents.oi.bincaids")}></PageHeader>
            <AdvancedSearchForm />
            <AddBinCaidModal
                t={t}
                isAddBinCaidModalVisible={isAddModalVisible}
                setIsAddBinCaidModalVisible={setIsAddModalVisible}
                fetchBinCaids={fetchBinCaids}
            />
            <EditBinCaidModal
                t={t}
                binCaid={selectedBinCaid}
                isEditModalVisible={isEditModalVisible}
                setIsEditModalVisible={setIsEditModalVisible}
                fetchBinCaids={fetchBinCaids}
            />
            <Table
                loading={isLoading}
                columns={columns}
                title={() => getHeaderTable()}
                rowKey="bincaidId"
                className="table-v3 custom-pagination"
                size="small"
                dataSource={getDataSource(data.datalist)}
                onChange={handleChange}
                pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}
                locale={{ emptyText: "No records found" }}
            />
        </div>
    );
};

export default React.memo(BinCaidList);